import { CameraOutlined, PhoneOutlined, SendOutlined } from "@ant-design/icons";
import { Avatar, Button, Card, message, Row, Skeleton } from "antd";
import { Formik } from "formik";
import { Form, FormItem, Select } from "formik-antd";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { sector as sectorApi, user as userApi } from "../../../apis";

const { Meta } = Card;

const FormSectorModal = ({ action, setAction, myTableRef }) => {
  const { isMobile, s3host } = useSelector(state => state.general);
  const [data, setData] = React.useState({
    firstName: undefined,
    lastName : undefined,
    email    : undefined,
    phone    : undefined,
    address  : undefined,
    role     : undefined,
    sector   : undefined,
    avatar   : undefined,
    // ...(action && action[0] === "update" ? action[1]: {}),
    // sectorId : action && action[0] === "update" ? action[1]?.sector?._id : undefined,
  });

  const [results, setResult] = useState([]);

  const onSubmit = async (values, formActions) => {
    if (action && action[0] === "update") {
      if (!values.sectorId) message.error("Салбар сонгоно уу!");
      else {
        try {
          await userApi.update(action[1]._id, { ...values, phone: values?.phone?.toString(), sector: values?.sectorId?.toString() });
          myTableRef.current.reload();
          message.success("Таны хүсэлт амжилттай!");
        } catch (error) {
          if (error.message)
            message.error(error.message);
        }
      }
    }
    formActions.resetForm();
    initRes();
    setAction([]);
  };

  let timeout;
  const onSearch = async (query = null) => {
    if (query) {
      if (timeout)
        clearTimeout(timeout);

      timeout = setTimeout(async () => {
        let res = await sectorApi.select({
          query: query?.trim()
        });

        setResult(res);
      }, 300);
    } else {
      initRes();
    }
  };

  const initRes = async () => {
    let res = await sectorApi.select({
      query: ""
    });
    setResult(res);
  };

  useEffect(() => {
    initRes();
  }, []);

  const getSector = async (e, setFieldValue) => {
    let res = await sectorApi.get(e);
    setFieldValue("sector", res.sector);
  };

  return (
    <Container>
      <Formik
        enableReinitialize
        initialValues={data}
        onSubmit={onSubmit}>
        {({ isSubmitting, values, setFieldValue, resetForm }) => (
          <Form layout="vertical">
            <div>
              <div className="details">
                <FormItem name="sectorId" label="Салбар" required >
                  <Select
                    size="large"
                    status={!values.sectorId && "error"}
                    allowClear
                    className="sector-select-input"
                    name="sectorId"
                    placeholder="Салбар сонгох"
                    showSearch
                    optionFilterProp="children"
                    onChange={e => {
                      getSector(e, setFieldValue);
                    }}
                    onSearch={(e) =>{
                      onSearch(e);}
                    }
                  >
                    {
                      results && results?.rows?.map((item, index) =>
                        <Select.Option value={`${item?._id}`} key={index}>
                          {item.fullName}
                        </Select.Option>)
                    }
                  </Select>
                </FormItem>
                {values?.sector && <div className="meta">
                  <Meta
                    avatar={values?.sector?.avatar && values.sectorId ? <Avatar size={55} src={`${s3host}/${values?.sector?.avatar}`} icon={<CameraOutlined/>} /> : <Skeleton.Avatar size={55} />}
                    title={values?.sector?.fullName && values.sectorId ? values?.sector?.fullName : <Skeleton.Input size="small" block />}
                    description={values?.sector?.phone && values.sectorId ? <><PhoneOutlined/> {values?.sector?.phone}</>: <Skeleton.Input width={100} height={10} size="small" /> }
                  />
                </div>}
                <Row justify="end"style={{ marginTop: 24 }}>
                  <Button className="button" icon={isMobile && <SendOutlined />} style={{ marginRight: 10 }} htmlType="submit" type="primary" loading={isSubmitting}>Хадгалах</Button>
                  <Button className="button" type="default" onClick={() => {
                    setAction([]);
                    resetForm();
                  }}>Болих</Button>
                </Row>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </Container>
  );
};

const Container = styled.div`
  margin-top: 20px;
  max-width: 575px;
  @media (max-width: 1000px){
    width:100%;
    max-width:100%;
  }
`;

export default FormSectorModal;