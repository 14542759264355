/* eslint-disable key-spacing */
import {
  CameraOutlined,
  ExclamationCircleOutlined,
  PlusOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Avatar, Button, DatePicker, Input, message, Modal, Tag } from "antd";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { notice as noticeApi } from "../../apis";
import { MyTable, RowAction } from "../../components";
import { Filter } from "../../components/Design";
import {
  PageContainer,
  PageContent,
  PageHeaders,
} from "../../components/Layout";
import { datetimeFormat } from "../../utils";

const NoticeList = () => {
  const {
    s3host,
    postStatus,
    isMobile,
    postStatusType,
    postTabKey = 1,
  } = useSelector((state) => state.general);
  const myTableRef = useRef();
  const history = useHistory();
  const [query, setQuery] = useState("");
  const [filters, setFilters] = useState({
    filter: {
      startDate: undefined,
      endDate: undefined,
      query: "",
      postStatus: postStatusType,
      userStatus: null,
      userType: null,
      sector: null,
      sectorUser: undefined,
    },
    offset: {
      page: 1,
      limit: 10,
    },
  });

  const columns = useHeader({
    s3host,
    postStatus,
    onClick: async (key, row) => {
      switch (key) {
        case "edit": {
          history.push(`/notice/${row._id}`);
          break;
        }
        case "remove": {
          Modal.confirm({
            title: "Баталгаажуулах",
            icon: <ExclamationCircleOutlined />,
            content: "Та үүнийг устгахдаа итгэлтэй байна уу!",
            okText: "Устгах",
            cancelText: "Болих",
            className: "btn-custom-class",
            onOk: async () => {
              try {
                await noticeApi.remove(row._id);
                myTableRef.current.reload();
              } catch (error) {
                if (error.message) {
                  message.error(error.message);
                }
              }
            },
          });
          break;
        }
        default:
      }
    },
  });

  const onClear = () => {
    setQuery("");
    setFilters({
      filter: {
        query: "",
        targetType: undefined,
        notifyStatus: undefined,
        type: undefined,
      },
      offset: {
        page: 1,
        limit: 10,
      },
    });
  };

  let timeout;

  useEffect(() => {
    if (timeout) clearTimeout(timeout);
    if (filters.filter.query !== query)
      // eslint-disable-next-line react-hooks/exhaustive-deps
      timeout = setTimeout(() => {
        setFilters((state) => ({
          ...state,
          filter: { ...filters.filter, query: query?.trim() || "" },
        }));
      }, 300);

    return () => {
      if (timeout) clearTimeout(timeout);
    };
  }, [query]);

  const dateFilter = (e) => {
    setFilters({
      ...filters,
      filter: {
        startDate: e
          ? moment(e[0]).startOf("days").format("YYYY-MM-DDTHH:mm")
          : null,
        endDate: e
          ? moment(e[1]).endOf("days").format("YYYY-MM-DDTHH:mm")
          : null,
      },
    });
  };

  return (
    <PageContainer testsda="ene bol test ym aa">
      <PageHeaders
        title="Нийтлэл"
        extra={
          <Button
            key={0}
            type="primary"
            icon={isMobile && <PlusOutlined />}
            onClick={() => history.push("/notice/new")}
          >
            {!isMobile && "Нийтлэл нэмэх"}
          </Button>
        }
      />
      <PageContent>
        <Filter>
          <Input
            onChange={(e) => setQuery(e.target.value)}
            value={query}
            placeholder="Хайх..."
            style={{ width: "100%" }}
            allowClear
          />
          <DatePicker.RangePicker
            value={[
              filters.filter.startDate && moment(filters.filter.startDate),
              filters.filter.endDate && moment(filters.filter.endDate),
            ]}
            onChange={dateFilter}
          />
          <Button onClick={onClear}>Цэвэрлэх</Button>
        </Filter>
        <MyTable
          scroll={{ x: "max-content" }}
          style={{ marginTop: "20px" }}
          columns={columns}
          ref={myTableRef}
          filters={filters}
          loadData={noticeApi.list}
          rowKey={(record) => record._id}
        />
      </PageContent>
    </PageContainer>
  );
};

const useHeader = ({ s3host, onClick }) => {
  return [
    {
      title: "№",
      dataIndex: "i",
      width: "20px",
    },
    {
      title: "Нийтлэсэн хэрэглэгч",
      render: (record) => {
        return (
          <div style={{ display: "flex", gap: "8px", alignItems: "center" }}>
            <Avatar
              src={
                record?.user?.avatar
                  ? `${s3host}/${record?.user?.avatar}`
                  : null
              }
              icon={<UserOutlined />}
            />
            <div>
              {record?.user?.lastName || ""} {record?.user?.firstName || "-"}
            </div>
          </div>
        );
      },
    },
    {
      title: "Тайлбар",
      render: (record) => {
        if (record?.text?.length > 25) {
          return <>{record.text.substring(0, 24)} ...</>;
        }
        return record.text || "-";
      },
    },
    {
      title: "Зураг",
      render: (record) => {
        return (
          <>
            {record?.image ? (
              <Avatar
                icon={<CameraOutlined />}
                src={`${s3host}/${record?.image}`}
                size={40}
                shape="square"
              />
            ) : (
              "-"
            )}
          </>
        );
      },
    },
    {
      title: "Таалагдсан",
      render: (record) => {
        return <Tag>{record.likeCount || 0}</Tag>;
      },
    },
    {
      title: "Өөрчлөгдсөн огноо",
      render: (record) => {
        // return datetimeFormat(record?.postStatusDate || "-");
        return record?.postStatusDate
          ? datetimeFormat(record.postStatusDate)
          : "-";
      },
    },
    {
      title: "Үүссэн огноо",
      render: (record) => {
        return datetimeFormat(record.createdAt);
      },
    },
    {
      title: "Үйлдэл",
      width: 100,
      fixed: "right",
      align: "center",
      render: (row) => {
        return (
          <RowAction
            actions={{
              edit: "Засах",
              remove: "Устгах",
            }}
            onClick={(key) => onClick(key, row)}
          />
        );
      },
    },
  ];
};

export default NoticeList;
