import moment from "moment";
import numeral from "numeral";
import request from "./request";

const dateFormat = (date) => moment(date).format("YYYY-MM-DD");
const datetimeFormat = (date) => moment(date).format("YYYY-MM-DD HH:mm");

let mailformat =
  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
const phoneNumber = /^[1-9]{1}[0-9]{5,7}$/gm;
const mongoliaText = /^[А-Яа-я||Үү||Өө||0-9]+$/;
const lowercaseRegex = /(?=.*[a-z])/;
const uppercaseRegex = /(?=.*[A-Z])/;
const numericRegex = /(?=.*[0-9])/;
const passwordRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]/;
const mongolText = /^[А-Яа-я||Үү||Өө||\-|| |-|]+$/;
const latin = /^[A-Za-z||Aa||Zz||_|0-9|| |-]+$/;
const latinNo = /^[A-Za-z||Aa||Zz|||-]+$/;
const websiteRegex =
  /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/;
const otpPinRegex = /^[0-9]{4}$/;
const number = /^[0-9\b]+$/gm;
numeral.register("locale", "mn", {
  delimiters: {
    thousands: ",",
    decimal  : ",",
  },
  abbreviations: {
    thousand: "k",
    million : "m",
    billion : "b",
    trillion: "t",
  },
  currency: {
    symbol: "$",
  },
});

const reg = "[а-яА-ЯёЁөӨүҮ]{2}[0-9]{8}";

numeral.locale("mn");

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

const tailFormItemLayout = {
  style: {
    marginTop: 20,
  },
  wrapperCol: {
    xs: {
      span  : 24,
      offset: 0,
    },
    sm: {
      span  : 16,
      offset: 8,
    },
  },
};

export {
  request,
  dateFormat,
  datetimeFormat,
  formItemLayout,
  tailFormItemLayout,
  mailformat,
  phoneNumber,
  mongoliaText,
  lowercaseRegex,
  uppercaseRegex,
  numericRegex,
  passwordRegex,
  mongolText,
  latin,
  latinNo,
  websiteRegex,
  number,
  reg,
  otpPinRegex
};
