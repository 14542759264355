import { Button, message, notification, Row } from "antd";
import { Formik } from "formik";
import { Form, FormItem, Input, Select } from "formik-antd";
import React from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import styled from "styled-components";
import * as Yup from "yup";
import { staff as staffApi } from "../../apis";
import { ImageUpload } from "../../components";
import { StyledForm } from "../../components/Design";
import { mongolText } from "../../utils";

const { TextArea } = Input;

const FormSchema = () => Yup.object().shape({
  firstName: Yup.string()
    .trim()
    .matches(mongolText, "Та кириллээр бичнэ үү.")
    .required("Заавал бөглөнө!")
    .max(45, "Хэт урт байна!")
    .when((data) => {
      let reWhiteSpace = /\s+/;
      let text = data?.trim();
      if (reWhiteSpace.test(text)) {
        return Yup.string()
          .matches(mongolText, "Та кириллээр бичнэ үү.")
          .required("Заавал бөглөнө!");
      }
    }),
  lastName: Yup.string()
    .trim()
    .matches(mongolText, "Та кириллээр бичнэ үү.")
    .required("Заавал бөглөнө!")
    .max(45, "Хэт урт байна!")
    .when((data) => {
      let reWhiteSpace = /\s+/;
      let text = data?.trim();
      if (reWhiteSpace.test(text)) {
        return Yup.string()
          .matches(mongolText, "Та кириллээр бичнэ үү.")
          .required("Заавал бөглөнө!");
      }
    }),
  email   : Yup.string().email("И-мэйл хаяг буруу байна").required("Заавал бөглөнө!"),
  phone   : Yup.string().min(8, "Дугаарын орон багадаа 8 байна!").max(8, "Дугаарын орон ихдээ 8 орон байна!").required("Заавал бөглөнө!"),
  address : Yup.string().required("Заавал бөглөнө!").max(255, "Хэт урт байна!").trim(),
  avatar  : Yup.string().required("Заавал бөглөнө!"),
  role    : Yup.string().required("Заавал бөглөнө!"),
  password: Yup.string().when((data, schema, test) => {
    if (!test.parent.isPassword)
      return Yup.string()
        .min(6, "Нууц үг 6-с их орон байх хэрэгтэй!")
        .max(20, "Нууц үг 20-с бага орон байх хэрэгтэй!")
        .required("Заавал бөглөнө!");
  }),
  confirmPassword: Yup.string().when((data, schema, test) => {
    if (!test.parent.isPassword)
      return Yup
        .string()
        .required("Заавал бөглөнө!")
        .oneOf([Yup.ref("password"), null], "Нууц үг таарахгүй байна!");
  }),
});

const FormStaff = ({ action }) => {
  const history = useHistory();
  const { rolePermission } = useSelector(state => state.general);

  const [data] = React.useState({
    firstName      : undefined,
    lastName       : undefined,
    email          : undefined,
    phone          : undefined,
    address        : undefined,
    avatar         : undefined,
    password       : undefined,
    confirmPassword: undefined,
    role           : rolePermission[0]?.code,
    ...(action && action[0] === "update" ? action[1]: {}),
    isPassword     : action && action[0] === "update",
  });

  const onSubmit = async (values) => {
    if (action && action[0] === "update") {
      try {
        await staffApi.update(action[1]?._id, { ...values, phone: values.phone.toString() });
        history.push("/staff");
        message.success("Таны хүсэлт амжилттай!");
      } catch (error) {
        if (error.message)
          notification.error({
            message: error.message
          });
      }
    } else {
      try {
        await staffApi.create({ ...values, phone: values.phone.toString() });
        history.push("/staff");
        message.success("Таны хүсэлт амжилттай!");
      }
      catch (error) {
        if (error.message)
          notification.error({
            message: error.message
          });
      }
    }
  };

  return (
    <Container>
      <Formik
        enableReinitialize
        initialValues={data}
        validationSchema={FormSchema}
        onSubmit={onSubmit}>
        {({ isSubmitting, values }) => (
          <StyledForm>
            <Form layout="vertical">
              <FormItem label="Зураг" name="avatar">
                <ImageUpload action="/api/general/upload/image" name="avatar" />
              </FormItem>
              <FormItem name="lastName" label="Овог" required>
                <Input prefix={<span/>} name="lastName" placeholder="Овог" />
              </FormItem>
              <FormItem name="firstName" label="Нэр" required>
                <Input prefix={<span/>} name="firstName" placeholder="Нэр" />
              </FormItem>
              <FormItem name="phone" label="Утас" required>
                <Input prefix={<span/>} name="phone" type='number' placeholder="Утас" />
              </FormItem>
              <FormItem name="email" label="И-мэйл" required>
                <Input prefix={<span/>} name="email" placeholder="И-мэйл" />
              </FormItem>

              { action && action[0] !== "update" &&
              <div className="password-wrapper">
                <FormItem name="password" label="Нууц үг" required>
                  <Input.Password prefix={<span/>} name="password" placeholder="Нууц үг" />
                </FormItem>
                <FormItem name="confirmPassword" label="Нууц үг давтах" required>
                  <Input.Password prefix={<span/>} name="confirmPassword" placeholder="Нууц үг давтах" />
                </FormItem>
              </div>
              }
              <FormItem name="role" label="Хандах эрх" required>
                <Select name="role" placeholder="Хандах эрх" >
                  {rolePermission.map((item, index) => <Select.Option value={item.code} key={index}>
                    {item.name}
                  </Select.Option>)}
                </Select>
              </FormItem>
              <FormItem name="address" label="Хаяг" required>
                <TextArea showCount maxLength={255} name="address" placeholder="Хаяг" rows={6} />
              </FormItem>
              <Row justify="end" style={{ marginTop: "40px" }}>
                <Button className="button" style={{ marginRight: 10 }} htmlType="submit" type="primary" loading={isSubmitting}>Хадгалах</Button>
                <Button className="button" type="default" onClick={() => history.push("/staff")}>Болих</Button>
              </Row>
            </Form>
          </StyledForm>
        )}
      </Formik>
    </Container>
  );
};

const Container = styled.div`
  margin-top: 20px;
  
  .password-wrapper { 
    background: #f9f9f9;
    border: 1px solid #D9D9D9;
    border-radius: 4px;
    padding: 15px;
    padding-bottom: 0px;
    margin-bottom: 20px;
    animation: translating 0.5s linear infinite;
    transition: 0.5s ease;
  }
  @media (max-width: 1000px){
    width:100%;
  }
`;


export default FormStaff;