import { Spin } from "antd";
import React from "react";
import { useParams } from "react-router-dom";
import { sector as sectorApi } from "../../apis";
import {
  PageContainer,
  PageContent,
  PageHeaders,
} from "../../components/Layout";
import Form from "./Form";

const SectorEdit = () => {
  const params = useParams();
  const [action, setAction] = React.useState([]);

  const reload = React.useCallback(
    async (signal) => {
      let res = await sectorApi.get(params.id, null, { signal });

      setAction(["update", res]);
    },
    [params.id]
  );

  React.useEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;

    reload(signal);

    return () => abortController.abort();
  }, [reload]);

  return (
    <PageContainer>
      <PageHeaders title="Байгууллагын мэдээлэл засах" />
      <PageContent>
        <Spin spinning={!action[1]}>
          {action[1] && <Form action={action} />}
        </Spin>
      </PageContent>
    </PageContainer>
  );
};

export default SectorEdit;
