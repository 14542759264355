import { Button, message, Row } from "antd";
import { Formik } from "formik";
import { Form, FormItem, Input } from "formik-antd";
import React from "react";
import { useHistory } from "react-router";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import * as Yup from "yup";
import { sector as sectorApi } from "../../apis";
import { ImageUpload } from "../../components";
import { StyledForm } from "../../components/Design";

const SectorForm = ({ action }) => {
  const FormSchema = () =>
    Yup.object().shape({
      // eslint-disable-next-line key-spacing
      avatar: Yup.string().required("Заавал бөглөнө!"),
      fullName: Yup.string()
        .required("Заавал бөглөнө!")
        .max(2, "Хэт богино байна!")
        .max(255, "Хэт урт байна!")
        .trim(),
      phone: Yup.string()
        .min(8, "Дугаарын орон багадаа 8 байна!")
        .max(11, "Дугаарын орон ихдээ 11 орон байна!")
        .required("Заавал бөглөнө!")
        .trim(),
      email: Yup.string()
        .max(45, "Хэт урт байна!")
        .email("И-мэйл хаяг буруу байна")
        .required("Заавал бөглөнө!")
        .trim(),
      address: Yup.string().required("Заавал бөглөнө!").trim(),
    });

  const history = useHistory();
  const params = useParams();
  const [data] = React.useState({
    // eslint-disable-next-line key-spacing
    avatar: undefined,
    fullName: undefined,
    // eslint-disable-next-line key-spacing
    phone: undefined,
    // eslint-disable-next-line key-spacing
    email: undefined,
    // eslint-disable-next-line key-spacing
    address: undefined,
    ...(action && action[0] === "update" ? action[1].sector : {}),
  });

  const onSubmit = async (values) => {
    if (action && action[0] === "update") {
      try {
        await sectorApi.update(params.id, {
          ...values,
          // eslint-disable-next-line key-spacing
          phone: values.phone.toString(),
          firstName: values.fullName,
          // eslint-disable-next-line key-spacing
          lastName: values.fullName,
        });
        history.push("/sector");
        message.success("Таны хүсэлт амжилттай!");
      } catch (error) {
        message.error("Хүсэлт амжилтгүй");
      }
    } else {
      try {
        await sectorApi.create({ ...values, phone: values.phone.toString() });
        history.push("/sector");
        message.success("Таны хүсэлт амжилттай!");
      } catch (error) {
        message.error("Хүсэлт амжилтгүй");
      }
    }
  };

  return (
    <Container className="flex justify-center">
      <Formik
        enableReinitialize
        initialValues={data}
        validationSchema={FormSchema}
        onSubmit={onSubmit}
      >
        {({ isSubmitting, errors }) => (
          <StyledForm>
            <Form layout="vertical">
              <FormItem label="Лого" name="avatar" required>
                <ImageUpload action="/api/general/upload/image" name="avatar" />
              </FormItem>
              <FormItem name="fullName" label="Нэр" required>
                <Input prefix={<span />} name="fullName" placeholder="Нэр" />
              </FormItem>
              <FormItem name="phone" label="Утас" required>
                <Input
                  prefix={<span />}
                  name="phone"
                  type="number"
                  placeholder="Утас"
                />
              </FormItem>
              <FormItem name="email" label="И-мэйл" required>
                <Input prefix={<span />} name="email" placeholder="И-мэйл" />
              </FormItem>
              <FormItem name="address" label="Хаяг" required>
                <Input.TextArea
                  showCount
                  maxLength={500}
                  rows={6}
                  prefix={<span />}
                  name="address"
                  placeholder="Хаяг"
                />
              </FormItem>
              <Row justify="end" style={{ marginTop: "40px" }}>
                <Button
                  className="button"
                  style={{ marginRight: 10 }}
                  htmlType="submit"
                  type="primary"
                  loading={isSubmitting}
                >
                  Хадгалах
                </Button>
                <Button
                  className="button"
                  type="default"
                  onClick={() => history.push("/sector")}
                >
                  Болих
                </Button>
              </Row>
            </Form>
          </StyledForm>
        )}
      </Formik>
    </Container>
  );
};

const Container = styled.div`
  margin-top: 20px;
  width: 80%;
  @media (max-width: 1000px) {
    width: 100%;
  }
`;

export default SectorForm;
