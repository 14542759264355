import { Button, message, Row } from "antd";
import { Formik } from "formik";
import { Form, FormItem, Input } from "formik-antd";
import React from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import * as Yup from "yup";
import { notify as notifyApi } from "../../apis";
import { ImageUpload } from "../../components";
import { StyledForm } from "../../components/Design";

const FormSchema = () =>
  Yup.object().shape({
    title: Yup.string()
      .required("Заавал бөглөнө!")
      .max(255, "Хэт урт байна!")
      .trim(),
    body: Yup.string()
      .required("Заавал бөглөнө!")
      .max(2000, "Хэт урт байна!")
      .trim(),
  });

const NotifyForm = ({ action }) => {
  const history = useHistory();
  const [data] = React.useState({
    // eslint-disable-next-line key-spacing
    post: undefined,
    // eslint-disable-next-line key-spacing
    title: undefined,
    // eslint-disable-next-line key-spacing
    body: undefined,
    sector: undefined,
    // eslint-disable-next-line key-spacing
    image: undefined,
    ...(action && action[0] === "update" ? action[1] : {}),
  });

  const onSubmit = async (data) => {
    let res;
    if (action && action[0] === "update") {
      try {
        res = await notifyApi.update(action[1]._id, data);
        message.success("Таны хүсэлт амжилттай!");
      } catch (error) {
        console.log(error);
        message.error("Амжилтгүй");
      }
    } else {
      try {
        res = await notifyApi.create(data);
        message.success("Таны хүсэлт амжилттай!");
      } catch (error) {
        console.log(error);
        message.error("Амжилтгүй");
      }
    }
    history.push("/notify");
  };

  return (
    <StyledForm>
      <Formik
        enableReinitialize
        initialValues={data}
        validationSchema={FormSchema}
        onSubmit={onSubmit}
      >
        {({ isSubmitting }) => (
          <Form layout="vertical">
            <h3>
              {" "}
              {data?.notifyStatus === "SENT" &&
                "Илгээсэн мэдэгдэл засаж болохгүй"}
            </h3>
            <FormItem name="title" label="Гарчиг" required>
              <Input
                prefix={<span />}
                name="title"
                placeholder="Гарчиг"
                disabled={data?.notifyStatus === "SENT"}
              />
            </FormItem>
            <FormItem name="body" label="Тайлбар" required>
              <Input.TextArea
                rows={6}
                showCount
                maxLength={2000}
                name="body"
                placeholder="Тайлбар"
                disabled={data?.notifyStatus === "SENT"}
              />
            </FormItem>
            <FormItem name="image" label="Зураг">
              <ImageUploadStyled>
                <ImageUpload
                  name="image"
                  action="/api/general/upload/image"
                  disabled={data?.notifyStatus === "SENT"}
                />
              </ImageUploadStyled>
            </FormItem>
            <Row justify="end">
              <Button
                className="button"
                style={{ marginRight: 10 }}
                htmlType="submit"
                type="primary"
                loading={isSubmitting}
                disabled={data?.notifyStatus === "SENT"}
              >
                Илгээх
              </Button>
              <Button
                className="button"
                type="default"
                onClick={() => history.push("/notify")}
              >
                Болих
              </Button>
            </Row>
          </Form>
        )}
      </Formik>
    </StyledForm>
  );
};

const ImageUploadStyled = styled.div`
  width: 100%;
  min-width: 100%;
  max-height: 400px;
  min-height: 300px;
  margin-bottom: 15px;

  .ant-upload.ant-upload-select-picture-card {
    min-width: 100%;
    max-height: 400px;
    min-height: 300px;
  }

  .form-upload-image {
    width: 100%;
  }

  .image-view {
    width: 100%;
  }

  @media (max-width: 900px) {
    min-height: 150px;
    .ant-upload.ant-upload-select-picture-card {
      min-height: 150px;
    }
  }
`;
export default NotifyForm;
