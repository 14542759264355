/* eslint-disable key-spacing */
import {
  FileImageOutlined,
  PhoneOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Avatar, Skeleton, Space, Table, Tag } from "antd";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { post as postApi } from "../../../apis";
import { datetimeFormat } from "../../../utils";

const CommentReject = () => {
  const { s3host } = useSelector((state) => state.general);
  const params = useParams();

  const [history, setHistory] = useState();

  const columns = useHeader({ s3host });

  const historyInit = async () => {
    try {
      let res = await postApi.history(params.id);
      setHistory(res);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    historyInit();
  }, []);

  return (
    <div style={{ marginTop: "24px" }}>
      <h2>Хуваарилалтын түүх</h2>
      <div style={{ width: "100%", overflow: "auto", marginTop: "24px" }}>
        <StyledTable
          rowKey={(e) => `${e?._id}`}
          pagination={false}
          dataSource={history || []}
          columns={columns}
        />
      </div>
    </div>
  );
};

const StyledTable = styled(Table)``;

const useHeader = ({ s3host }) => {
  return [
    {
      title: "№",
      dataIndex: "i",
      width: "20px",
    },
    {
      title: "Үйлдэл",
      render: (record) => {
        if (record?.selfAssigned) return <Tag color="pink">Хүлээн авсан</Tag>;
        if (record?.isRefused) return <Tag color="blue">Буцаасан</Tag>;
        return <Tag color="gold">Хуваарилсан</Tag>;
      },
    },
    {
      title: "Хуваарилагдсан салбар",
      render: (record) => {
        if (record?.isRefused)
          return (
            <>
              <Space>
                <Avatar
                  size={35}
                  src={`${s3host}${record?.refSector?.avatar}`}
                  shape="square"
                  icon={<FileImageOutlined />}
                />
                <Space direction="vertical" size={4}>
                  {record?.refSector?.fullName || "-"}
                  {record?.refSector?.phone && (
                    <Space>
                      <PhoneOutlined />
                      {`${record?.refSector?.phone}`}
                    </Space>
                  )}
                </Space>
              </Space>
            </>
          );
        return (
          <>
            <Space>
              <Avatar
                size={35}
                src={`${s3host}${record?.sector?.avatar}`}
                shape="square"
                icon={<FileImageOutlined />}
              />
              <Space direction="vertical" size={4}>
                {record?.sector?.fullName || (
                  <Skeleton.Input size="small" block />
                )}
                {record?.sector?.phone ? (
                  <Space>
                    <PhoneOutlined />
                    {`${record?.sector?.phone}`}
                  </Space>
                ) : (
                  <>
                    {" "}
                    <Skeleton.Input width={100} height={10} size="small" />
                  </>
                )}
              </Space>
            </Space>
          </>
        );
      },
    },
    {
      title: "Хүлээн авсан салбарын ажилтан",
      render: (record) => {
        if (record?.isRefused)
          return record?.refUser ? (
            <Space>
              <Avatar
                size={35}
                src={`${s3host}${record?.refUser?.avatar}`}
                icon={<UserOutlined />}
              />
              <Space direction="vertical" size={4}>
                <Space size={2}>
                  <div>
                    {`${record?.refUser?.lastName?.substring(0, 1)}.` || ""}
                  </div>
                  {record?.refUser?.firstName}
                </Space>
                {record?.refUser?.phone ? (
                  <Space>
                    <PhoneOutlined />
                    {`${record?.refUser?.phone}`}
                  </Space>
                ) : (
                  <>
                    {" "}
                    <Skeleton.Input width={100} height={10} size="small" />
                  </>
                )}
              </Space>
            </Space>
          ) : (
            "-"
          );

        return record?.sectorUser ? (
          <Space>
            <Avatar
              size={35}
              src={`${s3host}${record?.sectorUser?.avatar}`}
              icon={<UserOutlined />}
            />
            <Space direction="vertical" size={4}>
              <Space size={2}>
                <div>
                  {`${record?.sectorUser?.lastName?.substring(0, 1)}.` || ""}
                </div>
                {record?.sectorUser?.firstName}
              </Space>
              {record?.sectorUser?.phone ? (
                <Space>
                  <PhoneOutlined />
                  {`${record?.sectorUser?.phone}`}
                </Space>
              ) : (
                <>
                  {" "}
                  <Skeleton.Input width={100} height={10} size="small" />
                </>
              )}
            </Space>
          </Space>
        ) : (
          "-"
        );
      },
    },
    {
      title: "Огноо",
      render: (record) => {
        if (record?.isRefused)
          return record?.refusedDate
            ? datetimeFormat(record?.refusedDate)
            : "-";
        return record?.assignedDate
          ? datetimeFormat(record?.assignedDate)
          : "-";
      },
    },
    {
      title: "Тайлбар",
      render: (record) => {
        return (
          <>{record?.refuse ? `${record?.refuse.substring(0, 24)}...` : "-"}</>
        );
      },
    },
  ];
};

export default CommentReject;
