/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable key-spacing */
import {
  Avatar,
  Badge,
  Col,
  Divider,
  message,
  notification,
  PageHeader,
  Radio,
  Row,
} from "antd";
import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { PageContainer, PageContent } from "../../components/Layout";
import PolicyForm from "./components/PolicyForm";
import NoImage from "../../assets/noimage.png";
import ContactForm from "./components/ContactForm";
import AboutForm from "./components/AboutForm";
import { useSelector } from "react-redux";
import { CameraOutlined } from "@ant-design/icons";
import ImageUploadBtn from "../../components/ImageUploadBtn";
import { aboutUs as aboutApi } from "../../apis";
import { Form, Formik } from "formik";
import { FormItem } from "formik-antd";
import TermsForm from "./components/TermsForm";

const About = () => {
  const [formKey, setFormKey] = useState("contact");
  const { s3host } = useSelector((state) => state.general);
  const [avatar, setAvatar] = useState();
  const [cover, setCover] = useState();
  const [loadingCover, setLoadingCover] = useState(false);
  const [loadingAvatar, setLoadingAvatar] = useState(false);
  const [data, setData] = useState({
    fullName: undefined,
    email: undefined,
    role: undefined,
    address: undefined,
    avatar: undefined,
    phone: undefined,
    policy: undefined,
    about: undefined,
    cover: undefined,
  });

  const aboutInit = async () => {
    try {
      let res = await aboutApi.get();
      setData(res || data);

      if (res?.cover) {
        setLoadingCover(true);
        setCover(`${s3host}${res?.cover}`);
      }
      if (res?.avatar) {
        setLoadingAvatar(true);
        setAvatar(`${s3host}${res?.avatar}`);
      }
    } catch (error) {
      if (error.message) message.error(error.message || "Алдаа!");
    }
    setLoadingCover(false);
    setLoadingAvatar(false);
  };

  useEffect(() => {
    aboutInit();
  }, []);

  return (
    <PageContainer>
      <PageHeader title="Бидний мэдээлэл" />
      <PageContent>
        <Row>
          <Col span={24}>
            <StyledProfile bg={!loadingCover && cover ? cover : ""}>
              <div className="cover">
                <div className="upload-cover">
                  <Formik initialValues={data}>
                    {({ isSubmitting, values }) => {
                      return (
                        <Form layout="vertical">
                          <FormItem name="cover">
                            <ImageUploadBtn
                              name="cover"
                              action="/api/about/avatar/COVER"
                              type="upload"
                              disable={isSubmitting}
                              extraFunc={() => {
                                aboutInit();
                              }}
                            />
                          </FormItem>
                        </Form>
                      );
                    }}
                  </Formik>
                </div>
              </div>
              <Row>
                <div className="">
                  <Badge
                    count={
                      <div>
                        <Formik initialValues={data}>
                          {({ isSubmitting }) => {
                            return (
                              <Form layout="vertical">
                                <FormItem name="avatar">
                                  <ImageUploadBtn
                                    name="avatar"
                                    action="/api/about/avatar/AVATAR"
                                    type="upload"
                                    disable={isSubmitting}
                                    extraFunc={() => {
                                      aboutInit();
                                    }}
                                  />
                                </FormItem>
                              </Form>
                            );
                          }}
                        </Formik>
                      </div>
                    }
                    offset={[-24, 76]}
                  >
                    <Avatar
                      src={!loadingAvatar && avatar ? `${avatar}` : NoImage}
                      icon={<CameraOutlined />}
                      size={140}
                      className="avatar-ant"
                      shape="square"
                    />
                  </Badge>
                </div>
                <div className="profile-name">
                  <div className="fullname">{data?.fullName}</div>
                  <div className="mine-role">{data?.email}</div>
                </div>
              </Row>
            </StyledProfile>
            <Divider />
          </Col>
          <Col xs={24} sm={24} md={24}>
            <Radio.Group
              value={formKey}
              onChange={(e) => setFormKey(e.target.value)}
              size="large"
              style={{ width: "100%", marginBottom: "24px" }}
            >
              <Row gutter={[5, 5]}>
                <Col xs={24} sm={24} md={12} lg={6}>
                  <Radio.Button
                    style={{
                      width: "100%",
                      textAlign: "center",
                      minWidth: "max-content",
                    }}
                    value="contact"
                  >
                    Холбоо барих
                  </Radio.Button>
                </Col>
                <Col xs={24} sm={24} md={12} lg={6}>
                  <Radio.Button
                    style={{
                      width: "100%",
                      textAlign: "center",
                      minWidth: "max-content",
                    }}
                    value="about"
                  >
                    Бидний тухай
                  </Radio.Button>
                </Col>
                <Col xs={24} sm={24} md={12} lg={6}>
                  <Radio.Button
                    style={{
                      width: "100%",
                      textAlign: "center",
                      minWidth: "max-content",
                    }}
                    value="policy"
                  >
                    Нууцлалын журам
                  </Radio.Button>
                </Col>
                <Col xs={24} sm={24} md={12} lg={6}>
                  <Radio.Button
                    style={{
                      width: "100%",
                      textAlign: "center",
                      minWidth: "max-content",
                    }}
                    value="terms"
                  >
                    Үйлчилгээний нөхцөл
                  </Radio.Button>
                </Col>
              </Row>
            </Radio.Group>
          </Col>
          {formKey === "contact" && (
            <Col xs={24} sm={24} md={24} lg={12} xxl={8}>
              <h2>Холбоо барих</h2>
              <br />
              <ContactForm />
            </Col>
          )}

          {formKey === "about" && (
            <Col span={24}>
              <h2>Бидний тухай</h2>
              <br />
              <AboutForm setVisible={{}} />
            </Col>
          )}

          {formKey === "policy" && (
            <Col span={24}>
              <h2>Нууцлалын журам</h2>
              <br />
              <PolicyForm setVisible={{}} />
            </Col>
          )}

          {formKey === "terms" && (
            <Col span={24}>
              <h2>Үйлчилгээний нөхцөл</h2>
              <br />
              <TermsForm setVisible={{}} />
            </Col>
          )}
        </Row>
      </PageContent>
    </PageContainer>
  );
};

const StyledProfile = styled.div`
  .profile-name {
    margin-left: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .fullname {
      font-weight: 500;
      font-size: 18px;
      margin-bottom: 5px;
    }
    .mine-role {
      display: flex;
      font-size: 16px;
      font-weight: 400;
      color: rgb(132, 132, 132);
      height: 32px;
      -webkit-box-align: center;
      align-items: center;
    }
  }
  .avatar-ant {
    border-radius: 5px;
    border: 1px solid #dddddd;
    margin-top: -50px;
    margin-left: 20px;
    background: #333;
  }

  .cover {
    background: #333;
    background-image: url(${(props) => props.bg});
    object-fit: cover;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 240px;
    border-radius: 2px;
    position: relative;
    .upload-cover {
      position: absolute;
      right: 20px;
      bottom: 0px;
    }
  }

  .image {
    border: 4px solid #fafafa;
    width: min-content;
    border-radius: 5px;
  }
`;

export default About;
