import { LockOutlined, UserOutlined } from "@ant-design/icons";
import { Button, Card, notification, Space } from "antd";
import { Formik } from "formik";
import { Checkbox, Form, FormItem, Input } from "formik-antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import * as Yup from "yup";
import { login } from "../../apis/auth";
import Logo from "../../assets/logo.png";

const FormSchema = Yup.object().shape({
  username: Yup.string()
    .email("И-мэйл хаяг оруулна уу!")
    .required("Заавал бөглөнө!")
    .typeError("И-мэйл хаяг буруу байна!")
    .nullable()
    .trim(),
  password: Yup.string().required("Нууц үг оруулна уу"),
});

const Login = () => {
  const dispatch = useDispatch();
  const { remember } = useSelector(state => state.auth);

  const onSubmit = async (data, actions) => {
    try {
      let res = await login({ ...data, username: data?.username?.toLowerCase() });

      if (res) {
        dispatch({
          type   : "auth.remember",
          payload: {
            username: data.remember ? data?.username?.toLowerCase()?.trim() : null,
            remember: data.remember && data?.remember
          }
        });
        dispatch({
          type   : "auth.change",
          payload: res || undefined
        });
      }
    } catch (err) {
      if (err?.message)
        notification.error({
          message: err?.message || "Системд алдаа гарсан."
        });
      actions.setSubmitting(false);
    }
  };

  return (
    <LoginWrapper>
      <Card className="login">
        <Space size={0} className="login-head">
          <div className="logo-image">
            <img src={Logo} alt="" width={"40px"} height={"40px"} />
          </div>
          <div>
            <div className="subname">Дархан-Уул аймаг</div>
            <div className="little-text">Эрсдэлийн мэдэгдэл</div>
          </div>
        </Space>
        <Formik
          initialValues={{
            username: undefined,
            password: undefined,
            ...remember,
            remember: !!remember?.username
          }}
          validationSchema={FormSchema}
          onSubmit={onSubmit}>
          {({ isSubmitting }) => (
            <Form layout={"vertical"}>
              <FormItem name="username">
                <Input prefix={<UserOutlined />} name="username" placeholder="И-мэйл хаяг" />
              </FormItem>
              <FormItem name="password" style={{ marginBottom: 20 }}>
                <Input.Password prefix={<LockOutlined />} name="password" placeholder="Нууц үг" />
              </FormItem>
              <div className="forgot">
                <a href="/forgot">Нууц үгээ мартсан!</a>
              </div>
              <div className="remember">
                <Checkbox name="remember">Намайг сана</Checkbox>
              </div>
              <Button htmlType="submit" type="primary" loading={isSubmitting} block>Нэвтрэх</Button>
            </Form>
          )}
        </Formik>
      </Card>
    </LoginWrapper>
  );
};

const LoginWrapper = styled.div`
  background: #f8f8f8;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  justify-content: center;
  display: flex;
  flex-direction: center;
  align-items: center;
  
  .login-head { 
    margin-bottom: 10px;
  }
  .subname { 
    margin-left: 10px;
    font-weight: 600;
    text-transform: uppercase;
    color: #333; 
  }
  .little-text { 
    margin-left: 10px;
    text-transform: capitalize;
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 1rem;
  }
  .forgot {
    text-align: right;
    margin-top: 20px;
  }
  .login {
    background: #fff;
    border: 1px solid #e8e8e8;
    width: 320px;
    margin: auto;
    padding-bottom: 10px;
    .alert {
      font-size: 12px;
      padding: 10px 10px 10px 35px;
      margin-bottom: 20px;
    }
    .logo {
      text-align: start;
      margin: 10px 0 0px 0;
      font-size: 18px; 
      font-weight: 600;
      text-transform: uppercase;
      color: #333;

      img {
        height: 50px;
        margin: auto;
      }
    }
    .forgot {
      text-align: right;
      margin-top: 20px;
    }
    .remember {
      margin-bottom: 10px;
    }
  }
`;

export default Login;