import { PlusOutlined } from "@ant-design/icons";
import { Button, Tabs } from "antd";
import React, { useRef } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  PageContainer,
  PageContent,
  PageHeaders,
} from "../../components/Layout";
import UserList from "./components/UserList";
import UserSectorList from "./components/UserSectorList";

const { TabPane } = Tabs;

const AppList = () => {
  const history = useHistory();
  const { isMobile } = useSelector((state) => state.general);
  const myTableRefUser = useRef();
  const myTableRefSector = useRef();

  return (
    <PageContainer>
      <PageHeaders
        title="Хэрэглэгчийн жагсаалт"
        extra={
          <Button
            type="primary"
            icon={isMobile && <PlusOutlined />}
            onClick={() => history.push("/webuser/new")}
          >
            {!isMobile && "Хэрэглэгч нэмэх"}
          </Button>
        }
      />
      <PageContent>
        <Tabs
          defaultActiveKey="1"
          onChange={() => {
            myTableRefUser.current.reload();
            myTableRefSector.current.reload();
          }}
        >
          <TabPane tab="Хэрэглэгчид" key="1">
            <UserList myTableRef={myTableRefUser} />
          </TabPane>
          <TabPane tab="Салбар нэгжийн ажилчид" key="2">
            <UserSectorList myTableRef={myTableRefSector} />
          </TabPane>
        </Tabs>
      </PageContent>
    </PageContainer>
  );
};

export default AppList;
