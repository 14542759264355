import {
  ExclamationCircleOutlined,
  LockFilled,
  MailOutlined,
  PhoneOutlined,
  UnlockOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Avatar, Button, Card, Image, message, Modal, Result, Row } from "antd";
import moment from "moment";
import React from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { post as postApi } from "../../../apis";
import Fallback from "../../../assets/fallback.png";

const { Meta } = Card;

const CardSolved = ({ data, reloadData }) => {
  const { s3host, isMobile } = useSelector((state) => state.general);

  const params = useParams();

  const isActivePost = async (value) => {
    Modal.confirm({
      // eslint-disable-next-line key-spacing
      title: "Баталгаажуулах",
      // eslint-disable-next-line key-spacing
      icon: <ExclamationCircleOutlined />,
      // eslint-disable-next-line key-spacing
      okText: "Тийм",
      content: `Та үүнийг ${!value ? "ИДЭВХГҮЙ" : "ИДЭВХТЭЙ"} болгох уу!`,
      // eslint-disable-next-line key-spacing
      onOk: async () => {
        try {
          await postApi.hide(params.id, {
            isActive: value,
          });
          message.success("Төлөв амжилттай солигдлоо.");
        } catch (error) {
          if (error.message) {
            message.error(error.message);
          }
        }
        reloadData();
      },
    });
  };

  return (
    <CardWrapper>
      {!data.isActive ? (
        <>
          <Result
            icon={
              <LockFilled
                height={"100px"}
                width={"100px"}
                style={{ color: "red" }}
              />
            }
            title={"Эрсдэлийг идэвхжүүлэх үү ?"}
            extra={
              <Button
                onClick={() => isActivePost(true)}
                type="primary"
                icon={<UnlockOutlined />}
              >
                {!isMobile && "Тийм"}
              </Button>
            }
          />
        </>
      ) : (
        <>
          <Meta
            avatar={
              <Avatar
                size={55}
                shape="square"
                src={`${s3host}/${data.sector?.avatar}`}
                icon={<UserOutlined />}
              />
            }
            title={
              <Row justify="space-between">
                <div>{data?.sector?.fullName}</div>
                <div className="date">
                  {moment(data.resultDate).format("YYYY MM/DD hh:mm")}
                </div>
              </Row>
            }
            description={
              <div className="tag">
                Шийдвэрлэсэн салбар / <MailOutlined /> {data?.sector?.email}
              </div>
            }
          />
          <div className="content-image">
            <Image
              fallback={Fallback}
              width={"100%"}
              height={"100%"}
              src={`${s3host}${data.resultImage}`}
            />
          </div>
          {data?.sectorUser && (
            <Meta
              avatar={
                <Avatar
                  size={40}
                  src={`${s3host}/${data.sectorUser?.avatar}`}
                  icon={<UserOutlined />}
                />
              }
              title={
                <Row justify="space-between">
                  <div>
                    {data?.sectorUser?.lastName &&
                      `${data?.sectorUser?.lastName.substring(0, 1)}.`}
                    {data?.sectorUser?.firstName}
                  </div>
                </Row>
              }
              description={
                <div className="tag">
                  Шийдвэрлэсэн ажилтан | <PhoneOutlined />{" "}
                  {data?.sectorUser?.phone}{" "}
                </div>
              }
            />
          )}
        </>
      )}
      <div className="comment-title">Шийдвэрлэгдсэн үр дүн:</div>
      <p className="comment-description">{data?.result || "_"}</p>
    </CardWrapper>
  );
};

const CardWrapper = styled.div`
  padding: 16px;
  border-radius: 10px;
  box-shadow: 0px 1px 5px 0px #00000014;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 15px;

  .comment-description {
    background-color: #fafafa;
    padding: 10px 15px;
    border-radius: 5px;
    word-wrap: break-word;
  }
  .comment-title {
    font-weight: 600;
    color: #585858;
  }

  .content-text {
    padding-top: 10px;
    min-height: 70px;
    word-break: break-all;
    white-space: normal;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
      Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  }
  .content-image {
    .ant-image-img:hover,
    .ant-image-img {
      min-width: 100%;
      max-height: 400px;
      min-height: 400px;
      object-fit: cover;
      border-radius: 8px;
      border: 1px solid #fafafa;
    }
    .ant-image-mask:hover,
    .ant-image-mask:focus,
    .ant-image-mask {
      border-radius: 8px !important;
    }
  }
  .date {
    font-size: 14px;
    color: #00000073;
    font-weight: 400;
  }
`;

export default CardSolved;
