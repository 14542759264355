/* eslint-disable key-spacing */
import { Column } from "@ant-design/plots";
import { Card, DatePicker, Select, Space } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { dashboard as dashboardApi, sector as sectorApi } from "../../../apis";

const COLUMN_CONFIG = {
  xField: "status",
  yField: "total",
  isGroup: true,
  iStack: true,
  groupField: "status",
  seriesField: "status",
  legend: {
    position: "top-left",
  },
  style: {
    fontSize: "34px",
  },
  colorField: "type",
  color: ["#EA4335", "#D7C700", "#35A854", "#2b28ff", "#9623AA", "#A1887F"],
};

export const PostChart = () => {
  const [sectorId, setSectorId] = React.useState(null);
  const [chart, setChartData] = React.useState([]);
  const [filters, setFilters] = React.useState({
    filter: {
      startDate: null,
      endDate: null,
    },
    offset: {
      page: 1,
      limit: 10,
    },
  });
  const [results, setResult] = useState([]);

  const reload = React.useCallback(async () => {
    let res = await dashboardApi.postSectorChart(sectorId || "ALL", filters);
    setChartData(res.response.filter((item) => item.key !== "ALL"));
  }, [sectorId, filters]);

  React.useEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;
    reload(signal);

    return () => abortController.abort();
  }, [reload]);

  let timeout;

  const onSearch = async (query = null) => {
    if (query) {
      if (timeout) clearTimeout(timeout);

      timeout = setTimeout(async () => {
        let res = await sectorApi.select({
          query: query?.trim(),
        });

        setResult(res);
      }, 300);
    } else {
      initRes();
    }
  };

  const initRes = async () => {
    let res = await sectorApi.select({
      query: "",
    });
    setResult(res);
  };

  useEffect(() => {
    initRes();
  }, []);

  const dateFilter = (e) => {
    setFilters({
      ...filters,
      filter: {
        startDate: e
          ? moment(e[0]).startOf("days").format("YYYY-MM-DDTHH:mm")
          : null,
        endDate: e
          ? moment(e[1]).endOf("days").format("YYYY-MM-DDTHH:mm")
          : null,
      },
    });
  };

  const data = chart.filter((item) => item?.key !== "ALLRISK");

  return (
    <StyledDiv>
      <h3>Эрсдэл мэдээлэл</h3>
      <Space>
        <Select
          value={filters?.sector}
          style={{ width: "200px" }}
          onChange={(e) => setSectorId(e)}
          placeholder="Салбар сонгох"
          showSearch
          optionFilterProp="children"
          onSearch={(e) => onSearch(e)}
          defaultValue="ALL"
        >
          <Select.Option value={"ALL"} key={"all"}>
            Бүгд
          </Select.Option>
          {results?.rows?.map((item, index) => (
            <Select.Option value={`${item?._id}`} key={index}>
              {item?.fullName || "-"}
            </Select.Option>
          ))}
        </Select>
        <DatePicker.RangePicker
          value={[
            filters.filter.startDate && moment(filters.filter.startDate),
            filters.filter.endDate && moment(filters.filter.endDate),
          ]}
          onChange={dateFilter}
        />
      </Space>
      <Column
        style={{ width: "100%" }}
        {...COLUMN_CONFIG}
        data={data}
        height={400}
        forceFit={true}
      />
    </StyledDiv>
  );
};

const StyledDiv = styled(Card)`
  .ant-card-body {
    padding-bottom: 50px !important;
  }
`;
