import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { Avatar, Image, message, Upload } from "antd";
import { Field } from "formik";
import React, { useState } from "react";
import { connect } from "react-redux";
import { useDebounce } from "../../hooks";

function beforeUpload(file) {
  const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png" || file.type === "image/webp" || file.type === "image/svg+xml";
  if (!isJpgOrPng) {
    message.error("Та зөвхөн JPG/PNG файлыг байршуулах боломжтой!");
  }
  const isLt2M = file.size / 1024 < 1024 * 10;
  if (!isLt2M) {
    message.error("Зургийн хэмжээ 10 МБ-аас бага байх ёстой!");
  }
  return isJpgOrPng && isLt2M;
}

const UploadComponent = ({ name, auth, general, action, ...restProps }) => {
  const [loading, setLoading] = useState(false);
  const [base64, setBase64] = useState();
  const { token } = auth;
  const { s3host } = general;

  const handleChange = async (info, setFieldValue, setFieldTouched, setFieldError) => {
    setFieldTouched(name, true);
    let test = new FileReader();
    if (info.file.status === "uploading") {
      setLoading(true);
      setFieldValue(name, undefined);
      setBase64(undefined);
      setFieldError(name, "Зураг хуулж байна!");
    }


    if (info.file.status === "done" && info.file.response.url) {
      setTimeout(() => {
        setLoading(false);
        setFieldValue(name, info.file.response.url);
        setBase64(info.file.response.url);
      }, "3000");
    }
  };

  const uploadButton = (
    <div>
      {loading === false ? <PlusOutlined /> : <LoadingOutlined />}
      <div className='ant-upload-text'>{loading ? "Хуулж байна" : "Хуулах"}</div>
    </div>
  );

  return (
    <Field name={name}>
      {fieldProps => {
        const {
          field: { value },
          form: { setFieldValue, setFieldTouched, setFieldError }
        } = fieldProps;
        return (
          <div>
            <Upload
              name='file'
              listType='picture-card'
              className="form-upload-image"
              showUploadList={false}
              action={action}
              headers={{
                Authorization: `Bearer ${token}`,
              }}
              beforeUpload={beforeUpload}
              onChange={info => handleChange(info, setFieldValue, setFieldTouched, setFieldError)}
              {...restProps}
            >
              {base64 && base64.length > 0 || !loading && value && value.length > 0 ? <img className="image-view" src={`${s3host}${base64 || value}`} alt='' style={{ width: "100%", height: "100%", objectFit: "cover" }} srcSet="" /> : uploadButton}
            </Upload>
          </div>
        );
      }}
    </Field>
  );
};

const mapStateToProps = ({ auth, general }) => ({
  auth,
  general
});

export default connect(mapStateToProps)(UploadComponent);
