import React from "react";
import { useHistory } from "react-router-dom";
import { PageContainer, PageContent, PageHeaders } from "../../components/Layout";
import Form from "./Form";

const PostNew = () => {
  const history = useHistory();

  return (
    <PageContainer>
      <PageHeaders onBack={()=> history.push("/post")} title="Эрсдэл мэдээлэл нэмэх" />
      <PageContent>
        <Form />
      </PageContent>
    </PageContainer>
  );
};

export default PostNew;