/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable key-spacing */
import {
  DeleteOutlined,
  ExclamationCircleOutlined,
  UserOutlined,
} from "@ant-design/icons";
import {
  Avatar,
  Badge,
  Button,
  Input,
  message,
  Modal,
  Select,
  Tag,
} from "antd";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { user as userApi } from "../../../apis";
import NoImage from "../../../assets/noimage.png";
import { MyTable, RowAction } from "../../../components";
import { Filter } from "../../../components/Design";
import { datetimeFormat } from "../../../utils";
import FormSectorModal from "./../components/FormSectorModal";

const UserList = ({ myTableRef }) => {
  const history = useHistory();
  const { s3host, userStatus } = useSelector((state) => state.general);
  const [sectorModal, setSectorModal] = useState([]);
  const [query, setQuery] = useState(undefined);
  const [filters, setFilter] = useState({
    filter: {
      query: undefined,
      isActive: undefined,
      role: "USER",
      userStatus: undefined,
    },
  });

  const columns = useHeader({
    s3host,
    userStatus,
    onClick: (key, item) => {
      switch (key) {
        case "edit": {
          setSectorModal(["update", item]);
          break;
        }
        case "details": {
          history.push(`/app/${item._id}`);
          break;
        }
        case "status": {
          Modal.confirm({
            title: "Төлөв солих",
            icon: <ExclamationCircleOutlined />,
            content: (
              <>
                {item.isActive ? (
                  <span style={{ color: "red" }}>Идэвхгүй</span>
                ) : (
                  <span style={{ color: "green" }}>Идэвхтэй</span>
                )}{" "}
                болгох уу!
              </>
            ),
            onOk: async () => {
              try {
                await userApi.active(item._id);
                await myTableRef.current.reload();
              } catch (error) {
                if (error.message) {
                  message.error(error.message);
                }
              }
            },
            okText: "Тийм",
          });
          break;
        }
        case "remove": {
          Modal.confirm({
            title: "Баталгаажуулах",
            icon: <ExclamationCircleOutlined />,
            content: "Та үүнийг устгахдаа итгэлтэй байна уу!",
            onOk: async () => {
              try {
                await userApi.remove(item._id);
                await myTableRef.current.reload();
              } catch (error) {
                if (error.message) {
                  message.error(error.message);
                }
              }
            },
            okText: "Тийм",
          });
          break;
        }
        default:
      }
    },
  });

  const onClear = () => {
    setQuery(undefined);
    setFilter({
      filter: {
        query: undefined,
        isActive: undefined,
        role: "USER",
        userStatus: undefined,
      },
      offset: {
        page: 1,
        limit: 10,
      },
    });
  };

  useEffect(() => {
    let timer = setTimeout(() => {
      if (filters.filter.query !== query)
        setFilter({
          ...filters,
          filter: {
            query: query?.trim(),
            role: "USER",
          },
        });
    }, 300);

    return () => clearTimeout(timer);
  }, [query]);

  const tableReload = async () => {
    await myTableRef.current.reload();
  };

  useEffect(() => {
    tableReload();
  }, []);

  return (
    <div>
      <Filter>
        <Input
          onChange={(e) => setQuery(e.target.value)}
          value={query}
          placeholder="Хайх..."
          allowClear
        />
        <Select
          value={filters.filter.isActive}
          onChange={(e) =>
            setFilter({
              ...filters,
              filter: { ...filters.filter, isActive: e },
            })
          }
          placeholder="Идэвхтэй эсэх"
          allowClear
        >
          <Select.Option value={true} key={1}>
            <Badge color="green" /> Идэвхтэй
          </Select.Option>
          <Select.Option value={false} key={2}>
            <Badge color="red" /> Идэвхгүй
          </Select.Option>
        </Select>
        <Button onClick={onClear}>Цэвэрлэх</Button>
      </Filter>
      <MyTable
        scroll={{ x: "max-content" }}
        style={{ marginTop: "20px" }}
        ref={myTableRef}
        filters={filters}
        rowKey={(record) => record.id}
        columns={columns}
        loadData={userApi.list}
      />
      <Modal
        okText={"Хадгалах"}
        title="Салбар хуваарилах"
        open={sectorModal && sectorModal[0] === "update"}
        onCancel={() => setSectorModal([])}
        footer={false}
      >
        <FormSectorModal
          action={sectorModal}
          setAction={setSectorModal}
          myTableRef={myTableRef}
        />
      </Modal>
    </div>
  );
};

const useHeader = ({ s3host, userStatus, onClick }) => [
  {
    title: "№",
    dataIndex: "i",
    width: "20px",
  },
  {
    title: "Зураг",
    width: 40,
    render: (row) => {
      return (
        <Avatar
          icon={<UserOutlined />}
          src={row.avatar ? `${s3host}${row.avatar}` : NoImage}
          size={40}
        />
      );
    },
  },
  {
    title: "Овог",
    render: (row) => {
      return row.lastName || "-";
    },
  },
  {
    title: "Нэр",
    render: (row) => {
      return row.firstName || "-";
    },
  },
  {
    title: "Бүртгүүлсэн огноо",
    render: (row) => {
      return datetimeFormat(row.userStatusDate);
    },
  },
  {
    title: "Утас",
    render: (row) => {
      return row.phone || "-";
    },
  },
  {
    title: "Баталгаажсан эсэх",
    render: (row) => {
      return (
        <Tag
          color={
            userStatus?.find((item) => item?.code === row.userStatus)?.color
          }
        >
          {userStatus?.find((item) => item.code === row.userStatus)?.name ||
            "-"}
        </Tag>
      );
    },
  },
  {
    title: "Идэвхтэй эсэх",
    render: (row) => {
      if (row.userStatus !== "NEW") {
        if (row?.isActive) return <Tag color={"green"}>Идэвхтэй</Tag>;
        return <Tag color={"red"}>Идэвхгүй</Tag>;
      }
      return "-";
    },
  },
  {
    title: "Үйлдэл",
    width: 200,
    fixed: "right",
    align: "center",
    render: (row) => {
      if (row.userStatus === "NEW")
        return (
          <Button
            onClick={() => onClick("remove", row)}
            icon={<DeleteOutlined />}
          >
            Устгах
          </Button>
        );
      return (
        <RowAction
          actions={{
            edit: row.userStatus !== "NEW" && row.isActive && "Салбар",
            status:
              row.userStatus !== "NEW" && row?.isActive
                ? "Идэвхгүй болгох"
                : "Идэвхжүүлэх",
            remove: row.userStatus === "NEW" && "Устгах",
          }}
          onClick={(key) => onClick(key, row)}
        />
      );
    },
  },
];

export default UserList;
