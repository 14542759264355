import { SaveOutlined } from "@ant-design/icons";
import { Button, Col, Divider, message, Row, Spin } from "antd";
import { Formik } from "formik";
import { Form, FormItem, Switch } from "formik-antd";
import React from "react";
import { useSelector } from "react-redux";
import { permission as permissionApi } from "../../apis";
import { PageContainer, PageContent, PageHeaders } from "../../components/Layout";
import useFetch from "../../hooks/useFetch";

export default function Permission() {
  const [permission, loading] = useFetch(permissionApi.get)([]);
  const { rolePermission, isMobile } = useSelector(state => state.general);

  const onSubmit = async (values) => {

    try {
      await permissionApi.save(values);
      message.success("Амжилттай хадгалагдлаа");
    } catch (error) {
      if (error.message)
        message.error(error.message);
      else
      if (error)
        message.error("Хадгалахад алдаа гарлаа!");
    }
  };

  return (
    <Spin spinning={loading}>
      <Formik
        enableReinitialize
        initialValues={permission}
        onSubmit={onSubmit}>
        {({ isSubmitting }) => {
          return (
            <Form layout="vertical">
              <PageContainer>
                <PageHeaders title="Хандах эрх" extra={<Button icon={isMobile && <SaveOutlined/>} htmlType="submit" type="primary" loading={isSubmitting}>{!isMobile && "Хадгалах"}</Button>} />
                <PageContent>
                  <div style={{ maxWidth: "1100px", overflow: "auto" }}>
                    <Row style={{ minWidth: "1100px" }}>
                      <Col span={6}>Нэр</Col>
                      { rolePermission.map((item, index) => {
                        return <Col key={index} span={6} style={{ display: "flex", justifyContent: "center" }}><div style={{ whiteSpace: "nowrap", padding: "0 4px" }}>{item.name}</div></Col>;
                      }) }
                    </Row>
                    <Divider/>
                    {permission?.map((item, index)=>
                      <Row key={index} style={{ minWidth: "1100px" }}>
                        <Col span={6}>{item?.name}</Col>
                        <Col span={6} style={{ display: "flex", justifyContent: "center" }}>
                          <FormItem name={`[${index}].admin`}>
                            <Switch name={`[${index}].admin`} />
                          </FormItem>
                        </Col>
                        <Col span={6} style={{ display: "flex", justifyContent: "center" }}>
                          <FormItem name={`[${index}].post`}>
                            <Switch name={`[${index}].post`} />
                          </FormItem>
                        </Col>
                        <Col span={6} style={{ display: "flex", justifyContent: "center" }}>
                          <FormItem name={`[${index}].staff`}>
                            <Switch name={`[${index}].staff`} />
                          </FormItem>
                        </Col>
                      </Row>
                    )}
                  </div>
                </PageContent>
              </PageContainer>
            </Form>
          );
        }}
      </Formik>
    </Spin>
  );
};
