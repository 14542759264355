/* eslint-disable key-spacing */
import { Spin } from "antd";
import React, { useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { notice as noticeApi } from "../../apis";
import {
  PageContainer,
  PageContent,
  PageHeaders,
} from "../../components/Layout";
import Form from "./Form";

const NoticeEdit = () => {
  const params = useParams();
  const [action, setAction] = React.useState([]);
  const history = useHistory();
  const [load, setLoad] = useState(true);

  const reload = React.useCallback(
    async (signal) => {
      setLoad(false);
      let res = await noticeApi.get(params.id, null, { signal });
      setAction(["update", res]);
      setLoad(true);
    },
    [params.id]
  );

  React.useEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;

    reload(signal);

    return () => abortController.abort();
  }, [reload]);

  return (
    <PageContainer>
      <PageHeaders
        onBack={() => history.push("/notice")}
        title="Нийтлэл засах"
      />
      <PageContent
        style={{ maxWidth: "1500px", margin: "auto", paddingBottom: "100px" }}
      >
        {
          <Spin spinning={!load}>
            {action[1] && load && <Form action={action} />}
          </Spin>
        }
      </PageContent>
    </PageContainer>
  );
};
export default NoticeEdit;
