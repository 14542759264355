/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable indent */
/* eslint-disable key-spacing */
import { message, Table } from "antd";
import React from "react";
import styled from "styled-components";

const MyTable = React.forwardRef(
  (
    {
      loadData,
      columns,
      pagination = true,
      limit: initialLimit = 10,
      filters,
      order,
      items: rows,
      onChange: onChangeData,
      onTableChange = () => {},
      ...rest
    },
    ref
  ) => {
    const [loading, setLoading] = React.useState(false);
    const [items, setItems] = React.useState([]);
    const [total, setTotal] = React.useState(0);

    const [field, setField] = React.useState();
    const [sort, setSort] = React.useState();
    const [page, setPage] = React.useState(1);
    const [limit, setLimit] = React.useState(initialLimit);

    const onChange = (pagination, filterData, sorter) => {
      setPage(pagination.current);
      setLimit(pagination.pageSize);
      onTableChange(filterData);

      if (sorter && Object.keys(sorter).length && sorter.order) {
        setField(sorter.columnKey);
        setSort(sorter.order === "ascend" ? 1 : -1);
      } else {
        setField(field);
        setSort(sort);
      }
    };

    const reload = React.useCallback(
      async (signal) => {
        if (!loadData) return;
        setLoading(true);

        const res = await loadData(
          {
            filter: {
              query: "",
              user_id: undefined,
            },
            ...filters,
            offset: {
              page: page,
              limit: limit,
            },
          },
          { signal }
        );
        if (res.type === "error")
          return message.error(res.payload || "Түр саатал гарсан!");

        if (res.users) {
          setItems(
            res?.users?.map((row, index) => ({
              ...row,
              i: (page - 1 || 0) * limit + index + 1,
            }))
          );
          setTotal(res.users.length);
        } else if (res.rows) {
          setItems(
            res?.rows?.map((row, index) => ({
              ...row,
              i: (page - 1 || 0) * limit + index + 1,
            }))
          );
          setTotal(res.count);
        } else if (res) {
          setItems(
            res?.map((row, index) => ({
              ...row,
              i: (page - 1 || 0) * limit + index + 1,
            }))
          );
          setTotal(res.length);
        }

        if (onChangeData) onChangeData(res);

        setLoading(false);
      },
      [filters, limit, loadData, field, sort, page]
    );

    React.useEffect(() => {
      const abortController = new AbortController();
      const signal = abortController.signal;

      reload(signal);

      return () => abortController.abort();
    }, [reload]);

    React.useImperativeHandle(ref, () => ({
      reload() {
        reload();
      },
      setItems(items) {
        setItems(items);
      },
    }));

    React.useEffect(() => {
      setPage(1);
    }, [filters]);

    React.useEffect(() => {
      if (rows) {
        setItems(rows);
        setTotal(rows.length);
      }
    }, [rows]);

    return (
      <Container>
        <Table
          {...{
            scroll: { x: 400 },
            rowClassName: "row",
            className: "table",
            // size        : "small",
            columns,
            dataSource: items,
            pagination: pagination
              ? {
                  className: "pagination",
                  defaultCurrent: 1,
                  showTitle: true,
                  showSizeChanger: false,
                  showQuickJumper: false,
                  // showTotal      : (total, range) => (
                  //   <span>
                  //   Нийт <strong>{total}</strong>-c <strong>{range[0]}</strong>-<strong>{range[1]}</strong>
                  //   -г харуулж байна
                  //   </span>
                  // ),
                  total: total,
                  pageSize: limit,
                  current: page,
                }
              : false,
            onChange,
            ...rest,
          }}
          loading={loading}
          rowKey={(record) => record._id}
        />
      </Container>
    );
  }
);

const Container = styled.div`
  thead {
    background: #fff;

    &.ant-table-thead {
      tr {
        th {
          white-space: nowrap;
        }
      }
    }
  }
  .row {
    &:hover {
      cursor: pointer;
    }
  }
`;

export default MyTable;
