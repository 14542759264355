/* eslint-disable key-spacing */
import { Button, message, Row } from "antd";
import { Formik } from "formik";
import { Form, FormItem, Input } from "formik-antd";
import React from "react";
import * as Yup from "yup";
import { staff as staffApi } from "../../../apis";

const FormSchema = () =>
  Yup.object().shape({
    password: Yup.string()
      .min(6, "Нууц үг 6-с их орон байх хэрэгтэй!")
      .max(20, "Нууц үг 20-с бага орон байх хэрэгтэй!")
      .min(8, "Нууц үг 8-с их орон байх хэрэгтэй!")
      .required("Заавал бөглөнө!"),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), null], "Нууц үг таарахгүй байна!")
      .required("Заавал бөглөнө!"),
  });

const FormPassword = ({ passwordChange, setPasswordChange }) => {
  const [data] = React.useState({
    password: undefined,
    confirmPassword: undefined,
  });

  const onSubmit = async (values, action) => {
    try {
      await staffApi.update(passwordChange[1]?._id, {
        ...passwordChange[1],
        ...values,
      });
      setPasswordChange([]);
      action.resetForm();
      message.success("Нууц үг амжилттай солигдлоо.");
    } catch (error) {
      if (error.message) message.error(error.message);
      action.resetForm();
    }
  };

  return (
    <div>
      <Formik
        enableReinitialize
        initialValues={data}
        validationSchema={FormSchema}
        onSubmit={onSubmit}
      >
        {({ isSubmitting }) => (
          <div>
            <Form layout="vertical">
              <FormItem name="password" label="Шинэ нууц үг" required>
                <Input.Password
                  prefix={<span />}
                  name="password"
                  placeholder="Шинэ нууц үг"
                />
              </FormItem>
              <FormItem name="confirmPassword" label="Нууц үг давтах" required>
                <Input.Password
                  prefix={<span />}
                  name="confirmPassword"
                  placeholder="Нууц үг давтах"
                />
              </FormItem>
              <Row justify="end" style={{ marginTop: "40px" }}>
                <Button
                  className="button"
                  style={{ marginRight: 10 }}
                  htmlType="submit"
                  type="primary"
                  loading={isSubmitting}
                >
                  Хадгалах
                </Button>
                <Button
                  className="button"
                  type="default"
                  onClick={() => setPasswordChange([])}
                >
                  Болих
                </Button>
              </Row>
            </Form>
          </div>
        )}
      </Formik>
    </div>
  );
};

export default FormPassword;
