/* eslint-disable key-spacing */
import React, { useEffect, useState } from "react";
import { Card, Col, Divider, Row } from "antd";
import styled from "styled-components";
import { dashboard as dashboardApi, sector as sectorApi } from "../../../apis";
import moment from "moment";
import YellowAlert from "../../../assets/yellow_alert.svg";
import CorrectIcon from "../../../assets/correct-icon.svg";
import RedTime from "../../../assets/red-time.svg";
import User from "../../../assets/user.svg";
import { Image } from "@mantine/core";

export const TestChart = () => {
  const [filters, setFilters] = React.useState({
    filter: {
      startDate: null,
      endDate: null,
      lastDate: null,
    },
    offset: {
      page: 1,
      limit: 10,
    },
  });
  const [results, setResult] = useState([]);
  const [chart, setChartData] = React.useState([]);
  const [sectorId, setSectorId] = React.useState(null);
  const reload = React.useCallback(async () => {
    let res = await dashboardApi.TestChart(sectorId || "ALL", filters);
    setChartData(res);
  }, [sectorId, filters]);
  React.useEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;
    reload(signal);
    return () => abortController.abort();
  }, [reload]);
  let timeout;
  const onSearch = async (query = null) => {
    if (query) {
      if (timeout) clearTimeout(timeout);

      timeout = setTimeout(async () => {
        let res = await sectorApi.select({
          query: query?.trim(),
        });

        setResult(res);
      }, 300);
    } else {
      initRes();
    }
  };

  const initRes = async () => {
    let res = await sectorApi.select({
      query: "",
    });
    setResult(res);
  };

  const dateFilter = (e) => {
    setFilters({
      ...filters,
      filter: {
        startDate: e && moment(e[0]).startOf("days").format("YYYY-MM-DDTHH:mm"),
        endDate: e && moment(e[1]).endOf("days").format("YYYY-MM-DDTHH:mm"),
        lastDate: e && moment().subtract(10, "days").format("YYYY-MM-DDTHH:mm"),
      },
    });
  };

  useEffect(() => {
    initRes();
  }, []);

  const icons = [
    <Image alt="" src={YellowAlert} />,
    <Image alt="" src={CorrectIcon} />,
    <Image alt="" src={RedTime} />,
    <Image alt="" src={User} />,
  ];

  return (
    <>
      <h3>Ерөнхий мэдээлэл</h3>
      <Row gutter={16}>
        {chart.map((item, index) => {
          return (
            <Col key={index}>
              <StyledDiv className="card" bordered={true}>
                <Row>
                  <Col flex={2} className="tht">
                    <div className="icon" style={{ color: item.color }}>
                      {icons[index]}
                    </div>
                  </Col>
                  <Col flex={3} className="hth">
                    <div>
                      <div className="field">{item.field}</div>
                      <div className="manlai">
                        <div className="total">
                          {item.key === "count1" ? (
                            <>{item.total}%</>
                          ) : (
                            <>{item.total}</>
                          )}
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </StyledDiv>
            </Col>
          );
        })}
      </Row>
    </>
  );
};
const StyledDiv = styled(Card)`
  max-height: 100px;
  min-height: 120px;
  min-width: 100px;
  max-width: 300px;
  width: 300px;
  margin-top: 10px;
  .total {
    background: #bbdefb;
    color: #1976d2;
    border-radius: 10px;
    width: 180px;
    display: flex;
    justify-content: center;
  }
  .hth {
    margin-left: 30px;
  }
  .tht {
    display: flex;
    justify-content: space-between;
  }
  .icon {
    font-size: 28px;
  }
  .manlai {
    display: flex;
    justify-content: space-between;
    color: black;
    margin-top: 20px;
  }
  .field {
    background: #d9d9d9;
    color: #525252;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    margin-top: 10px;
  }
`;
