import {
  CameraOutlined,
  ExclamationCircleOutlined,
  LockFilled,
  SendOutlined,
  UnlockOutlined,
  UserOutlined,
} from "@ant-design/icons";
import {
  Avatar,
  Button,
  Card,
  Divider,
  message,
  Modal,
  Result,
  Row,
  Skeleton,
} from "antd";
import { Checkbox, FormItem, Select } from "formik-antd";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import {
  post as postApi,
  sector as sectorApi,
  user as userApi,
} from "../../../apis";

const { Meta } = Card;

const CardRejected = ({ data, setFieldValue, isSubmitting, reloadData }) => {
  const { s3host, isMobile } = useSelector((state) => state.general);
  const [results, setResult] = useState([]);
  const [users, setUsers] = useState([]);
  const [sectorUserData, setSectorUserData] = useState({});
  const [sectorData, setSectorData] = useState();
  const params = useParams();

  const initRes = async () => {
    let res = await sectorApi.select({
      query: "",
    });
    setResult(res);
  };

  const initUsers = async (query = "", sectorId = null) => {
    let timeout;
    if (timeout) clearTimeout(timeout);

    timeout = setTimeout(async () => {
      let res = await userApi.list({
        offset: {
          // eslint-disable-next-line key-spacing
          page: 1,
          limit: 10,
        },
        filter: {
          // eslint-disable-next-line key-spacing
          query: query.trim() || "",
          isActive: undefined,
          // eslint-disable-next-line key-spacing
          role: "SECTOR",
          // eslint-disable-next-line key-spacing
          sector: sectorId,
        },
      });
      setUsers(res);
    }, 300);
  };

  useEffect(() => {
    initUsers();
    initRes();
    getUser(data.sectorUserId);
    getSector(data.sectorId);
  }, []);

  const getSector = async (e) => {
    let res = await sectorApi.get(e);
    setSectorData(res.sector);
  };

  const getUser = async (e) => {
    if (e) {
      let res = await userApi.get(e);
      setSectorUserData(res);
    } else setSectorUserData();
  };

  const isActivePost = async (value) => {
    Modal.confirm({
      // eslint-disable-next-line key-spacing
      title: "Баталгаажуулах",
      // eslint-disable-next-line key-spacing
      icon: <ExclamationCircleOutlined />,
      // eslint-disable-next-line key-spacing
      okText: "Тийм",
      content: `Та үүнийг ${!value ? "ИДЭВХГҮЙ" : "ИДЭВХТЭЙ"} болгох уу!`,
      // eslint-disable-next-line key-spacing
      onOk: async () => {
        try {
          await postApi.hide(params.id, {
            isActive: value,
          });
          reloadData();
          message.success("Төлөв амжилттай солигдлоо.");
        } catch (error) {
          if (error.message) {
            message.error(error.message);
          }
        }
      },
    });
  };

  useEffect(() => {
    initRes();
    initUsers("", data.sectorId);
    getUser("");
  }, [data.sectorId]);

  return (
    <div>
      <CardWrapper>
        {!data.isActive ? (
          <Result
            icon={
              <LockFilled
                height={"100px"}
                width={"100px"}
                style={{ color: "red" }}
              />
            }
            title={"Эрсдэлийг идэвхжүүлэх үү ?"}
            extra={
              <Button
                onClick={() => isActivePost(true)}
                type="primary"
                icon={<UnlockOutlined />}
              >
                {!isMobile && "Тийм"}
              </Button>
            }
          />
        ) : (
          <>
            <FormItem
              name="sectorId"
              label="Хуваарилах салбар сонгоно уу"
              required
            >
              <Select
                className="sector-select-input"
                name="sectorId"
                placeholder="Салбар сонгох"
                showSearch
                optionFilterProp="children"
                onChange={(e) => {
                  getSector(e);
                  setFieldValue("sectorUserId", undefined);
                }}
              >
                {results &&
                  results?.rows?.map((item, index) => (
                    <Select.Option value={`${item?._id}`} key={index}>
                      {item.fullName}
                    </Select.Option>
                  ))}
              </Select>
            </FormItem>
            <div className="meta-header">
              {data.sectorId && sectorData && (
                <Meta
                  avatar={
                    data.sectorId &&
                    sectorData?.avatar && (
                      <Avatar
                        size={55}
                        src={`${s3host}/${sectorData?.avatar}`}
                        icon={<CameraOutlined />}
                      />
                    )
                  }
                  title={
                    data.sectorId && sectorData?.fullName ? (
                      sectorData?.fullName
                    ) : (
                      <Skeleton.Input size="small" block />
                    )
                  }
                  description={
                    data.sectorId && sectorData.email ? (
                      <>{`${sectorData?.email} / ${sectorData?.phone}`}</>
                    ) : (
                      <>
                        {" "}
                        <Skeleton.Input width={100} height={10} size="small" />
                      </>
                    )
                  }
                />
              )}
            </div>
            <Divider />
            <FormItem name="selectSectorUser">
              <Checkbox
                name="selectSectorUser"
                onChange={(e) => {
                  setFieldValue("sectorUserId", "");
                  setSectorUserData();
                }}
              >
                Салбарын ажилтан сонгох эсэх ?
              </Checkbox>
            </FormItem>
            {data.selectSectorUser && (
              <>
                <FormItem
                  name="sectorUserId"
                  label="Салбар нэгжийн ажилтан"
                  required
                >
                  <Select
                    disabled={!data.sectorId}
                    className="sector-select-input"
                    name="sectorUserId"
                    placeholder="Салбар нэгжийн ажилтан"
                    showSearch
                    optionFilterProp="children"
                    onChange={(e) => {
                      getUser(e, data.sectorId);
                    }}
                    onSearch={(e) => {
                      initUsers(e, data.sectorId);
                    }}
                  >
                    {users &&
                      users?.rows?.map((item, index) => (
                        <Select.Option value={`${item?._id}`} key={index}>
                          {item?.lastName} {item?.firstName || "-"}
                        </Select.Option>
                      ))}
                  </Select>
                </FormItem>
                <div className="meta-header">
                  {sectorUserData && (
                    <Meta
                      avatar={
                        <Avatar
                          size={55}
                          src={`${s3host}${sectorUserData?.avatar}`}
                          icon={<UserOutlined />}
                        />
                      }
                      title={
                        sectorUserData?.firstName || (
                          <Skeleton.Input size="small" block />
                        )
                      }
                      description={
                        sectorUserData && sectorUserData?.phone ? (
                          <>{`${sectorUserData?.phone} / Цуцласан ажилтан`}</>
                        ) : (
                          <>
                            {" "}
                            <Skeleton.Input
                              width={100}
                              height={10}
                              size="small"
                            />
                          </>
                        )
                      }
                    />
                  )}
                </div>
              </>
            )}
            <Divider />
            <Row justify="end">
              {/* <div>
                <div style={{ marginRight: 10 }}>
                  {
                    data.isActive &&
                      <Button onClick={()=>isActivePost(false)} type="danger" icon={<LockFilled />} >{!isMobile && "Идэвхгүй болгох"}</Button>
                  }
                </div>
              </div> */}
              <Button htmlType="submit" type="primary" loading={isSubmitting}>
                {isMobile ? (
                  <>
                    <SendOutlined />
                  </>
                ) : (
                  <>Илгээх</>
                )}
              </Button>
            </Row>
          </>
        )}
      </CardWrapper>
    </div>
  );
};

const CardWrapper = styled.div`
  padding: 16px;
  border-radius: 10px;
  box-shadow: 0px 1px 5px 0px #00000014;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 15px;
  min-width: auto !important;

  .meta-header {
    height: 70px;
  }
  .date {
    font-size: 14px;
    color: #00000073;
    font-weight: 400;
  }
`;

export default CardRejected;
