// import { Status, Wrapper } from "@googlemaps/react-wrapper";
import { UserAddOutlined } from "@ant-design/icons";
import { Marker, MarkerClusterer } from "@react-google-maps/api";
import React, { useState } from "react";
import { default as NewLocate } from "../../../assets/status-svg/newlocate.svg";
import GoogleMapComponent from "../../../components/googleMap";



const GetPosition = ({ setFieldValue, values }) => {
  const [center, setCenter] = useState({
    lat: values.location.lat || 49.48717522016001,
    lng: values.location.lng || 105.95057331174262,
  });

  return (
    <div>
      <GoogleMapComponent
        height={"450px"}
        zoom={15}
        center={center}
        onClick={(map) => {
          setFieldValue("location", { lat: map.latLng.lat(), lng: map.latLng.lng() });
          setCenter({ lat: map.latLng.lat(), lng: map.latLng.lng() });
        }}>
        <MarkerClusterer
          options={{
            imagePath: "https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m", // so you must have m1.png, m2.png, m3.png, m4.png, m5.png and m6.png in that folder
          }}
        >
          {(clusterer) =>
            <Marker
              controls={true}
              clusterer={clusterer}
              icon={{
                url       : NewLocate,
                scaledSize: {
                  width : 40,
                  height: 40,
                },
                icon: <UserAddOutlined/>
              }}

              position={center}
            />
          }
        </MarkerClusterer>
      </GoogleMapComponent>
    </div>
  );
};

export default GetPosition;