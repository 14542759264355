/* eslint-disable key-spacing */
import {
  CameraOutlined,
  DownloadOutlined,
  ExclamationCircleOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import {
  Avatar,
  Badge,
  Button,
  DatePicker,
  Input,
  message,
  Modal,
  notification,
  Select,
  Space,
  Tabs,
  Tag,
} from "antd";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  general,
  post as postApi,
  sector as sectorApi,
  notice as noticeApi,
} from "../../apis";
import { MyTable, RowAction } from "../../components";
import { Filter } from "../../components/Design";
import {
  PageContainer,
  PageContent,
  PageHeaders,
} from "../../components/Layout";
import { datetimeFormat } from "../../utils";
import GoogleMap from "../GoogleMap";
import ReportList from "./components/ReportList";

const { TabPane } = Tabs;

const PostList = () => {
  const {
    s3host,
    postStatus,
    isMobile,
    postStatusType,
    postTabKey = 1,
    reportedPosts,
  } = useSelector((state) => state.general);
  const myTableRef = useRef();
  const dispatch = useDispatch();
  const history = useHistory();

  const [query, setQuery] = useState("");
  const [filters, setFilters] = useState({
    filter: {
      startDate: undefined,
      endDate: undefined,
      query: null,
      postStatus: postStatusType || "",
      userStatus: null,
      userType: null,
      sector: null,
      sectorUser: undefined,
      download: false,
    },
    offset: {
      page: 1,
      limit: 10,
    },
  });

  const [sectorUsersList, setSectorUsersList] = useState([]);

  const [results, setResult] = useState([]);

  const columns = useHeader({
    s3host,
    postStatus,
    onAction: async (key, post) => {
      switch (key) {
        case "edit": {
          history.push(`/post/${post._id}`);
          break;
        }

        default:
      }
    },
    onClick: async (key, post) => {
      switch (key) {
        case "edit": {
          history.push(`/post/${post._id}`);
          break;
        }
        case "remove": {
          Modal.confirm({
            // eslint-disable-next-line key-spacing
            title: "Баталгаажуулах",
            icon: <ExclamationCircleOutlined />,
            content: "Та үүнийг устгахдаа итгэлтэй байна уу!",
            okText: "Устгах",
            cancelText: "Болих",
            className: "btn-custom-class",
            onOk: async () => {
              try {
                await noticeApi.remove(post._id);
                myTableRef.current.reload();
              } catch (error) {
                if (error.message) {
                  message.error(error.message);
                }
              }
            },
          });
          break;
        }
      }
    },
  });

  const onClear = () => {
    dispatch({
      type: "general.postStatusType",
      payload: undefined,
    });
    setQuery("");
    setFilters({
      filter: {
        startDate: undefined,
        endDate: undefined,
        query: null,
        postStatus: null,
        userStatus: null,
        userType: null,
        sector: null,
        sectorUser: undefined,
        download: false,
      },
      offset: {
        page: 1,
        limit: 10,
      },
    });
  };

  useEffect(() => {
    let timeout;
    if (timeout) clearTimeout(timeout);
    if (filters.filter.query !== query)
      timeout = setTimeout(() => {
        setFilters({
          ...filters,
          filter: { ...filters.filter, query: query?.trim() },
        });
      }, 300);

    return () => {
      if (timeout) clearTimeout(timeout);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  const onSearch = async (query = null) => {
    let timeout;
    if (query) {
      if (timeout) clearTimeout(timeout);

      timeout = setTimeout(async () => {
        let res = await sectorApi.select({
          query: query?.trim(),
        });

        setResult(res);
      }, 300);
    } else {
      initRes();
    }
  };

  const initRes = async () => {
    let res = await sectorApi.select({
      query: "",
    });
    setResult(res);
  };

  const notifyInit = async () => {
    await Promise.all([general.init()(dispatch)]);
  };

  useEffect(() => {
    initRes();
    notifyInit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const dateFilter = (e) => {
    setFilters({
      ...filters,
      filter: {
        ...filters.filter,
        startDate: e && moment(e[0]).startOf("days").format("YYYY-MM-DDTHH:mm"),
        endDate: e && moment(e[1]).endOf("days").format("YYYY-MM-DDTHH:mm"),
      },
    });
  };

  const sectorUsersGet = async (id, query) => {
    try {
      if (id) {
        const res = await sectorApi.sectorUsers(id, query || "");
        setSectorUsersList(res);
      } else setSectorUsersList([]);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    setFilters({
      ...filters,
      filter: {
        ...filters.filter,
        postStatus: postStatusType,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [postStatusType]);

  const download = async () => {
    try {
      const res = await postApi.list({
        ...filters,
        filter: {
          ...filters.filter,
          download: true,
        },
      });
      if (res?.url) {
        window.open(`${s3host}/files${res?.url}`);
      }
    } catch (error) {
      notification.error({
        title: error.message || "Хүсэлт амжилтгүй боллоо!",
      });
    }
  };

  return (
    <PageContainer>
      <PageHeaders
        title="Эрсдэлийн мэдээлэл"
        extra={
          <Space>
            <Button
              key={0}
              type="primary"
              ghost
              icon={isMobile && <DownloadOutlined />}
              onClick={() => {
                download();
              }}
            >
              {!isMobile && "Файл татах"}
            </Button>
            <Button
              key={0}
              type="primary"
              icon={isMobile && <PlusOutlined />}
              onClick={() => history.push("/post/new")}
            >
              {!isMobile && "Эрсдэл мэдээлэл"}
            </Button>
          </Space>
        }
      />
      <PageContent>
        <Tabs
          activeKey={postTabKey || "1"}
          onChange={(e) => {
            dispatch({
              type: "general.postTabKey",
              payload: e,
            });
          }}
        >
          <TabPane tab="Жагсаалт" key="1">
            <>
              <Filter>
                <Input
                  value={query}
                  onChange={(e) => setQuery(e.target.value)}
                  placeholder="Хайх..."
                  allowClear
                />
                <Select
                  value={
                    filters?.filter?.postStatus || postStatusType || undefined
                  }
                  onChange={(e) => {
                    dispatch({
                      type: "general.postStatusType",
                      payload: e,
                    });
                    setFilters({
                      ...filters,
                      filter: { ...filters.filter, postStatus: e },
                    });
                  }}
                  placeholder="Төлөвөөр хайх"
                  allowClear
                >
                  {postStatus?.map((item, index) => {
                    return (
                      <Select.Option value={item.code} key={index}>
                        <Badge color={item.color || "#939393"} /> {item.name}
                      </Select.Option>
                    );
                  })}
                </Select>
                <Select
                  value={filters?.filter.isActive}
                  onChange={(e) =>
                    setFilters({
                      ...filters,
                      filter: { ...filters.filter, isActive: e },
                    })
                  }
                  placeholder="Идэвхтэй эсэх"
                  allowClear
                >
                  <Select.Option value={true} key={"true"}>
                    <Badge color={"green"} /> Идэвхтэй
                  </Select.Option>
                  <Select.Option value={false} key={"false"}>
                    <Badge color={"red"} /> Идэвхгүй
                  </Select.Option>
                </Select>
                <Select
                  value={filters?.filter?.sector}
                  style={{ width: "200px" }}
                  onChange={(e) => {
                    sectorUsersGet(e, "");
                    setFilters({
                      ...filters,
                      filter: { ...filters.filter, sector: e },
                    });
                  }}
                  placeholder="Салбар"
                  optionFilterProp="children"
                  allowClear
                  onSearch={(e) => onSearch(e.trim())}
                >
                  {results?.rows?.map((item, index) => (
                    <Select.Option value={`${item?._id}`} key={index}>
                      {item?.fullName || "-"}
                    </Select.Option>
                  ))}
                </Select>
                {filters?.filter?.sector && (
                  <Select
                    value={filters?.filter?.sectorUser}
                    style={{ width: "200px" }}
                    onChange={(e) => {
                      setFilters({
                        ...filters,
                        filter: { ...filters.filter, sectorUser: e },
                      });
                    }}
                    placeholder="Салбар ажилтан"
                    showSearch
                    optionFilterProp="children"
                    allowClear
                    onClear={() =>
                      sectorUsersGet(filters.filter.sector || "", "")
                    }
                    onSearch={(e) =>
                      sectorUsersGet(filters.filter.sector || "", e)
                    }
                  >
                    {sectorUsersList?.rows?.map((item, index) => (
                      <Select.Option value={`${item?._id}`} key={index}>
                        {item?.lastName} {item?.firstName || "-"}
                      </Select.Option>
                    ))}
                  </Select>
                )}
                <DatePicker.RangePicker
                  value={[
                    filters?.filter?.startDate &&
                      moment(filters?.filter?.startDate),
                    filters?.filter?.endDate &&
                      moment(filters?.filter?.endDate),
                  ]}
                  onChange={dateFilter}
                />
                <Button onClick={onClear}>Цэвэрлэх</Button>
              </Filter>
              <MyTable
                scroll={{ x: "max-content" }}
                style={{ marginTop: "20px" }}
                columns={columns}
                ref={myTableRef}
                filters={filters}
                loadData={postApi.list}
                rowKey={(record) => record._id}
              />
            </>
          </TabPane>
          <TabPane tab="Байршил" key="2">
            <GoogleMap />
          </TabPane>
          <TabPane
            tab={
              <div>
                Админд мэдэгдсэн эрсдэл{" "}
                <Badge
                  style={{ marginLeft: 3 }}
                  count={reportedPosts}
                  showZero
                />
              </div>
            }
            key="3"
          >
            <ReportList />
          </TabPane>
        </Tabs>
      </PageContent>
    </PageContainer>
  );
};

const useHeader = ({ onAction, s3host, postStatus, onClick }) => {
  return [
    {
      title: "№",
      dataIndex: "i",
      width: "20px",
    },
    {
      title: "Код",
      render: (record) => {
        return <b>{record?.code || "-"}</b>;
      },
    },
    {
      title: "Зураг",
      render: (record) => {
        return (
          <>
            {record?.image ? (
              <Avatar
                icon={<CameraOutlined />}
                src={`${s3host}${record?.image}`}
                size={40}
                shape="square"
              />
            ) : (
              "-"
            )}
          </>
        );
      },
    },
    {
      title: "Эрсдэл мэдээлэл",
      render: (record) => {
        if (record?.text?.length > 25) {
          return <>{record.text.substring(0, 24)} ...</>;
        }
        return record.text || "-";
      },
    },
    {
      title: "Салбар",
      render: (record) => {
        return (
          <div style={{ whiteSpace: "nowrap" }}>
            {record?.sector?.fullName || "-"}
          </div>
        );
      },
    },
    {
      title: "Таалагдсан",
      render: (record) => {
        return <Tag>{record.likeCount || 0}</Tag>;
      },
    },
    {
      title: "Төлөв",
      render: (record) => {
        return (
          <Tag
            color={
              postStatus?.find((item) => item.code === record.postStatus)?.color
            }
          >
            {postStatus.find((item) => item.code === record.postStatus)?.name}
          </Tag>
        );
      },
    },
    {
      title: "Тайлбар",
      render: (record) => {
        if (record?.isRefused)
          return (
            <>
              {record?.refuse ? record.refuse.substring(0, 24) + " ..." : "-"}{" "}
            </>
          );
        return (
          <>{record?.result ? record.result.substring(0, 24) + " ..." : "-"} </>
        );
      },
    },
    {
      title: "Төлөв өөрчлөгдсөн огноо",
      render: (record) => {
        if (record.repliedDate)
          return record.resultDate
            ? datetimeFormat(record.resultDate)
            : datetimeFormat(record.repliedDate);
        return "-";
      },
    },
    {
      title: "Үүссэн огноо",
      render: (record) => {
        return record.postStatusDate
          ? datetimeFormat(record.postStatusDate)
          : "-";
      },
    },
    {
      title: "Идэвхтэй эсэх",
      render: (record) => {
        if (record.isActive) return <Tag color="green">Идэвхтэй</Tag>;
        return <Tag>Идэвхгүй</Tag>;
      },
    },
    {
      title: "Буцаагдсан эсэх",
      render: (record) => {
        return !record?.isRefused ? (
          <Tag>Үгүй</Tag>
        ) : (
          <Tag color="blue">Тийм</Tag>
        );
      },
    },
    {
      title: "Үйлдэл",
      width: 100,
      fixed: "right",
      align: "center",
      render: (record) => {
        return record.postStatus === "FAILED" ? (
          <RowAction
            actions={{
              edit: "Дэлгэрэнгүй",
              remove: "Устгах",
            }}
            onClick={(key) => onClick(key, record)}
          />
        ) : (
          <Button type="link" onClick={() => onAction("edit", record)}>
            Дэлгэрэнгүй{" "}
          </Button>
        );
      },
    },
  ];
};

export default PostList;
