import { Button, message, Row } from "antd";
import { Formik } from "formik";
import { Form, FormItem, Input, InputNumber } from "formik-antd";
import React from "react";
import { useHistory } from "react-router";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import * as Yup from "yup";
import { faq as faqApi } from "../../apis";
import { StyledForm } from "../../components/Design";

const { TextArea } = Input;

const FormSchema = () => Yup.object().shape({
  title: Yup.string()
    .trim()
    .required("Заавал бөглөнө!").
    min(5, "Хэт богино байна!")
    .max(250, "Хэт урт байна!"),
  body: Yup.string()
    .trim()
    .min(5, "Хэт богино байна!")
    .required("Заавал бөглөнө!")
    .max(500, "Хэт урт байна!"),
  sort: Yup.string().typeError("Заавал бөглөнө!").required("Заавал бөглөнө!").trim()
});

const FormStaff = ({ action }) => {
  const history = useHistory();
  const params = useParams();

  const [data] = React.useState({
    title: undefined,
    body : undefined,
    sort : undefined,
    ...(action && action[0] === "update" ? action[1]: {}),
  });

  const onSubmit = async (values) => {
    if (action && action[0] === "update") {
      try {
        await faqApi.update(params.id, values);
        history.push("/faq");
        message.success("Таны хүсэлт амжилттай!");
      } catch (error) {
        if (error.message)
          message.error(error.message);
      }
    } else {
      try {
        await faqApi.create(values);
        history.push("/faq");
        message.success("Таны хүсэлт амжилттай!");
      }
      catch (error) {
        if (error.message)
          message.error(error.message);
      }
    }
  };

  return (
    <Container>
      <Formik
        enableReinitialize
        initialValues={data}
        validationSchema={FormSchema}
        onSubmit={onSubmit}>
        {({ isSubmitting }) => (
          <StyledForm>
            <Form layout="vertical">
              <FormItem name="title" label="Түгээмэл асуулт" required>
                <Input prefix={<span/>} name="title" placeholder="Түгээмэл асуулт" />
              </FormItem>
              <FormItem name="body" label="Хариулт" required>
                <TextArea showCount maxLength={500} name="body" placeholder="Хариулт" rows={6} />
              </FormItem>
              <FormItem name="sort" label="Эрэмбэ" required>
                <InputNumber type="number" style={{ width: "100%" }} min={0} prefix={<span/>} name="sort" placeholder="Эрэмбэ" />
              </FormItem>
              <Row justify="end" style={{ marginTop: "40px" }}>
                <Button className="button" style={{ marginRight: 10 }} htmlType="submit" type="primary" loading={isSubmitting}>Хадгалах</Button>
                <Button className="button" type="default" onClick={() => history.push("/faq")}>Болих</Button>
              </Row>
            </Form>
          </StyledForm>
        )}
      </Formik>
    </Container>
  );
};

const Container = styled.div`
  margin-top: 20px;
  
  .password-wrapper { 
    background: #f9f9f9;
    border: 1px solid #D9D9D9;
    border-radius: 4px;
    padding: 15px;
    padding-bottom: 0px;
    margin-bottom: 20px;
    animation: translating 0.5s linear infinite;
    transition: 0.5s ease;
  }
  @media (max-width: 1000px){
    width:100%;
  }
`;


export default FormStaff;