import React from "react";
import { PageHeader } from "antd";
import styled from "styled-components";

const Content = styled.div`
  border-radius: 4px;
  position: relative;
  width: 100%;
  color: #333;
  overflow: auto;
`;
export const PageContainer = (props) => {
  return <Content {...props} />;
};

export const PageHeaders = styled(PageHeader)`
  .ant-page-header-heading-title {
    font-size: 18px;
    margin-top: 0;
    margin-bottom: 0;
    color: rgba(0, 0, 0, 0.85);
    font-weight: 500;
  }
  .anticon-arrow-left {
    color: #333;
  }
  .ant-badge-count {
    background-color: #ff2747 !important;
  }
`;

export const PageContent = styled.div`
  padding: 16px 24px;
  color: #333;
`;
