/* eslint-disable import/named */
/* eslint-disable key-spacing */
import React, { useState, useEffect } from "react";
import { Bar } from "@ant-design/plots";
import styled from "styled-components";
import { Button, Card, Divider, Modal, Row } from "antd";
import { dashboard as dashboardApi, total as totalApi } from "../../../apis";
import { NoPrint, Print } from "react-easy-print";

export const DemoLine = () => {
  const [isPrint, setIsPrint] = useState(false);
  const [chart, setChartData] = React.useState([]);
  const [filters, setFilters] = React.useState({
    filter: {
      startDate: "",
      endDate: "",
    },
    offset: {
      page: 1,
      limit: 10,
    },
  });
  const [results, setResult] = useState([]);

  const reload = React.useCallback(async () => {
    let res = await dashboardApi.Diagram("ALL", filters);
    setChartData(res.response.filter((item) => item.key !== "ALL"));
  }, [filters]);

  React.useEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;
    reload(signal);
    return () => abortController.abort();
  }, [reload]);

  let timeout;

  const initRes = async () => {
    let res = await totalApi.select({
      query: "",
    });
    setResult(res);
  };

  useEffect(() => {
    initRes();
  }, []);
  const data = chart
    .map((r) => r.risks.map((c) => ({ ...c, name: r.name })))
    .flat();

  const notIncludedType = ["ALL", "FAILED", "REFUSED", "NEW", "ALLRISK"];

  const filteredData = data.filter(
    (item) => !notIncludedType.includes(item.key)
  );

  const sortedData = Object.values(
    filteredData.reduce((acc, { name, total }) => {
      acc[name] = acc[name] || { name, total: 0 };
      acc[name].total += total;
      return acc;
    }, {})
  ).sort((a, b) => b.total - a.total);

  const r = sortedData
    .map((t) => filteredData.map((c) => (t.name === c.name ? c : null)))
    .flat()
    ?.filter((c) => c !== null)
    ?.map((m) => ({
      ...m,
      name: m?.name?.length > 24 ? `${m?.name?.slice(0, 24)}...` : m?.name,
    }));

  const config = {
    data: r.filter((data) => data?.total !== 0) || [],
    xField: "total",
    yField: "name",
    seriesField: "status",
    isStack: true,
    color: r.filter((item) => item).map((d) => d.color),
    label: {
      offset: "-50%",
      style: {},
      autoRotate: false,

      layout: [
        {
          type: "interval-adjust-position",
        },
        {
          type: "interval-hide-overlap",
        },
        {
          type: "adjust-color",
        },
      ],
    },
  };

  return (
    <StyledDiv>
      <div className="flex">
        <h3>Салбарын эрсдэлийн мэдээлэл</h3>
      </div>
      <Divider />
      <div height={200} className="scroll">
        <Bar {...config} style={{ height: "500px" }} />
      </div>
      <Modals
        centered
        closeIcon={<div></div>}
        maskClosable
        width={"max-content"}
        open={isPrint[0] === "print"}
        title={false}
        footer={false}
        style={{ boxShadow: "none" }}
        maskStyle={{
          boxShadow: "none",
          border: "none",
        }}
      >
        <Print>
          <div style={{ width: "850px", height: "700px" }}>
            {isPrint[1] === "DemoLine" && <DemoLine />}
          </div>
        </Print>

        <NoPrint>
          <Row justify="end" style={{ paddingTop: 10 }}>
            <Button onClick={() => setIsPrint([])} style={{ marginRight: 14 }}>
              Цуцлах
            </Button>
            <Button onClick={() => window.print()} type="primary">
              Хэвлэх
            </Button>
          </Row>
        </NoPrint>
      </Modals>
    </StyledDiv>
  );
};

const Modals = styled(Modal)`
  .ant-modal-content {
    box-shadow: none !important;
  }
  .ant-modal-body {
    box-shadow: none !important;
  }
`;
const StyledDiv = styled(Card)`
  .flex {
    display: flex;
    justify-content: space-between;
  }
  .scroll {
    overflow: scroll;
    height: 550px;
  }
  .flex {
    display: flex;
    justify-content: space-between;
  }
`;
