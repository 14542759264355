/* eslint-disable key-spacing */
/* eslint-disable react-hooks/exhaustive-deps */
import { CameraOutlined, UserOutlined } from "@ant-design/icons";
import { Avatar, Button, Col, Input, Row, Space, Tag } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { notify as notifyApi } from "../../../apis";
import { MyTable } from "../../../components";
import { datetimeFormat } from "../../../utils";

const ListDetails = () => {
  const myTableRef = React.useRef();
  const [query, setQuery] = React.useState("");
  const { notifyStatus = [], s3host } = useSelector((state) => state.general);
  const params = useParams();
  const [filters, setFilters] = React.useState({
    filter: {
      query: "",
      targetType: undefined,
      notifyStatus: undefined,
      type: undefined,
      post: params.id,
    },
    offset: {
      page: 1,
      limit: 10,
    },
  });

  const columns = useHeader({
    notifyStatus,
    s3host,
  });

  let timeout;

  React.useEffect(() => {
    if (timeout) clearTimeout(timeout);
    if (filters.query !== query)
      timeout = setTimeout(() => {
        setFilters({
          ...filters,
          filter: {
            ...filters.filter,
            query: query?.trim(),
          },
        });
      }, 300);

    return () => {
      if (timeout) clearTimeout(timeout);
    };
  }, [query]);

  return (
    <div style={{ marginTop: "24px" }}>
      <h2>Мэдэгдэл дэлгэрэнгүй</h2>
      <MyTable
        scroll={{ x: "auto" }}
        style={{ marginTop: "20px", minWidth: "100%" }}
        columns={columns}
        ref={myTableRef}
        filters={filters}
        loadData={notifyApi.listDetails}
        rowKey={(record) => record?._id}
      />
    </div>
  );
};

const useHeader = ({ notifyStatus, s3host }) => {
  return [
    {
      title: "№",
      dataIndex: "i",
      align: "center",
      width: "50px",
    },
    {
      title: "Зураг",
      render: (record) => {
        return (
          <Avatar
            icon={<CameraOutlined />}
            src={`${s3host}/${record?.image}`}
            size={40}
            shape="square"
          />
        );
      },
    },
    {
      title: "Гарчиг",
      render: (record) => {
        return (
          <div
            style={{
              minWidth: "220px",
              maxWidth: "400px",
              whiteSpace: "pre-wrap",
            }}
          >
            {record?.title}
          </div>
        );
      },
    },
    {
      title: "Хэрэглэгчийн мэдээлэл",
      render: (record) => {
        if (record.user)
          return (
            <Space>
              <Avatar
                icon={<UserOutlined />}
                src={`${s3host}/${record?.user?.avatar}`}
                size={45}
                shape="square"
              />
              <Space direction="vertical" size={2} style={{ fontSize: "12px" }}>
                <b>
                  {record?.user?.lastName
                    ? `${record?.user?.lastName?.substring(0, 1)}.`
                    : ""}
                  {record?.user?.firstName}
                </b>
                {record?.user?.role && (
                  <Tag style={{ fontSize: "11px" }}>
                    {record?.user?.role === "SECTOR" && "Салбарын ажилтан"}
                    {record?.user?.role === "USER" && "Хэрэглэгч"}
                    {record?.user?.role === "SUPER" && "Сүпэр Админ"}
                  </Tag>
                )}
              </Space>
            </Space>
          );
        return "-";
      },
    },
    {
      title: "Харсан эсэх",
      render: (record) => {
        return record?.isSeen ? <Tag color="green">Тийм</Tag> : <Tag>Үгүй</Tag>;
      },
    },
    {
      title: "Харсан огноо",
      render: (record) => {
        return record?.seenDate ? (
          <div style={{ whiteSpace: "nowrap" }}>
            {datetimeFormat(record?.seenDate)}
          </div>
        ) : (
          "-"
        );
      },
    },
    {
      title: "Огноо",
      render: (record) => {
        return (
          <div style={{ whiteSpace: "nowrap" }}>
            {record?.notificationStatusDate
              ? datetimeFormat(record?.notificationStatusDate)
              : "-"}
          </div>
        );
      },
    },
    {
      title: "FireBase амжилттай эсэх",
      align: "center",
      render: (record) => {
        return record?.isSeen ? (
          <Tag color="green">Амжилттай</Tag>
        ) : (
          <Tag color="red">Амжилтгүй</Tag>
        );
      },
    },
  ];
};

export default ListDetails;
