import { Button, Col, message, Row } from "antd";
import { Formik } from "formik";
import { Form, FormItem, Input } from "formik-antd";
import React from "react";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";
import { auth as authApi } from "../../../apis";

const PasswordForm =() => {
  const [data] = React.useState({
    oldPassword    : undefined,
    password       : undefined,
    confirmPassword: undefined
  });
  const history = useHistory();

  const FormSchema = () => Yup.object().shape({
    oldPassword: Yup.string().required("Заавал бөглөнө!"),
    password   : Yup.string()
      .min(6, "Нууц үг 6-с их орон байх хэрэгтэй!")
      .max(20, "Нууц үг 20-с бага орон байх хэрэгтэй!")
      .required("Заавал бөглөнө!"),
    confirmPassword: Yup
      .string()
      .oneOf([Yup.ref("password"), null], "Нууц үг давтан оруулна уу!")
      .required("Заавал бөглөнө!")
  });

  const onSubmit = async (value, action) => {
    try {
      let res = await authApi.password(value);
      if (res){
        message.success("Нууц үг амжилттай солигдлоо.");
      }
      action.resetForm();
    } catch (error) {
      action.resetForm();
      if (error.message || error.payload){
        message.error(error.message || error.payload || "Үйлдэл амжилтгүй.");
      }
      else {
        message.error(error.message || "Үйлдэл амжилтгүй.");
      }
    }
  };

  return (
    <Col>
      <Formik
        initialValues={data}
        validationSchema={() => FormSchema()}
        onSubmit={onSubmit}>
        {({ isSubmitting }) => {
          return (
            <Form layout="vertical" >
              <FormItem name="oldPassword" label="Хуучин нууц үг" required>
                <Input.Password prefix={<span/>} name="oldPassword" placeholder="Хуучин нууц үг" />
              </FormItem>
              <FormItem name="password" label="Шинэ нууц үг" required>
                <Input.Password prefix={<span/>} name="password" placeholder="Шинэ нууц үг" />
              </FormItem>
              <FormItem name="confirmPassword" label="Шинэ нууц үг давтах" required>
                <Input.Password prefix={<span/>} name="confirmPassword" placeholder="Шинэ нууц үг давтах" />
              </FormItem>
              <Row justify="start" style={{ marginTop: "40px" }}>
                <Button className="button" style={{ marginRight: 10 }} htmlType="submit" type="primary" loading={isSubmitting}>Хадгалах</Button>
                <Button onClick={() => {history.goBack();}}>
                  Болих
                </Button>
              </Row>
            </Form>
          );
        }}
      </Formik>
    </Col>
  );
};

export default PasswordForm;