/* eslint-disable key-spacing */
// import { Status, Wrapper } from "@googlemaps/react-wrapper";
import { Marker, MarkerClusterer } from "@react-google-maps/api";
import { Modal } from "antd";
import React from "react";
import { useHistory } from "react-router-dom";
import useSWR from "swr";
import { default as NewLocate } from "../../assets/status-svg/newlocate.svg";
import GoogleMapComponent from "../../components/googleMap";
import request from "../../utils/request";

const GoogleMap = () => {
  const { data } = useSWR("/api/map", request.get);
  const history = useHistory();

  return (
    <div>
      {data ? (
        <GoogleMapComponent>
          <MarkerClusterer
            options={{
              imagePath:
                "https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m", // so you must have m1.png, m2.png, m3.png, m4.png, m5.png and m6.png in that folder
            }}
          >
            {(clusterer) =>
              data.map((issue, index) => (
                <Marker
                  onClick={() => {
                    Modal.confirm({
                      title: "Дэлгэрэнгүй үзэх үү?",
                      onOk: async () => {
                        history.push(`/post/${issue._id}`);
                      },
                      okText: "Тийм",
                    });
                  }}
                  key={index}
                  clusterer={clusterer}
                  icon={{
                    url: NewLocate,
                    scaledSize: {
                      width: 40,
                      height: 40,
                    },
                  }}
                  position={{
                    lat: issue.location.lat,
                    lng: issue.location.lng,
                  }}
                />
              ))
            }
          </MarkerClusterer>
        </GoogleMapComponent>
      ) : (
        <div>Loading...</div>
      )}
    </div>
  );
};

export default GoogleMap;
