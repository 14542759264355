import request from "../utils/request";

export const get = (id) => request.get(`/api/post/${id}`);

export const list = (data, options) => request.get("/api/post", data, options);

export const create = (data) => request.post("/api/post", data);

export const update = (id, data) => request.put(`/api/post/${id}`, data);

export const remove = (id) => request.del(`/api/post/${id}`);

export const hide = (id, data) => request.put(`/api/post/${id}/hide`, data);

export const select = (data, options) =>
  request.get("/api/post/select", data, options);

export const history = (id) => request.get(`/api/history/${id}`);

export const reports = (data) => request.get("/api/report", data);

export const reportedUsers = (data) =>
  request.get(`/api/report/${data.filter.id}`, data);

export const cancel = (id, data) => request.put(`/api/post/${id}/cancel`, data);

export const decline = (id, data) =>
  request.put(`/api/post/${id}/decline`, data);

export const solve = (id, data) => request.put(`/api/post/${id}/solve`, data);

export const review = (id, data) => request.put(`/api/post/${id}/review`, data);

export const accept = (id, data) => request.put(`/api/post/${id}/accept`, data);
export const all = (id, data) => request.put(`/api/post/${id}/all`, data);
