import { dashboard } from ".";
import request from "../utils/request";

export const userChart = (id) => request.get("/api/dashboard");

export const postSectorChart = (id, data) =>
  request.get(`/api/dashboard/sector/${id}`, data);

export const Diagram = (id, data) =>
  request.get(`/api/dashboard/total/${id}`, data);

export const TestChart = () => request.get("/api/dashboard/count");

export const Charted = (id) => request.get(`/api/dashboard/total/${id}`);
