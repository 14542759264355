/* eslint-disable key-spacing */
const initialState = {
  s3host: undefined,
  userRoles: [],
  postStatus: [],
  rolePermission: [],
  isMobile: false,
  drawer: false,
  collapse: false,
  newPost: undefined,
  userStatus: [],
  targetTypes: [],
  notifyStatus: [],
  permissionCodes: [],
  webUserRoles: [],
  objectTypes: [],
  postStatusType: "",
  permissions: [],
  reportTypes: [],
  postTabKey: "1",
  reportedPosts: 0,
};

const general = (state = initialState, action = {}) => {
  switch (action.type) {
    case "general.init": {
      const {
        s3host,
        postStatus = [],
        reportedPosts,
        rolePermission = [],
        userRoles = [],
        newPost,
        userStatus = [],
        actionTypes = [],
        objectTypes = [],
        targetTypes = [],
        notifyStatus = [],
        permissionCodes = [],
        webUserRoles = [],
        permissions,
        reportTypes = [],
      } = action.payload;
      return {
        ...state,
        s3host,
        userRoles,
        postStatus,
        rolePermission,
        newPost,
        userStatus,
        actionTypes,
        objectTypes,
        targetTypes,
        notifyStatus,
        permissionCodes,
        webUserRoles,
        permissions,
        reportTypes,
        reportedPosts,
      };
    }
    case "general.is_mobile": {
      return {
        ...state,
        isMobile: action.payload,
      };
    }
    case "general.postTabKey": {
      return {
        ...state,
        postTabKey: action.payload,
      };
    }
    case "general.collapse": {
      return {
        ...state,
        collapse: action.payload,
      };
    }
    case "general.drawer": {
      return {
        ...state,
        drawer: action.payload,
      };
    }
    case "general.postStatusType": {
      return {
        ...state,
        postStatusType: action.payload,
      };
    }
    default:
      return state;
  }
};

export default general;
