import { useSelector } from "react-redux";

export default function Permission() {
  const { user } = useSelector(state => state.auth);

  if (user)
    return {
      checkPermission: (codes = []) => {
        let allowPermission = false;

        if (codes.length > 0) {
          if (user?.role === "SUPER")
            allowPermission = true;
          else
            allowPermission = codes?.some(item => {

              return user?.permission?.find(i => i.code === item);
            });
        }
        return allowPermission;
      }
    };
};
