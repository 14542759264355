/* eslint-disable react-hooks/exhaustive-deps */
import { CameraOutlined, UserOutlined } from "@ant-design/icons";
import { Avatar, Button, Input, Space, Tag } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { notify as notifyApi } from "../../apis";
import { MyTable } from "../../components";
import { Filter } from "../../components/Design";
import { PageContainer, PageContent, PageHeaders } from "../../components/Layout";
import { datetimeFormat } from "../../utils";

const ListDetails = () => {
  const myTableRef = React.useRef();
  const history = useHistory();
  const [query, setQuery] = React.useState("");
  const { targetTypes = [], isMobile, notifyStatus = [], s3host } = useSelector(state => state.general);
  const params = useParams();
  const [filters, setFilters] = React.useState({
    filter: {
      query       : "",
      targetType  : undefined,
      notifyStatus: undefined,
      type        : undefined,
      notify      : params.id
    },
    offset: {
      page : 1,
      limit: 10
    }
  });

  const columns = useHeader({
    targetTypes,
    notifyStatus,
    s3host
  });

  let timeout;

  const onClear =()=> {
    setQuery("");
    setFilters({
      filter: {
        query       : "",
        targetType  : undefined,
        notifyStatus: undefined,
        type        : undefined,
        notify      : params.id
      },
      offset: {
        page : 1,
        limit: 10
      }
    });
  };

  React.useEffect(() => {
    if (timeout) clearTimeout(timeout);
    if (filters.filter.query !== query)
      timeout = setTimeout(() => {
        setFilters(state => ({ ...state, filter: { query: query?.trim(), notify: params.id } }));
      }, 300);

    return () => {
      if (timeout)
        clearTimeout(timeout);
    };
  }, [query]);

  return (
    <PageContainer>
      <PageHeaders
        onBack={() => history.push("/notify")}
        title="Мэдэгдэл дэлгэрэнгүй"
      />
      <PageContent>
        <Filter>
          <Input
            onChange={(e) => setQuery(e.target.value)}
            value={query}
            placeholder="Хайх..."
            allowClear
          />
          <Button onClick={onClear}>Цэвэрлэх</Button>
        </Filter>
        <MyTable
          scroll={{ x: "100%" }}
          style={{ marginTop: "20px" }}
          columns={columns}
          ref={myTableRef}
          filters={filters}
          loadData={notifyApi.listDetails}
          rowKey={(record) => record?._id}
        />
      </PageContent>
    </PageContainer>
  );
};

const useHeader = ({ notifyStatus, s3host }) => {
  return [{
    title    : "№",
    dataIndex: "i",
    width    : "50px",
    align    : "center"
  }, {
    title : "Зураг",
    render: (record) => {
      return <Avatar icon={<CameraOutlined />} src={`${s3host}/${record?.image}`} size={40} shape="square" />;
    }
  }, {
    title : "Гарчиг",
    render: (record) => {
      return <div style={{ minWidth: "220px", maxWidth: "420px", whiteSpace: "pre-wrap" }}>{record?.title}</div>;
    }
  }, {
    title : "Тайлбар",
    render: (record) => {
      return <div style={{ minWidth: "220px", maxWidth: "420px", whiteSpace: "pre-wrap" }}>{record?.body}</div>;
    }
  }, {
    title : "Хэрэглэгчийн мэдээлэл",
    render: (record) => {
      if (record?.user)
        return <Space>
          <Avatar icon={<UserOutlined />} src={`${s3host}/${record?.user?.avatar}`} size={45} shape="square" />
          <Space direction="vertical" size={2} style={{ fontSize: "12px" }}>
            <b>
              {record?.user?.lastName ? `${record?.user?.lastName?.substring(0, 1)}.`: ""}{record?.user?.firstName}
            </b>
            {record?.user?.role && <Tag style={{ fontSize: "11px" }}>
              {record?.user?.role === "SECTOR" && "Салбарын ажилтан"}
              {record?.user?.role === "USER" && "Хэрэглэгч"}
              {record?.user?.role === "SUPER" && "Сүпэр Админ"}
            </Tag>}
          </Space>
        </Space>;
      return "-";
    }
  }, {
    title : "Харсан эсэх",
    render: (record) => {
      return record?.isSeen ? <Tag color="green">Тийм</Tag> : <Tag>Үгүй</Tag>;
    }
  }, {
    title : "Харсан огноо",
    render: (record) => {
      return record?.seenDate ? <div style={{ whiteSpace: "nowrap" }}>{datetimeFormat(record?.seenDate)}</div> : "-";
    }
  }, {
    title : "Төлөв",
    render: (record) => {
      return <Tag color={notifyStatus?.find(item => item.code === record?.notificationStatus)?.color} >{notifyStatus?.find(item => item.code === record?.notificationStatus)?.name || "-"}</Tag>;
    }
  }, {
    title : "FireBase амжилттай эсэх",
    align : "center",
    render: (record) => {
      return record?.isSeen ? <Tag color="green">Амжилттай</Tag> : <Tag color="red">Амжилтгүй</Tag>;
    }
  }];
};

export default ListDetails;