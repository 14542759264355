/* eslint-disable key-spacing */
import {
  BellOutlined,
  ClockCircleOutlined,
  CustomerServiceOutlined,
  FileProtectOutlined,
  FileTextOutlined,
  FormOutlined,
  HomeOutlined,
  MedicineBoxOutlined,
  PieChartOutlined,
  SettingOutlined,
  SmileOutlined,
  TeamOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Badge, Menu, Space } from "antd";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useHistory } from "react-router-dom";
import styled from "styled-components";
import usePermission from "../../hooks/usePermission";

const NavBarMobile = ({ isDrawer }) => {
  const history = useHistory();
  const [selected, setSelected] = useState(
    history.location.pathname.split("/")[1] || "dashboard"
  );
  const { isMobile, collapse, newPost } = useSelector((state) => state.general);

  const dispatch = useDispatch();

  const onClick = (e) => {
    setSelected(e.key);
    console.log(e);

    dispatch({
      type: "general.postStatusType",
      payload: "NEW",
    });
    dispatch({
      type: "general.drawer",
      payload: false,
    });
  };
  const { checkPermission } = usePermission();

  return (
    <SideBarStyled>
      <Menu
        className="menu"
        inlineCollapsed={isMobile ? !isDrawer : collapse}
        onClick={onClick}
        selectedKeys={selected}
        mode="inline"
        theme="light"
      >
        <Menu.Item key="dashboard" icon={<PieChartOutlined />}>
          Хянах самбар <NavLink to="/" />{" "}
        </Menu.Item>

        {checkPermission([
          "POST_MANAGEMENT",
          "SECTOR_MANAGEMENT",
          "USER_MANAGEMENT",
        ]) && (
          <Menu.SubMenu
            key="post_management"
            title="Эрсдэл удирдлага"
            icon={<MedicineBoxOutlined />}
          >
            {checkPermission(["POST_MANAGEMENT"]) && (
              <Menu.Item key="post" icon={<ClockCircleOutlined />}>
                <Space>
                  Эрсдэл
                  <Badge count={newPost} />
                </Space>
                <NavLink to="/post" />
              </Menu.Item>
            )}
            {checkPermission(["USER_MANAGEMENT"]) && (
              <Menu.Item key="app" icon={<TeamOutlined />}>
                Хэрэглэгч
                <NavLink to="/webuser" />
              </Menu.Item>
            )}
            {checkPermission(["SECTOR_MANAGEMENT"]) && (
              <Menu.Item key="Sector" icon={<HomeOutlined />}>
                Салбар нэгж
                <NavLink to="/sector" />
              </Menu.Item>
            )}
          </Menu.SubMenu>
        )}

        {checkPermission(["NOTICE_MANAGEMENT"]) && (
          <Menu.Item key="notice" icon={<FileTextOutlined />}>
            Нийтлэл
            <NavLink to="/notice" />
          </Menu.Item>
        )}
        {checkPermission(["NOTIFY_MANAGEMENT"]) && (
          <Menu.Item key="notify" icon={<BellOutlined />}>
            Мэдэгдэл
            <NavLink to="/notify" />
          </Menu.Item>
        )}
        {checkPermission(["FAQ"]) && (
          <Menu.Item key="faq" icon={<CustomerServiceOutlined />}>
            Нийтлэг асуулт хариулт
            <NavLink to="/faq" />
          </Menu.Item>
        )}
        {checkPermission([
          "PERMISSION_MANAGEMENT",
          "STAFF_MANAGEMENT",
          "AUDIT_LOG",
        ]) && (
          <Menu.SubMenu
            key="settings"
            title="Тохиргоо"
            icon={<SettingOutlined />}
          >
            {checkPermission(["STAFF_MANAGEMENT"]) && (
              <Menu.Item key="staff" icon={<UserOutlined />}>
                Админ хэрэглэгч
                <NavLink to="/staff" />
              </Menu.Item>
            )}
            {checkPermission(["PERMISSION_MANAGEMENT"]) && (
              <Menu.Item key="permission" icon={<FormOutlined />}>
                Хандах эрх
                <NavLink to="/permission" />
              </Menu.Item>
            )}
            {checkPermission(["ABOUT"]) && (
              <Menu.Item key="about" icon={<SmileOutlined />}>
                Бидний тухай
                <NavLink to="/about" />
              </Menu.Item>
            )}
            {checkPermission(["AUDIT_LOG"]) && (
              <Menu.Item key="log" icon={<FileProtectOutlined />}>
                Лог бүртгэл
                <NavLink to="/log" />
              </Menu.Item>
            )}
          </Menu.SubMenu>
        )}
      </Menu>
    </SideBarStyled>
  );
};

const SideBarStyled = styled.div`
  height: 100%;
  overflow-y: scroll;
  padding: 0;
  .toggle {
    bottom: 0px;
  }
  .menu {
    padding: 0;
    height: 100%;
    border: none;
    width: 100%;
  }
`;

export default NavBarMobile;
