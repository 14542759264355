/* eslint-disable key-spacing */
import { Button, message, notification, Row } from "antd";
import { Formik } from "formik";
import { Form, FormItem, Input } from "formik-antd";
import React from "react";
import { useHistory } from "react-router";
import styled from "styled-components";
import * as Yup from "yup";
import { user as userApi } from "../../apis";
import { ImageUpload } from "../../components";
import { StyledForm } from "../../components/Design";
import { mongolText } from "../../utils";

const { TextArea } = Input;

const FormSchema = () =>
  Yup.object().shape({
    firstName: Yup.string()
      .trim()
      .matches(mongolText, "Та кириллээр бичнэ үү.")
      .required("Заавал бөглөнө!")
      .max(45, "Хэт урт байна!")
      .when((data) => {
        let reWhiteSpace = /\s+/;
        let text = data?.trim();
        if (reWhiteSpace.test(text)) {
          return Yup.string()
            .matches(mongolText, "Зай авж болохгүй!")
            .required("Заавал бөглөнө!");
        }
      }),
    lastName: Yup.string()
      .trim()
      .matches(mongolText, "Та кириллээр бичнэ үү.")
      .required("Заавал бөглөнө!")
      .max(45, "Хэт урт байна!")
      .when((data) => {
        let reWhiteSpace = /\s+/;
        let text = data?.trim();
        if (reWhiteSpace.test(text)) {
          return Yup.string()
            .matches(mongolText, "Зай авж болохгүй!")
            .required("Заавал бөглөнө!");
        }
      }),
    email: Yup.string().when((data, schema, test) => {
      if (test.parent.email)
        return Yup.string()
          .email("И-мэйл хаяг буруу байна")
          .required("Заавал бөглөнө!")
          .trim();
    }),
    phone: Yup.string()
      .min(8, "Дугаарын орон багадаа 8 байна!")
      .max(11, "Дугаарын орон ихдээ 11 орон байна!")
      .required("Заавал бөглөнө!"),
    password: Yup.string()
      .min(6, "Нууц үг 6-с их орон байх хэрэгтэй!")
      .max(20, "Нууц үг 20-с бага орон байх хэрэгтэй!")
      .required("Заавал бөглөнө!"),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), null], "Нууц үг таарахгүй байна!")
      .required("Заавал бөглөнө!"),
  });

const FormApp = ({ action }) => {
  const history = useHistory();
  const [data] = React.useState({
    firstName: undefined,
    lastName: undefined,
    email: undefined,
    phone: undefined,
    address: undefined,
    role: undefined,
    sector: undefined,
    avatar: undefined,
    password: undefined,
    userStatus: "NEW",
    confirmPassword: undefined,
    ...(action && action[0] === "update" ? action[1] : {}),
    sectorId:
      action && action[0] === "update" ? action[1]?.sector?._id : undefined,
  });

  const onSubmit = async (values) => {
    try {
      if (action && action[0] === "update") {
        if (!values.sectorId) message.error("Хуваарилалт хийнэ үү.");
        else {
          await userApi.update(action[1]?._id, {
            ...values,
            phone: values.phone.toString(),
            sector: values.sectorId,
            address: values.address.trim(),
          });
          history.push("/webuser");
          message.success("Таны хүсэлт амжилттай!");
        }
      } else {
        await userApi.create({ ...values, phone: values.phone.toString() });
        history.push("/webuser");
        message.success("Таны хүсэлт амжилттай!");
      }
    } catch (error) {
      if (error.message)
        notification.error({
          message: error.message,
        });
    }
  };

  return (
    <Container>
      <Formik
        enableReinitialize
        initialValues={data}
        validationSchema={FormSchema}
        onSubmit={onSubmit}
      >
        {({ isSubmitting, values }) => (
          <StyledForm>
            <Form layout="vertical">
              <FormItem label="Зураг" name="avatar">
                <ImageUpload action="/api/general/upload/image" name="avatar" />
              </FormItem>
              <FormItem name="lastName" label="Овог" required>
                <Input prefix={<span />} name="lastName" placeholder="Овог" />
              </FormItem>
              <FormItem name="firstName" label="Нэр" required>
                <Input prefix={<span />} name="firstName" placeholder="Нэр" />
              </FormItem>
              <FormItem name="phone" label="Утас" required>
                <Input
                  prefix={<span />}
                  name="phone"
                  type="number"
                  placeholder="Утас"
                />
              </FormItem>
              <FormItem name="email" label="И-мэйл">
                <Input prefix={<span />} name="email" placeholder="И-мэйл" />
              </FormItem>
              <FormItem name="address" label="Хаяг">
                <TextArea
                  showCount
                  maxLength={500}
                  rows={6}
                  name="address"
                  placeholder="Хаяг"
                />
              </FormItem>
              <div className="password-wrapper">
                <FormItem name="password" label="Нууц үг" required>
                  <Input.Password
                    prefix={<span />}
                    name="password"
                    placeholder="Нууц үг"
                  />
                </FormItem>
                <FormItem
                  name="confirmPassword"
                  label="Нууц үг давтах"
                  required
                >
                  <Input.Password
                    prefix={<span />}
                    name="confirmPassword"
                    placeholder="Нууц үг давтах"
                  />
                </FormItem>
              </div>
              <Row justify="end" style={{ marginTop: "40px" }}>
                <Button
                  className="button"
                  style={{ marginRight: 10 }}
                  htmlType="submit"
                  type="primary"
                  loading={isSubmitting}
                >
                  Хадгалах
                </Button>
                <Button
                  className="button"
                  type="default"
                  onClick={() => history.push("/webuser")}
                >
                  Болих
                </Button>
              </Row>
            </Form>
          </StyledForm>
        )}
      </Formik>
    </Container>
  );
};

const Container = styled.div`
  margin-top: 20px;
  width: 80%;

  .password-wrapper {
    background: #f9f9f9;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    padding: 15px;
    padding-bottom: 0px;
    margin-bottom: 20px;
    animation: translating 0.5s linear infinite;
    transition: 0.5s ease;
  }
  @media (max-width: 1000px) {
    width: 100%;
  }
`;

export default FormApp;
