/* eslint-disable key-spacing */
import {
  CameraOutlined,
  ExclamationCircleOutlined,
  UserOutlined,
} from "@ant-design/icons";
import {
  Avatar,
  Button,
  Col,
  Input,
  message,
  Modal,
  Row,
  Select,
  Tag,
} from "antd";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { sector as sectorApi, user as userApi } from "../../../apis";
import { MyTable, RowAction } from "../../../components";
import { Filter } from "../../../components/Design";
import FormSectorModal from "./../components/FormSectorModal";

const UserSectorList = ({ myTableRef }) => {
  const history = useHistory();
  const { s3host, userStatus } = useSelector((state) => state.general);
  const [results, setResult] = useState([]);
  const [sectorModal, setSectorModal] = useState([]);
  const [query, setQuery] = React.useState(undefined);
  const [filters, setFilter] = React.useState({
    filter: {
      query: undefined,
      isActive: undefined,
      role: "SECTOR",
      sector: undefined,
    },
  });
  const columns = useHeader({
    s3host,
    userStatus,
    onClick: (key, item) => {
      switch (key) {
        case "status": {
          Modal.confirm({
            title: "Төлөв солих",
            icon: <ExclamationCircleOutlined />,
            content: (
              <>
                {item.isActive ? (
                  <span style={{ color: "red" }}>Идэвхгүй</span>
                ) : (
                  <span style={{ color: "green" }}>Идэвхтэй</span>
                )}{" "}
                болгох уу!
              </>
            ),
            onOk: async () => {
              try {
                await userApi.active(item._id);
                await myTableRef.current.reload();
              } catch (error) {
                if (error.message) {
                  message.error(error.message);
                }
              }
            },
            okText: "Тийм",
          });
          break;
        }
        case "roleChange": {
          Modal.confirm({
            title: "Баталгаажуулах",
            icon: <ExclamationCircleOutlined />,
            content: "Та үүнийг Салбараас хасах уу!",
            onOk: async () => {
              try {
                await userApi.roleChange(item._id);
                await myTableRef.current.reload();
              } catch (error) {
                if (error.message) {
                  message.error(error.message);
                }
              }
            },
          });
          break;
        }
        case "details": {
          history.push(`/app/${item._id}`);
          break;
        }
        case "edit": {
          console.log("item: ", item._id);
          setSectorModal(["update", item]);
          break;
        }
        case "remove": {
          Modal.confirm({
            title: "Баталгаажуулах",
            icon: <ExclamationCircleOutlined />,
            content: "Та үүнийг устгахдаа итгэлтэй байна уу!",
            onOk: async () => {
              try {
                await userApi.remove(item._id);
                await myTableRef.current.reload();
              } catch (error) {
                if (error.message) {
                  message.error(error.message);
                }
              }
            },
          });
          break;
        }
        default:
      }
    },
  });

  const onClear = () => {
    setQuery(undefined);
    setFilter({
      filter: {
        query: undefined,
        isActive: undefined,
        role: "SECTOR",
        sector: undefined,
      },
      offset: {
        page: 1,
        limit: 10,
      },
    });
  };

  useEffect(() => {
    let timer = setTimeout(() => {
      if (filters.filter.query !== query)
        setFilter({
          ...filters,
          filter: {
            query: query?.trim(),
            role: "SECTOR",
          },
        });
    }, 300);

    return () => clearTimeout(timer);
  }, [query]);

  const onSearch = async (query = null) => {
    let timeout;

    if (query) {
      if (timeout) clearTimeout(timeout);

      timeout = setTimeout(async () => {
        let res = await sectorApi.select({
          query: query?.trim(),
        });
        setResult(res);
      }, 300);
    } else {
      initRes();
    }
  };

  const initRes = async () => {
    let res = await sectorApi.select({
      query: "",
    });
    setResult(res);
  };

  useEffect(() => {
    initRes();
  }, []);

  return (
    <div>
      <Filter>
        <Input
          onChange={(e) => setQuery(e.target.value)}
          value={query}
          placeholder="Хайх..."
          allowClear
        />
        <Select
          value={filters.filter.sector}
          onChange={(e) =>
            setFilter({ ...filters, filter: { ...filters.filter, sector: e } })
          }
          placeholder="Салбар сонгох"
          showSearch
          optionFilterProp="children"
          onSearch={(e) => onSearch(e)}
          allowClear
        >
          {results &&
            results?.rows?.map((item, index) => (
              <Select.Option value={`${item?._id}`} key={index}>
                {item.fullName}
              </Select.Option>
            ))}
        </Select>
        <Button onClick={onClear}>Цэвэрлэх</Button>
      </Filter>
      <MyTable
        scroll={{ x: "max-content" }}
        style={{ marginTop: "20px" }}
        ref={myTableRef}
        filters={filters}
        rowKey={(record) => record.id}
        columns={columns}
        loadData={userApi.list}
      />
      <Modal
        okText={"Хадгалах"}
        title="Салбар хуваарилах"
        open={sectorModal && sectorModal[0] === "update"}
        onCancel={() => setSectorModal([])}
        footer={false}
      >
        <FormSectorModal
          action={sectorModal}
          setAction={setSectorModal}
          myTableRef={myTableRef}
        />
      </Modal>
    </div>
  );
};

const useHeader = ({ s3host, onClick, userStatus }) => [
  {
    title: "№",
    dataIndex: "i",
    width: "20px",
  },
  {
    title: "Салбар",
    render: (row) => {
      if (row.sector)
        return (
          <Row gutter={[8, 16]}>
            <Col>
              <Avatar
                size={40}
                src={`${s3host}${row?.sector?.avatar}`}
                shape="square"
                icon={<CameraOutlined />}
              />
            </Col>
            <Col>
              <b>{row?.sector?.fullName}</b>
              <div style={{ lineHeight: "5px", fontSize: "10px" }}>
                {row?.sector?.email}
              </div>
            </Col>
          </Row>
        );
      return "-";
    },
  },
  {
    title: "Зураг",
    width: 40,
    render: (row) => {
      return (
        <Avatar
          icon={<UserOutlined />}
          src={`${s3host}${row.avatar}`}
          size={40}
        />
      );
    },
  },
  {
    title: "Овог",
    render: (row) => {
      return row.lastName || "-";
    },
  },
  {
    title: "Нэр",
    render: (row) => {
      return row.firstName || "-";
    },
  },
  {
    title: "И-мэйл хаяг",
    render: (row) => {
      return row.email || "-";
    },
  },
  {
    title: "Утас",
    render: (row) => {
      return row.phone || "-";
    },
  },
  {
    title: "Төлөв",
    render: (row) => {
      return (
        <Tag
          color={
            userStatus?.find((item) => item?.code === row.userStatus)?.color
          }
        >
          {userStatus?.find((item) => item.code === row.userStatus)?.name ||
            "-"}
        </Tag>
      );
    },
  },
  {
    title: "Идэвхтэй эсэх",
    render: (row) => {
      if (row.userStatus !== "NEW") {
        if (row?.isActive) return <Tag color={"green"}>Идэвхтэй</Tag>;
        return <Tag color={"red"}>Идэвхгүй</Tag>;
      }
      return "-";
    },
  },
  {
    title: "Үйлдэл",
    width: 100,
    fixed: "right",
    align: "center",
    render: (row) => {
      return (
        <RowAction
          actions={{
            // details      : row.userStatus !== "NEW" && row.isActive && "Дэлгэрэнгүй",
            edit: row.userStatus !== "NEW" && row.isActive && "Салбар солих",
            roleChange:
              row.userStatus !== "NEW" && row.isActive && "Салбараас хасах",
            status:
              row.userStatus !== "NEW" && row?.isActive
                ? "Идэвхгүй болгох"
                : "Идэвхжүүлэх",
          }}
          onClick={(key) => onClick(key, row)}
        />
      );
    },
  },
];

export default UserSectorList;
