import request from "../utils/request";

export const get = (id) => request.get(`/api/sector/${id}`);

export const list = (data) => request.get("/api/sector", data);

export const create = (data) => request.post("/api/sector", data);

export const update = (id, data) => request.put(`/api/sector/${id}`, data);

export const remove = (id) => request.del(`/api/sector/${id}`);

export const select = (data) => request.get("/api/sector/select", data);

export const sectorUsers = (id, data) =>
  request.get(`/api/sector/${id}/sectorUsers`, {
    filter: {
      query: data || "",
    },
  });
