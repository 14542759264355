/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable key-spacing */
import {
  ExclamationCircleOutlined,
  FileImageOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { Avatar, Button, Input, message, Modal, Tag } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { sector as sectorApi } from "../../apis";
import NoImage from "../../assets/noimage.png";
import { MyTable, RowAction } from "../../components";
import { Filter } from "../../components/Design";
import {
  PageContainer,
  PageContent,
  PageHeaders,
} from "../../components/Layout";

const SectorList = () => {
  const history = useHistory();
  const { s3host, isMobile } = useSelector((state) => state.general);

  const [query, setQuery] = React.useState(undefined);
  const [filters, setFilters] = React.useState({
    filter: {
      query: "",
    },
    offset: {
      page: 1,
      limit: 10,
    },
  });
  const myTableRef = React.useRef(null);
  const columns = useHeader({
    s3host,
    onClick: (key, item) => {
      switch (key) {
        case "edit": {
          history.push(`/sector/${item._id}`);
          break;
        }
        case "remove": {
          Modal.confirm({
            title: "Баталгаажуулах",
            icon: <ExclamationCircleOutlined />,
            content: "Та үүнийг устгахдаа итгэлтэй байна уу!",
            onOk: async () => {
              try {
                await sectorApi.remove(item._id);
                await myTableRef.current.reload();
              } catch (error) {
                if (error.message) {
                  message.error(error.message);
                }
              }
            },
          });
          break;
        }
        default:
      }
    },
  });

  const onClear = () => {
    setQuery(undefined);
    setFilters({
      filter: {
        query: "",
      },
      offset: {
        page: 1,
        limit: 10,
      },
    });
  };

  React.useEffect(() => {
    let timer = setTimeout(() => {
      if (filters.filter.query !== query)
        setFilters({
          ...filters,
          filter: {
            ...filters.filter,
            query: query?.trim() || "",
          },
        });
    }, 300);

    return () => clearTimeout(timer);
  }, [query]);

  return (
    <PageContainer>
      <PageHeaders
        title="Салбар удирдлага"
        extra={
          <Button
            type="primary"
            icon={isMobile && <PlusOutlined />}
            onClick={() => history.push("/sector/new")}
          >
            {!isMobile && "Салбар нэмэх"}
          </Button>
        }
      />
      <PageContent>
        <Filter>
          <Input
            onChange={(e) => setQuery(e.target.value)}
            value={query}
            placeholder="Хайх..."
            allowClear
          />
          <Button onClick={onClear}>Цэвэрлэх</Button>
        </Filter>
        <MyTable
          scroll={{ x: "max-content" }}
          style={{ marginTop: "20px" }}
          ref={myTableRef}
          filters={filters}
          rowKey={(record) => record.id}
          columns={columns}
          loadData={sectorApi.list}
        />
      </PageContent>
    </PageContainer>
  );
};

const useHeader = ({ s3host, onClick }) => [
  {
    title: "№",
    dataIndex: "i",
    width: "20px",
  },
  {
    title: "Лого",
    width: 70,
    align: "center",
    render: (row) => {
      return (
        <Avatar
          src={row.avatar ? `${s3host}/${row.avatar}` : NoImage}
          size={40}
          shape="square"
          icon={<FileImageOutlined />}
        />
      );
    },
  },
  {
    title: "Нэр",
    render: (row) => {
      return row.fullName || "-";
    },
  },
  {
    title: "И-мэйл хаяг",
    render: (row) => {
      return row.email || "-";
    },
  },
  {
    title: "Утас",
    render: (row) => {
      return row.phone || "-";
    },
  },
  {
    title: "Хаяг",
    render: (row) => {
      return row.address || "-";
    },
  },
  {
    title: "Ажилтантай эсэх",
    render: (row) => {
      return row.isUsed ? <Tag color="green">Тийм</Tag> : <Tag>Үгүй</Tag>;
    },
  },
  {
    title: "Үйлдэл",
    key: "action",
    width: 100,
    fixed: "right",
    align: "center",
    render: (row) => {
      if (row.isUsed) {
        return (
          <RowAction
            actions={{
              edit: "Засварлах",
            }}
            onClick={(key) => onClick(key, row)}
          />
        );
      }
      return (
        <RowAction
          actions={{
            edit: "Засварлах",
            remove: "Устгах",
          }}
          onClick={(key) => onClick(key, row)}
        />
      );
    },
  },
];

export default SectorList;
