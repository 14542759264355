import {
  CameraOutlined,
  ExclamationCircleOutlined,
  SendOutlined,
  UnlockOutlined,
  UserOutlined,
} from "@ant-design/icons";
import {
  Avatar,
  Button,
  Card,
  Divider,
  message,
  Modal,
  Result,
  Row,
  Skeleton,
} from "antd";
import { Checkbox, FormItem, Select } from "formik-antd";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import {
  post as postApi,
  sector as sectorApi,
  user as userApi,
} from "../../../apis";
import { ReactComponent as NewSvg } from "../../../assets/status-svg/new.svg";

const { Meta } = Card;
const Option = Select.Option;

const CardSector = ({ data, setFieldValue, isSubmitting, reloadData }) => {
  const { s3host, isMobile } = useSelector((state) => state.general);
  const [results, setResult] = useState([]);
  const [users, setUsers] = useState([]);
  const [sectorUserData, setSectorUserData] = useState({});
  const params = useParams();
  let timeout;

  const initRes = async () => {
    let res = await sectorApi.select({
      query: "",
    });
    setResult(res);
  };

  const initUsers = async (query = "", sectorId = null) => {
    if (timeout) clearTimeout(timeout);

    timeout = setTimeout(async () => {
      let res = await userApi.list({
        offset: {
          // eslint-disable-next-line key-spacing
          page: 1,
          limit: 10,
        },
        filter: {
          // eslint-disable-next-line key-spacing
          query: query.trim() || "",
          isActive: true,
          // eslint-disable-next-line key-spacing
          role: "SECTOR",
          // eslint-disable-next-line key-spacing
          sector: sectorId || null,
        },
      });
      setUsers(res);
    }, 300);
  };

  useEffect(() => {
    setFieldValue("sectorUserId", undefined);
    setFieldValue("sectorId", undefined);
    initUsers();
    initRes();
  }, []);

  const getSector = async (e, setFieldValue) => {
    if (e.length > 0) {
      let res = await sectorApi.get(e);
      setFieldValue("sector", res.sector);
    }
  };

  const getUser = async (e) => {
    if (e) {
      let res = await userApi.get(e);
      setSectorUserData(res);
    } else setSectorUserData();
  };

  const isActivePost = async (value) => {
    Modal.confirm({
      // eslint-disable-next-line key-spacing
      title: "Баталгаажуулах",
      // eslint-disable-next-line key-spacing
      icon: <ExclamationCircleOutlined />,
      // eslint-disable-next-line key-spacing
      okText: "Тийм",
      content: `Та үүнийг ${!value ? "ИДЭВХГҮЙ" : "ИДЭВХТЭЙ"} болгох уу!`,
      // eslint-disable-next-line key-spacing
      onOk: async () => {
        try {
          await postApi.hide(params.id, {
            isActive: value,
          });
          message.success("Төлөв амжилттай солигдлоо.");
        } catch (error) {
          if (error.message) {
            message.error(error.message);
          }
        }
        reloadData();
      },
    });
  };
  useEffect(() => {
    initRes();
    initUsers("", data.sectorId);
    getUser(data.sectorUserId);
    getSector(data.sectorId);
  }, [data.sectorId]);

  return (
    <CardWrapper>
      {!data.isActive ? (
        <Result
          icon={<NewSvg height={"100px"} width={"100px"} color="#ea4335" />}
          title={"Эрсдэлийг идэвхжүүлэх үү ?"}
          extra={
            <Button
              onClick={() => isActivePost(true)}
              type="primary"
              icon={<UnlockOutlined />}
            >
              {!isMobile && "Тийм"}
            </Button>
          }
        />
      ) : (
        <>
          <div>
            <div>
              <FormItem
                name="sectorId"
                label="Хуваарилах салбар сонгоно уу"
                required
              >
                <Select
                  status={!data.sectorId && "error"}
                  className="sector-select-input"
                  name="sectorId"
                  placeholder="Салбар сонгох"
                  showSearch
                  optionFilterProp="children"
                  onChange={(e) => {
                    getSector(e, setFieldValue);
                    setFieldValue("sectorUserId", undefined);
                  }}
                >
                  {results &&
                    results?.rows?.map((item, index) => (
                      <Option value={`${item?._id}`} key={index}>
                        {item.fullName}
                      </Option>
                    ))}
                </Select>
              </FormItem>

              <div className="meta-header">
                {data?.sectorId && (
                  <Meta
                    avatar={
                      data?.sectorId &&
                      data.sector?.avatar && (
                        <Avatar
                          size={55}
                          src={`${s3host}/${data.sector?.avatar}`}
                          icon={<CameraOutlined />}
                        />
                      )
                    }
                    title={
                      data?.sectorId && data?.sector?.fullName ? (
                        data?.sector?.fullName
                      ) : (
                        <Skeleton.Input size="small" block />
                      )
                    }
                    description={
                      data?.sectorId && data?.sector?.email ? (
                        <>{`${data?.sector?.email} / ${data?.sector?.phone}`}</>
                      ) : (
                        <>
                          {" "}
                          <Skeleton.Input
                            width={100}
                            height={10}
                            size="small"
                          />
                        </>
                      )
                    }
                  />
                )}
              </div>
              <Divider />
              <FormItem name="selectSectorUser">
                <Checkbox
                  name="selectSectorUser"
                  onChange={() => {
                    setFieldValue("sectorUserId", "");
                    setSectorUserData();
                  }}
                >
                  Салбарын ажилтан сонгох эсэх ?
                </Checkbox>
              </FormItem>
              {data.selectSectorUser && (
                <>
                  <FormItem
                    name="sectorUserId"
                    label="Салбар нэгжийн ажилтан"
                    required
                    key={"sectorUserId"}
                  >
                    <Select
                      status={!data.sectorUserId && "error"}
                      key={"sectorUserId"}
                      disabled={!data.sectorId}
                      className="sector-select-input"
                      name="sectorUserId"
                      placeholder="Салбар нэгжийн ажилтан"
                      showSearch
                      optionFilterProp="children"
                      onChange={(e) => {
                        getUser(e, data.sectorId);
                      }}
                      onSearch={(e) => {
                        initUsers(e, data.sectorId);
                      }}
                    >
                      {users &&
                        users?.rows?.map((item, index) => (
                          <Select.Option value={`${item?._id}`} key={index}>
                            {item?.lastName} {item?.firstName || "-"}
                          </Select.Option>
                        ))}
                    </Select>
                  </FormItem>
                  <div className="meta-header">
                    {sectorUserData && data.sectorUserId && (
                      <Meta
                        avatar={
                          <Avatar
                            size={55}
                            src={`${s3host}${sectorUserData?.avatar}`}
                            icon={<UserOutlined />}
                          />
                        }
                        title={
                          sectorUserData.firstName || (
                            <Skeleton.Input size="small" block />
                          )
                        }
                        description={
                          sectorUserData && sectorUserData.phone ? (
                            <>{`${sectorUserData?.phone}`}</>
                          ) : (
                            <>
                              {" "}
                              <Skeleton.Input
                                width={100}
                                height={10}
                                size="small"
                              />
                            </>
                          )
                        }
                      />
                    )}
                  </div>
                </>
              )}
            </div>
          </div>
          <div>
            <Row justify="end">
              <Button htmlType="submit" type="primary" loading={isSubmitting}>
                {isMobile ? (
                  <>
                    <SendOutlined />
                  </>
                ) : (
                  <>Илгээх</>
                )}
              </Button>
            </Row>
          </div>
        </>
      )}
    </CardWrapper>
  );
};

const CardWrapper = styled.div`
  padding: 16px;
  border-radius: 10px;
  box-shadow: 0px 1px 5px 0px #00000014;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 15px;

  .meta-header {
    height: 70px;
  }
  .date {
    font-size: 14px;
    color: #00000073;
    font-weight: 400;
  }
`;

export default CardSector;
