/* eslint-disable key-spacing */
import { Button, message as notify, Row } from "antd";
import { Formik } from "formik";
import { Form, FormItem, Input } from "formik-antd";
import React from "react";
import { useHistory } from "react-router";
import styled from "styled-components";
import * as Yup from "yup";
import { post as postApi } from "../../apis";
import { ImageUpload } from "../../components";
import GetPosition from "./components/GetPosition";

const FormSchema = () =>
  Yup.object().shape({
    text: Yup.string()
      .required("Заавал бөглөнө!")
      .max(2000, "Хэт урт байна!")
      .trim(),
    image: Yup.string().optional().nullable(),
  });

const PostForm = () => {
  const history = useHistory();

  const [data] = React.useState({
    text: undefined,
    image: undefined,
    location: {
      lat: undefined,
      lng: undefined,
    },
  });

  const onSubmit = async (values) => {
    if (values.location.lat && values.location.lng)
      try {
        await postApi.create(values);
        history.push("/post");
        notify.success("Таны хүсэлт амжилттай!");
      } catch (error) {
        notify.error(error.message || "Хүсэлт амжилтгүй");
      }
    else notify.error("Эрсдэлийн байршлыг сонгоно уу!");
  };

  return (
    <Container>
      <Row justify="center">
        <PostStyled>
          <Formik
            enableReinitialize
            initialValues={data}
            validationSchema={FormSchema}
            onSubmit={onSubmit}
          >
            {({ isSubmitting, setFieldValue, values }) => (
              <Form layout="vertical">
                <FormItem name="image">
                  <ImageUploadStyled>
                    <ImageUpload
                      name="image"
                      action="/api/general/upload/image"
                    />
                  </ImageUploadStyled>
                </FormItem>

                <GetPosition setFieldValue={setFieldValue} values={values} />

                <FormItem name="text" label="Тайлбар" required>
                  <Input.TextArea
                    placeholder="Энд бичнэ үү..."
                    name="text"
                    rows={6}
                    maxLength={2000}
                    showCount
                  />
                </FormItem>
                <Row justify="end" style={{ paddingTop: "30px" }}>
                  <Button
                    className="button"
                    style={{ marginRight: 10 }}
                    htmlType="submit"
                    type="primary"
                    loading={isSubmitting}
                  >
                    Хадгалах
                  </Button>
                  <Button
                    className="button"
                    type="default"
                    onClick={() => history.push("/post")}
                  >
                    Болих
                  </Button>
                </Row>
              </Form>
            )}
          </Formik>
        </PostStyled>
      </Row>
    </Container>
  );
};

const ImageUploadStyled = styled.div`
  width: 100%;
  min-width: 100%;
  max-height: 400px;
  min-height: 300px;

  .ant-upload.ant-upload-select-picture-card {
    min-width: 100%;
    max-height: 400px;
    min-height: 300px;
  }

  .form-upload-image {
    width: 100%;
  }

  .image-view {
    width: 100%;
  }

  @media (max-width: 900px) {
    min-height: 150px;
    .ant-upload.ant-upload-select-picture-card {
      min-height: 150px;
    }
  }
`;

const Container = styled.div`
  margin-bottom: 20px;

  @media (max-width: 1000px) {
    width: 100%;
  }
`;

const PostStyled = styled.div`
  margin-top: 15px;
  padding: 8px;
  border-radius: 8px;
  box-shadow: 0px 1px 5px 0px #00000014;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
  max-width: 675px;
  width: 100%;
  margin-bottom: 40px;

  .comment {
    margin: 10px 0;
    background: #fafafa;
    padding-left: 5px;
    border-left: 3px dashed #dadada;
    border-radius: 2px;

    .ant-input {
      background: #fafafa;
      border: none;
    }
    .ant-input:focus {
      border-color: #ffffff;
      outline: 0;
      -webkit-box-shadow: none;
      box-shadow: none;
    }
  }
  .comment-title {
    font-weight: 500;
    color: #8d8d8d;
    line-height: 8px;
    margin-top: 20px;
  }
`;

export default PostForm;
