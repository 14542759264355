import request from "../utils/request";

export const get = (id) => request.get(`/api/total/${id}`);

export const list = (data) => request.get("/api/total", data);

export const createx = (data) => request.post("/api/total", data);

export const update = (id, data) => request.put(`/api/total/${id}`, data);

export const remove = (id) => request.del(`/api/total/${id}`);

export const select = (data) => request.get("/api/dashboard/total3", data);

export const sectorUsers = (id, data) =>
  request.get(`/api/total/${id}/sectorUsers`, {
    filter: {
      query: data || "",
    },
  });
