import { UserOutlined } from "@ant-design/icons";
import { Avatar, Tag } from "antd";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { post as postApi } from "../../apis";
import { MyTable } from "../../components";
import { PageContainer } from "../../components/Layout";

const ReportDetails = () => {
  const params = useParams();
  const { s3host, webUserRoles, reportTypes } = useSelector(state => state.general);
  const [filters] = useState({
    filter: {
      startDate: undefined,
      endDate  : undefined,
      id       : params.id,
    },
    offset: {
      page : 1,
      limit: 10
    },
    query: "",
  });

  const columns = useHeader({
    s3host,
    webUserRoles,
    reportTypes
  });

  return (
    <div>
      <PageContainer>
        <MyTable
          pagination={false}
          scroll={{ x: "max-content" }}
          style={{ marginTop: "20px" }}
          columns={columns}
          filters={filters}
          loadData={postApi.reportedUsers}
          rowKey={(record) => record._id}
        />
      </PageContainer>
    </div>
  );
};

const useHeader = ({ s3host, webUserRoles, reportTypes }) => (
  [
    {
      title    : "№",
      dataIndex: "i",
      width    : "20px"
    }, {
      title : "Зураг",
      render: (row) => {
        return <Avatar size={40} src={`${s3host}${row?.avatar}`} shape="square" icon={<UserOutlined />} />;
      }
    }, {
      title : "Овог",
      render: (row) => {
        return row.lastName || "-";
      }
    }, {
      title : "Нэр",
      render: (row) => {
        return row.firstName || "-";
      }
    }, {
    //   title : "И-мэйл хаяг",
    //   render: (row) => {
    //     return row.email || "-";
    //   }
    // }, {
      title : "Утас",
      render: (row) => {
        return row.phone || "-";
      }
    }, {
      title : "Мэдээллэсэн эрсдэлийн тоо",
      render: (row) => {
        return row.totalReports || 0;
      }
    }, {
      title : "Төрөл",
      render: (record) => {
        return record?.reportType ? reportTypes?.find(item => item.code === record?.reportType)?.name : "-";
      }
    }, {
      title : "Хэрэглэгчийн төрөл",
      render: (row) => {
        return row.role ? <Tag color={webUserRoles?.find(item => item?.code === row.role)?.color}>
          {webUserRoles?.find(item => item.code === row.role)?.name || "-"}
        </Tag> : "-";
      }
    },
  ]
);

export default ReportDetails;