/* eslint-disable key-spacing */
import { Button, message, Row, Space } from "antd";
import { Formik } from "formik";
import { Form, FormItem, Radio, Select } from "formik-antd";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import * as Yup from "yup";
import { notify as notifyApi, sector as sectorApi } from "../../apis";

const FormSchema = () =>
  Yup.object().shape({
    type: Yup.string().required("Заавал бөглөнө!"),
    sector: Yup.string().when((data, schema, test) => {
      if (test.parent.type === "SECTOR") {
        return Yup.string().required("Заавал бөглөнө!");
      }
    }),
  });

const FormModalNoticy = ({ setNotifyModal, notifyModal, myTableRef }) => {
  const [data] = React.useState({
    type: "ALL",
    sector: "",
  });
  const [count, setCount] = useState(0);
  const [sectorQuery, setSectorQuery] = useState("");
  const [sectorListData, setSectorListData] = useState([]);

  const { targetTypes } = useSelector((state) => state.general);

  const onSubmit = async (data) => {
    let res;
    try {
      res = await notifyApi.send(
        notifyModal && notifyModal[0] === "notify" && notifyModal[1],
        data
      );
      message.success("Таны хүсэлт амжилттай!");
      setNotifyModal([]);
      myTableRef.current.reload();
    } catch (error) {
      setNotifyModal([]);
      message.error("Таны хүсэлт амжилтгүй!");
    }
  };

  const countTarget = async (data) => {
    let res;
    try {
      res = await notifyApi.target(data);
      setCount(res.count);
    } catch (error) {
      if (error.message) message.alert(error.message);
    }
  };

  useEffect(() => {
    countTarget({ type: "ALL" });
    sectorList(sectorQuery);
  }, []);

  const sectorList = async () => {
    let timeout = 1;
    if (timeout) clearTimeout(timeout);

    timeout = setTimeout(async () => {
      let res = await sectorApi.select({
        query: sectorQuery.trim() || undefined,
      });
      setSectorListData(res);
    }, 300);
  };

  return (
    <Container>
      <Formik
        enableReinitialize
        initialValues={data}
        validationSchema={FormSchema}
        onSubmit={onSubmit}
      >
        {({ isSubmitting, values, setFieldValue }) => (
          <Form layout="vertical">
            <h3>
              <span style={{ fontWeight: "400" }}>Нийт хэрэглэгчид:</span>{" "}
              {count}
            </h3>
            <FormItem name="type" label="Хүлээн авах салбар" required>
              <div style={{ padding: "0 20px" }}>
                <Radio.Group
                  name="type"
                  onChange={(e) => {
                    if (e.target.value !== "SECTOR") {
                      countTarget({ type: e.target.value });
                      setFieldValue("sector", "");
                    } else setCount(0);
                  }}
                >
                  <Space direction="vertical">
                    {targetTypes.map((item, index) => {
                      return (
                        <Radio key={index} value={item.code}>
                          {item.name}
                        </Radio>
                      );
                    })}
                  </Space>
                  ;
                </Radio.Group>
              </div>
            </FormItem>

            {values.type === "SECTOR" && (
              <>
                <FormItem name="sector" label="Хүлээн авах салбар" required>
                  <Select
                    status={values.type !== "SECTOR" && "error"}
                    onChange={(e) => {
                      countTarget({ ...values, sector: e });
                    }}
                    name="sector"
                    optionFilterProp="children"
                    showSearch
                    onSearch={(e) => setSectorQuery(e)}
                    placeholder="Сонгох"
                  >
                    {sectorListData.rows?.map((item, index) => (
                      <Select.Option value={item?._id} key={index}>
                        {item?.fullName}
                      </Select.Option>
                    ))}
                  </Select>
                </FormItem>
              </>
            )}
            <Row justify="end">
              <Button
                className="button"
                style={{ marginRight: 10 }}
                htmlType="submit"
                type="primary"
                loading={isSubmitting}
              >
                Илгээх
              </Button>
              <Button
                className="button"
                type="default"
                onClick={() => setNotifyModal([])}
              >
                Болих
              </Button>
            </Row>
          </Form>
        )}
      </Formik>
    </Container>
  );
};

const Container = styled.div`
  .ant-radio-group {
    display: flex;
    width: 100%;
  }
`;

export default FormModalNoticy;
