/* eslint-disable key-spacing */
import { Drawer } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Switch } from "react-router-dom";
import styled from "styled-components";
import { auth, general } from "./apis";
import {
  Header,
  Loader,
  NavBar,
  NavBarMobile,
  PrivateRoute,
  PublicRoute,
} from "./components";
import { Forgot, Login, Notfound, Permission, Profile, Reset } from "./pages";
import { AppList, NewApp } from "./pages/App";
import AuditLog from "./pages/AuditLog";
import AuditLogHistory from "./pages/AuditLogHistory";
import Dashboard from "./pages/Dashboard";
import { EditNotify, ListDetails, NewNotify, NotifyList } from "./pages/Notify";
import { EditPost, NewPost, PostList } from "./pages/Post";
import ReportDetails from "./pages/ReportDetails";
import { EditSector, NewSector, SectorList } from "./pages/Sector";
import { EditStaff, NewStaff, StaffList } from "./pages/Staff";
import { EditFaq, NewFaq, FaqList } from "./pages/Faq";
import About from "./pages/About";
import { NoticeEdit, NoticeList, NoticeNew } from "./pages/Notice";
import Test from "./pages/Test";

const App = () => {
  const dispatch = useDispatch();
  const { token } = useSelector((state) => state.auth);
  const [loading, setLoading] = React.useState(true);
  const { isMobile, collapse, drawer } = useSelector((state) => state.general);

  React.useEffect(() => {
    const asyncFn = async () => {
      try {
        await Promise.all([general.init()(dispatch), auth.me()(dispatch)]);
        setLoading(false);
      } catch (e) {
        setLoading(false);
      }
    };

    if (token) {
      asyncFn();
    } else {
      setLoading(false);
    }
  }, [dispatch, token]);

  const updateDimensions = () => {
    const width = window.innerWidth;

    dispatch({
      type: "general.is_mobile",
      payload: width <= 991,
    });
  };

  useEffect(() => {
    updateDimensions();
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading)
    return (
      <div>
        <Loader />
      </div>
    );

  return (
    <div>
      <Switch>
        <PublicRoute path="/login" component={Login} />
        <PublicRoute path="/forgot" component={Forgot} />
        <PublicRoute path="/reset" component={Reset} />
        <PrivateRoute path="/">
          <Header />
          <Container
            isMobile={isMobile}
            collapse={collapse}
            className={isMobile && "mobile"}
          >
            <div className="content">
              <div className="navbar">
                <NavBar />
              </div>
              <div className="wrapper">
                <Switch>
                  <Route path="/" component={Dashboard} exact />
                  <Route path="/post" component={PostList} exact />
                  <Route path="/post/new" component={NewPost} exact />
                  <Route path="/post/:id" component={EditPost} exact />
                  <Route path="/webuser" component={AppList} exact />
                  <Route path="/webuser/new" component={NewApp} exact />
                  <Route path="/notify" component={NotifyList} exact />
                  <Route path="/notify/new" component={NewNotify} exact />
                  <Route path="/notify/:id" component={EditNotify} exact />
                  <Route
                    path="/notify/details/:id"
                    component={ListDetails}
                    exact
                  />
                  <Route path="/staff" component={StaffList} exact />
                  <Route path="/staff/new" component={NewStaff} exact />
                  <Route path="/staff/:id" component={EditStaff} exact />
                  <Route path="/sector" component={SectorList} exact />
                  <Route path="/sector/new" component={NewSector} exact />
                  <Route path="/sector/:id" component={EditSector} exact />
                  <Route path="/profile" component={Profile} exact />
                  <Route path="/log" component={AuditLog} exact />
                  <Route
                    path="/audit/:actionType/:objectType/:objectId/:userId"
                    component={AuditLogHistory}
                    exact
                  />
                  <Route path="/permission" component={Permission} exact />
                  <Route path="/report/:id" component={ReportDetails} exact />
                  <Route path="/faq" component={FaqList} exact />
                  <Route path="/faq/new" component={NewFaq} exact />
                  <Route path="/faq/:id" component={EditFaq} exact />
                  <Route path="/about" component={About} exact />
                  <Route path="/notice" component={NoticeList} exact />
                  <Route path="/notice/new" component={NoticeNew} exact />
                  <Route path="/notice/:id" component={NoticeEdit} exact />
                  <Route path="/test" component={Test} exact />
                  <Route component={Notfound} />
                </Switch>
              </div>
            </div>
            <DrawerStyled>
              <Drawer
                placement={"left"}
                onClose={() => {
                  dispatch({
                    type: "general.drawer",
                    payload: false,
                  });
                }}
                open={drawer}
              >
                <NavBarMobile isDrawer={true} />
              </Drawer>
            </DrawerStyled>
          </Container>
        </PrivateRoute>
      </Switch>
    </div>
  );
};

const DrawerStyled = styled.div`
  padding: 0;
  .ant-drawer-body {
    padding: 0;

    background: rebeccapurple !important;
  }
  .ant-drawer-content-wrapper {
    padding: 0 !important;
  }
`;

const Container = styled.div`
  &.mobile {
    .navbar {
      display: none !important;
    }
    .wrapper {
      animation: translating 0.5s linear infinite;
      transition: 0.5s ease;
      margin-left: 0 !important;
    }
  }

  .content {
    position: relative;
    width: 100%;

    .navbar {
      display: flex;
      width: ${(props) => (props.collapse ? "80px" : "250px")};
      height: calc(100vh - 60px);
      border-right: 1px solid #e8e8e8;
      flex-direction: column;
      position: absolute;
      padding-top: 20px;
      left: 0;
      top: 0;
      overflow: auto;
      animation: translating 0.5s linear infinite;
      transition: 0.5s ease;
    }
    .wrapper {
      height: calc(100vh - 60px);
      overflow-y: auto;
      position: relative;
      margin-left: ${(props) => (props.collapse ? "80px" : "250px")};
      animation: translating 0.5s linear infinite;
      transition: 0.5s ease;
    }
  }
`;

export default App;
