import request from "../utils/request";

export const list = (data) => request.get("/api/faq", data);

export const get = (id) => request.get(`/api/faq/${id}`);

export const remove = (id) => request.del(`/api/faq/${id}`);

export const create = (data) => request.post("/api/faq", data);

export const update = (id, data) => request.put(`/api/faq/${id}`, data);
