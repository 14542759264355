import { CameraOutlined, UserOutlined } from "@ant-design/icons";
import { Avatar, Card, Image, Row } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import Fallback from "../../../assets/fallback.png";
import { datetimeFormat } from "../../../utils";

const { Meta } = Card;

const CardNew = ({ data }) => {
  const { s3host } = useSelector((state) => state.general);

  return (
    <CardWrapper>
      <Meta
        avatar={
          <Avatar
            size={55}
            src={`${s3host}/${data.user?.avatar}`}
            icon={<UserOutlined />}
          />
        }
        title={
          <Row justify="space-between">
            <div>{`${data?.user?.lastName} ${data?.user?.firstName}`}</div>
            <div className="date">{datetimeFormat(data.postStatusDate)}</div>
          </Row>
        }
        description={<div className="tag">Утас: {data?.user?.phone}</div>}
      />
      <div className="content-image">
        <Image
          fallback={Fallback}
          icon={<CameraOutlined />}
          width={"100%"}
          height={"100%"}
          src={`${s3host}${data.image}`}
        />
      </div>
      <div>
        <div className="comment-title">Эрсдэл:</div>
        <p className="comment-description">{data.text || "-"}</p>
      </div>
    </CardWrapper>
  );
};

const CardWrapper = styled.div`
  padding: 16px;
  border-radius: 10px;
  box-shadow: 0px 1px 5px 0px #00000014;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 15px;

  .comment-description {
    background-color: #fafafa;
    padding: 10px 15px;
    border-radius: 5px;
    word-wrap: break-word;
  }
  .comment-title {
    font-weight: 600;
    color: #585858;
    padding: 8px 0;
  }

  .content-text {
    padding-top: 10px;
    min-height: 70px;
    word-break: break-all;
    white-space: normal;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
      Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  }
  .content-image {
    .ant-image-img:hover,
    .ant-image-img {
      min-width: 100%;
      max-height: 400px;
      min-height: 400px;
      object-fit: cover;
      border-radius: 8px;
      border: 1px solid #fafafa;
    }
    .ant-image-mask:hover,
    .ant-image-mask:focus,
    .ant-image-mask {
      border-radius: 8px !important;
    }
  }
  .date {
    font-size: 14px;
    color: #00000073;
    font-weight: 400;
  }
`;

export default CardNew;
