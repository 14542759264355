import { FileImageOutlined } from "@ant-design/icons";
import { Avatar, Button, DatePicker, Radio, Space, Tag } from "antd";
import moment from "moment";
import React from "react";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { audit as auditApi } from "../../apis";
import { MyTable } from "../../components";
import { Filter } from "../../components/Design";
import { PageContainer, PageContent, PageHeaders } from "../../components/Layout";
import useFetch from "../../hooks/useFetch";
import { datetimeFormat } from "../../utils";

const AuditLogHistory = () => {
  const history = useHistory();
  const myTableRef = React.useRef();
  const { s3host, isMobile, logsObjectAction, objectTypes, permissions } = useSelector(state => state.general);
  const params = useParams();
  const [filter] = React.useState({
    start_date : null,
    end_date   : null,
    object_type: params?.objectType,
    object_id  : params?.objectId,
    action_type: params?.actionType,
    query      : null,
  });

  const [filters, setFilters] = React.useState({
    filter: {
      action_type: params?.actionType,
      user_id    : params?.userId,
      object_type: params?.objectType,
      session_id : null,
      start_date : null,
      end_date   : null,
      object_id  : params?.objectId,
    },
    offset: {
      page : 1,
      limit: 10
    }
  });
  const [dataHeader] = useFetch(auditApi.historyTemplate, filter)([]);

  let columns = useHeader({
    dataHeader,
    s3host,
    permissions
  });

  const dateFilter = (e)=> {
    setFilters({ ...filters, filter: { ...filters.filter, start_date: e && moment(e[0]).startOf("days").format("YYYY-MM-DDTHH:mm"), end_date: e && moment(e[1]).endOf("days").format("YYYY-MM-DDTHH:mm") } });
  };

  const onClear = () => {
    setFilters({
      filter: {
        action_type: params?.actionType,
        user_id    : params?.userId,
        object_type: params?.objectType,
        session_id : null,
        start_date : null,
        end_date   : null,
        object_id  : params?.objectId,
      },
      offset: {
        page : 1,
        limit: 10
      }
    });
  };

  return (
    <PageContainer>
      <PageContent>
        <PageHeaders onBack={()=> history?.goBack()} title={objectTypes?.filter(i => i.code === params.objectType).map(i => i.name)} />
        <Filter style={{ marginBottom: "15px" }}>
          <DatePicker.RangePicker value={[filters.filter.start_date && moment(filters.filter.start_date), filters.filter.end_date && moment(filters.filter.end_date)]} onChange={dateFilter} />
          <Button onClick={onClear} >Цэвэрлэх</Button>
          <Radio.Group buttonStyle="solid" onChange={(e) => setFilters({ ...filters, filter: { ...filters.filter, object_action: e.target.value } })} value={filters.filter.object_action}>
            {logsObjectAction?.map((item, index) => {
              return <Radio.Button key={index} value={item.code}>{item.name}</Radio.Button>;
            })}
          </Radio.Group>
        </Filter>
        <MyTable
          scroll={{ x: "max-content" }}
          bordered={!isMobile}
          columns={columns}
          ref={myTableRef}
          filters={filters}
          loadData={auditApi.history}
          rowKey={(record) =>{
            return Math.random();
          }} />
      </PageContent>
    </PageContainer>
  );
};

const useHeader = ({ dataHeader, s3host, permissions }) => {
  let test = [{
    title    : "№",
    dataIndex: "i",
    width    : "50px",
    fixed    : "left",
    align    : "center"
  }];
  let hero = test.concat(dataHeader);
  return hero?.map((item, index) => {
    return {
      title    : item?.title,
      width    : item?.width && "40px",
      dataIndex: item?.dataIndex || index,
      align    : item?.align,
      fixed    : item?.fixed,
      render   : (render) => {
        if (item?.type === "PERMISSION")
        {
          return <Space>{render?.map(item => {
            return <Tag color="green">{permissions?.find(i => i.code === item)?.name || "-"}</Tag>;
          })}</Space>;
        }
        if (item?.type === "DATE") {
          return <div style={{ whiteSpace: "nowrap" }}>{render ? datetimeFormat(render) : "-"}</div>;
        }
        if (item?.type === "TAGS") return <Tag color={item?.tags?.find(item => item.code === render)?.color || ""}>{item?.tags?.find(item => item.code === render)?.name}</Tag>;
        if (item?.type === "IMAGE") return <Avatar icon={<FileImageOutlined /> } src={`${s3host}${render}`} />;
        if (item?.type === "BOOLEAN") return render ? <Tag color="green" >Идэвхтэй</Tag> : <Tag color="red">Идэвхгүй</Tag>;
        return <div key={index} style={{ maxWidth: "450px" }}>{render || "-"}</div>;
      }
    };
  });
};

export default AuditLogHistory;