/* eslint-disable key-spacing */
/* eslint-disable react-hooks/exhaustive-deps */
import { ExclamationCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { Badge, Button, Input, message, Modal, Select, Tag } from "antd";
import moment from "moment";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { notify as notifyApi } from "../../apis";
import { MyTable, RowAction } from "../../components";
import { Filter } from "../../components/Design";
import {
  PageContainer,
  PageContent,
  PageHeaders,
} from "../../components/Layout";
import FormModalNoticy from "./FormModalNotice";

const NotifyList = () => {
  const myTableRef = React.useRef();
  const history = useHistory();
  const [query, setQuery] = React.useState("");
  const {
    targetTypes = [],
    isMobile,
    notifyStatus = [],
  } = useSelector((state) => state.general);
  const [notifyModal, setNotifyModal] = useState();

  const [filters, setFilters] = React.useState({
    filter: {
      query: "",
      targetType: undefined,
      notifyStatus: undefined,
      type: undefined,
    },
    offset: {
      page: 1,
      limit: 10,
    },
  });

  const columns = useHeader({
    targetTypes,
    notifyStatus,
    onAction: async (key, row) => {
      switch (key) {
        case "details": {
          history.push(`/notify/details/${row._id}`);
          break;
        }
        case "noticy": {
          setNotifyModal(["notify", row._id]);
          break;
        }
        case "edit": {
          history.push(`/notify/${row._id}`);
          break;
        }
        case "remove": {
          Modal.confirm({
            title: "Баталгаажуулах",
            icon: <ExclamationCircleOutlined />,
            content: "Та үүнийг устгахдаа итгэлтэй байна уу!",
            okText: "Устгах",
            cancelText: "Болих",
            className: "btn-custom-class",
            onOk: async () => {
              try {
                await notifyApi.remove(row._id);
                myTableRef.current.reload();
              } catch (error) {
                if (error.message) {
                  message.error(error.message);
                }
              }
            },
          });
          break;
        }
        default:
      }
    },
  });

  let timeout;

  const onClear = () => {
    setQuery("");
    setFilters({
      filter: {
        query: "",
        targetType: undefined,
        notifyStatus: undefined,
        type: undefined,
      },
      offset: {
        page: 1,
        limit: 10,
      },
    });
  };

  React.useEffect(() => {
    if (timeout) clearTimeout(timeout);
    if (filters.filter.query !== query)
      timeout = setTimeout(() => {
        setFilters((state) => ({
          ...state,
          filter: { ...filters.filter, query: query?.trim() || "" },
        }));
      }, 300);

    return () => {
      if (timeout) clearTimeout(timeout);
    };
  }, [query]);

  return (
    <PageContainer>
      <PageHeaders
        title="Мэдэгдэл"
        extra={
          <Button
            type="primary"
            icon={isMobile && <PlusOutlined />}
            onClick={() => history.push("/notify/new")}
          >
            {!isMobile && "Мэдэгдэл нэмэх"}
          </Button>
        }
      />
      <PageContent>
        <Filter>
          <Input
            onChange={(e) => setQuery(e.target.value)}
            value={query}
            placeholder="Хайх..."
            style={{ width: "100%" }}
            allowClear
          />
          <Select
            value={filters.filter.notifyStatus}
            placeholder="Төлөв"
            onChange={(e) =>
              setFilters({
                ...filters,
                filter: { ...filters.filter, notifyStatus: e },
              })
            }
            allowClear
          >
            {notifyStatus?.map((item, index) => (
              <Select.Option value={item.code} key={index}>
                <Badge color={item.color || "gray"} /> {item.name}
              </Select.Option>
            ))}
          </Select>
          <Button onClick={onClear}>Цэвэрлэх</Button>
        </Filter>
        <MyTable
          scroll={{ x: "max-content" }}
          style={{ marginTop: "20px" }}
          columns={columns}
          ref={myTableRef}
          filters={filters}
          loadData={notifyApi.list}
          rowKey={(record) => record?._id}
        />

        <Modal
          title="Мэдэгдэл илгээх"
          footer={false}
          open={notifyModal && notifyModal[0] === "notify"}
          destroyOnClose
          onCancel={() => setNotifyModal([])}
        >
          <Target>
            <FormModalNoticy
              setNotifyModal={setNotifyModal}
              notifyModal={notifyModal}
              myTableRef={myTableRef}
            />
          </Target>
        </Modal>
      </PageContent>
    </PageContainer>
  );
};

const useHeader = ({ onAction, targetTypes, notifyStatus }) => {
  return [
    {
      title: "№",
      dataIndex: "i",
      width: "50px",
      align: "center",
    },
    {
      title: "Гарчиг",
      render: (record) => {
        return record?.title ? (
          <div style={{ minWidth: "170px", wordBreak: "break-word" }}>
            {record?.title.substring(0, 24)}...
          </div>
        ) : (
          "-"
        );
      },
    },
    {
      title: "Тайлбар",
      render: (record) => {
        if (record?.body?.length > 25) {
          return <>{record?.body.substring(0, 24)} ...</>;
        }
        return record?.body || "-";
      },
    },
    {
      title: "Илгээсэн төрөл",
      render: (record) => {
        return (
          targetTypes?.find((item) => item.code === record?.targetType)?.name ||
          "-"
        );
      },
    },
    {
      title: "Илгээсэн салбар",
      render: (record) => {
        if (!record.sector) return "-";
        return <Tag color="blue">{record?.sector?.fullName || "-"}</Tag>;
      },
    },
    {
      title: "Илгээх тоо",
      render: (record) => {
        return <Tag>{record.targetCount || "0"}</Tag>;
      },
    },
    {
      title: "Илгээгдсэн тоо",
      render: (record) => {
        return <Tag>{record?.sentCount || 0}</Tag>;
      },
    },
    {
      title: "Төлөв",
      render: (record) => {
        return (
          <Tag
            color={
              notifyStatus?.find((item) => item.code === record?.notifyStatus)
                ?.color
            }
          >
            {notifyStatus?.find((item) => item.code === record?.notifyStatus)
              ?.name || "N/A"}
          </Tag>
        );
      },
    },
    {
      title: "Огноо",
      render: (record) => {
        return moment(record?.notifyStatusDate).format("YYYY MM/DD hh:mm");
      },
    },
    {
      title: "Үйлдэл",
      width: 100,
      fixed: "right",
      align: "center",
      render: (record) => {
        return (
          <RowAction
            actions={{
              noticy:
                record?.notifyStatus !== "SENT" &&
                record?.notifyStatus !== "SENDING" &&
                "Илгээх",
              edit:
                record?.notifyStatus !== "SENT" &&
                record?.notifyStatus !== "SENDING" &&
                "Засах",
              details:
                record?.notifyStatus === "SENT" &&
                record?.notifyStatus !== "SENDING" &&
                "Дэлгэрэнгүй",
              remove:
                record?.notifyStatus !== "SENT" &&
                record?.notifyStatus !== "SENDING" &&
                "Устгах",
            }}
            onClick={(key) => onAction(key, record)}
          />
        );
      },
    },
  ];
};

const Target = styled.div`
  display: flex;
  flex-direction: column;
  padding: 15px;
  border-radius: 2px;

  .item {
    font-size: 16px;
    color: #676767;
    &.type {
      font-size: 20px;
      text-transform: uppercase;
    }
    &.count {
      font-size: 48px;
      font-weight: 500;
      line-height: 40px;
      margin: 5px 0;
      color: #333;
    }
  }
`;
export default NotifyList;
