import { CaretRightOutlined } from "@ant-design/icons";
import { Button, DatePicker, Select, Tag } from "antd";
import moment from "moment";
import React from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { audit as auditApi, staff as staffApi } from "../../apis";
import { MyTable } from "../../components";
import { Filter } from "../../components/Design";
import { PageContainer, PageContent, PageHeaders } from "../../components/Layout";
import usePermission from "../../hooks/usePermission";
import { datetimeFormat } from "../../utils";

const AuditLog = () => {
  const history = useHistory();
  const myTableRef = React.useRef();
  const { logsObjectTypes = [], logsObjectAction = [], actionTypes, objectTypes } = useSelector(state => state.general);
  const [searchFilters, setSearchFilter] = React.useState([]);

  const getTranslate = (e) => {
    return e;
  };
  const { checkPermission } = usePermission();
  const [filters, setFilters] = React.useState({
    filter: {
      object_type: undefined,
      action_type: undefined,
      object_id  : "",
      user_id    : "",
      session_id : "",
      start_date : "",
      end_date   : "",
    },
    offset: {
      page : 1,
      limit: 10
    }
  });

  const columns = useHeader({
    checkPermission,
    getTranslate,
    logsObjectTypes,
    logsObjectAction,
    actionTypes,
    objectTypes,
    onAction: async (key, record) => {

      switch (key) {
        case "edit": {
          history.push(`/audit/${record?.action_type}/${record?.object_type}/${record?.object_id}/${record?.user_id}`);
          break;
        }
        default:
      }
    }
  });

  const dateFilter = (e)=> {
    setFilters({ ...filters, filter: { ...filters.filter, start_date: e && moment(e[0]).startOf("days").format("YYYY-MM-DDTHH:mm"), end_date: e && moment(e[1]).endOf("days").format("YYYY-MM-DDTHH:mm") } });
  };

  const onClear = () => {
    setFilters({
      filter: {
        object_type: undefined,
        action_type: undefined,
        object_id  : "",
        user_id    : "",
        session_id : "",
        start_date : "",
        end_date   : "",
      },
      offset: {
        page : 1,
        limit: 10
      }
    });
  };

  const userSearch = async (q = "") => {
    let timer = setTimeout(async () => {
      let result = await staffApi.select({ query: q });
      setSearchFilter(result);
    }, 600);
    return () => clearTimeout(timer);
  };

  React.useEffect(() => {
    userSearch();
  }, []);

  return (
    <PageContainer>
      <PageContent>
        <PageHeaders title="Аудит лог" />
        <Filter style={{ paddingBottom: "20px" }} >
          <Select
            className="grid-item"
            showSearch
            optionFilterProp="children"
            value={filters?.filter.user_id || null}
            onChange={(e)=> {setFilters({ ...filters, filter: { ...filters.filter, user_id: e || "" } });}}
            onSearch={v=> userSearch(v)}
            placeholder="бүртгэсэн ажилтан"
            allowClear>
            {searchFilters?.map((item, index) => {
              return (<Select.Option value={item?._id} key={index}>{item?.lastName} {item?.firstName}</Select.Option>);
            })}
          </Select>

          <Select
            allowClear
            value={filters.filter.object_type}
            placeholder="Төрөл"
            onChange={(e) => setFilters({ ...filters, filter: { ...filters.filter, object_type: e } })}
          >
            {objectTypes.map((item, index) => <Select.Option value={item.code} key={index}>
              {item.name}
            </Select.Option>)}
          </Select>
          <Select
            allowClear
            value={filters.filter.action_type}
            placeholder="Лог үйлдлийн төрөл"
            onChange={(e) => setFilters({ ...filters, filter: { ...filters.filter, action_type: e } })}
          >
            {actionTypes.map((item, index) => <Select.Option value={item.code} key={index}>
              {item.name}
            </Select.Option>)}
          </Select>
          <DatePicker.RangePicker value={[filters.filter.start_date && moment(filters.filter.start_date), filters.filter.end_date && moment(filters.filter.end_date)]} onChange={dateFilter} />
          <Button onClick={onClear}>Цэвэрлэх</Button>
        </Filter>
        <MyTable
          scroll={{ x: "max-content" }}
          ref={myTableRef}
          columns={columns}
          filters={filters}
          loadData={auditApi.list}
          rowKey={(record) => {
            return record?.phone;}}
        />
      </PageContent>
    </PageContainer>
  );
};

const useHeader = ({ onAction, checkPermission, actionTypes, objectTypes }) => {
  return [{
    title    : "№",
    dataIndex: "i",
    width    : "55px",
    align    : "center"
  }, {
    title : "Лог бүртгэсэн ажилтан",
    render: (record) => {
      return (<div>{record?.fullname || "-"}</div>);
    }
  }, {
    title : "Лог бичигдсэн огноо",
    render: (record) => {
      return (<div>{record?.created_at ? datetimeFormat(record?.created_at) : "-"}</div>);
    }
  }, {
    title : "Лог үйлдлийн төрөл",
    render: (record) => {
      return <Tag>{actionTypes?.find(item => item?.code === record?.action_type)?.name || "-"}</Tag>;
    }
  }, {
    title : "Төрөл",
    render: (record) => {
      return <Tag> {objectTypes?.find(item => item.code === record.object_type).name || "-"}</Tag>;
    }
  }, {
    title : "Үйлдэл",
    width : 135,
    fixed : "right",
    align : "center",
    render: (record) => {
      return (
        checkPermission(["AUDIT_LOG"]) && <Button disabled={record.action_type === "DELETE"} type="link" onClick={() => onAction("edit", record)} >Дэлгэрэнгүй <CaretRightOutlined /></Button>
      );
    }
  }];
};

export default AuditLog;