/* eslint-disable import/named */
/* eslint-disable key-spacing */
/* eslint-disable prefer-exponentiation-operator */
import { Pie, measureTextWidth } from "@ant-design/plots";
import styled from "styled-components";
import { Card, DatePicker, Divider, Modal, Select, Space } from "antd";
import { dashboard as dashboardApi, sector as sectorApi } from "../../../apis";
import React, { useEffect, useState } from "react";
import moment, { now } from "moment";

export const Charted = () => {
  function renderStatistic(containerWidth, text, style) {
    const { width: textWidth, height: textHeight } = measureTextWidth(
      text,
      style
    );
    const R = containerWidth / 2; // r^2 = (w / 2)^2 + (h - offsetY)^2

    let scale = 1;

    if (containerWidth < textWidth) {
      scale = Math.min(
        Math.sqrt(
          Math.abs(
            Math.pow(R, 2) /
              (Math.pow(textWidth / 2, 2) + Math.pow(textHeight, 2))
          )
        ),
        1
      );
    }

    const textStyleStr = `width:${containerWidth}px;`;
    return `<div style="${textStyleStr};font-size:${scale}em;line-height:${
      scale < 1 ? 1 : "inherit"
    };">${text}</div>`;
  }
  // -------------------------------------------------------const--------------------------------------------
  const [filters, setFilters] = React.useState({
    filter: {
      startDate: null,
      endDate: null,
    },
    offset: {
      page: 1,
      limit: 10,
    },
  });

  const [results, setResult] = useState([]);
  const [chart, setChartData] = React.useState([]);
  const [sectorId, setSectorId] = React.useState(null);
  const reload = React.useCallback(async () => {
    let res = await dashboardApi.postSectorChart(sectorId || "ALL", filters);
    setChartData(res.response.filter((item) => item.key !== "ALL"));
  }, [sectorId, filters]);
  React.useEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;
    reload(signal);
    return () => abortController.abort();
  }, [reload]);
  let timeout;
  const onSearch = async (query = null) => {
    if (query) {
      if (timeout) clearTimeout(timeout);

      timeout = setTimeout(async () => {
        let res = await sectorApi.select({
          query: query?.trim(),
        });

        setResult(res);
      }, 300);
    } else {
      initRes();
    }
  };

  const initRes = async () => {
    let res = await sectorApi.select({
      query: "",
    });
    setResult(res);
  };

  const dateFilter = (e) => {
    setFilters({
      ...filters,
      filter: {
        startDate: e && moment(e[0]).startOf("days").format("YYYY-MM-DDTHH:mm"),
        endDate: e && moment(e[1]).endOf("days").format("YYYY-MM-DDTHH:mm"),
      },
    });
  };

  useEffect(() => {
    initRes();
  }, []);

  const date = (value) => {
    if (value === "1") {
      dateFilter(null);
    }
    if (value === "2") {
      dateFilter([moment().subtract(7, "days").format("YYYY-MM-DDTHH:mm")]);
    }
    if (value === "3") {
      dateFilter([moment().subtract(30, "days").format("YYYY-MM-DDTHH:mm")]);
    }
  };

  const data = chart.filter((item) => item.key !== "ALLRISK");
  return (
    <StyledDiv>
      <div className="flex">
        <h3>Нийт эрсдэлийн мэдээлэл</h3>
      </div>
      <Divider style={{ marginTop: "32px" }} />
      <Space>
        <DatePicker.RangePicker
          value={[
            filters.filter.startDate && moment(filters.filter.startDate),
            filters.filter.endDate && moment(filters.filter.endDate),
          ]}
          onChange={dateFilter}
        />
        <Select
          showSearch
          placeholder="Select a person"
          style={{ width: "200px" }}
          optionFilterProp="children"
          onChange={date}
          defaultValue={"Бүгд"}
          options={[
            {
              value: "1",
              label: "Бүгд",
            },
            {
              value: "2",
              label: "Сүүлийн 7 хоног ",
            },
            {
              value: "3",
              label: "Сүүлийн 1 сар",
            },
          ]}
        />
      </Space>
      <div className="chart">
        <Pie
          className="pie-chart"
          {...{
            data: data.filter((item) => item.total !== 0),
            appendPadding: 10,
            angleField: "total",
            colorField: "status",
            color: data.filter((item) => item.total !== 0).map((d) => d.color),
            radius: 1,
            innerRadius: 0.64,
            meta: {
              value: {
                formatter: (v) => `${v} `,
              },
            },
            label: {
              type: "inner",
              offset: "-50%",
              style: {
                textAlign: "center",
                border: "1px solid black",
              },
              autoRotate: false,
              content: "{value}",
            },
            statistic: {
              title: {
                offsetY: -4,
                customHtml: (container, view, datum) => {
                  const { width, height } = container.getBoundingClientRect();
                  const d = Math.sqrt(
                    Math.pow(width / 2, 2) + Math.pow(height / 2, 2)
                  );
                  const text = datum ? datum.status : "Нийт";
                  return renderStatistic(datum, text);
                },
              },
              content: {
                offsetY: 4,
                style: {
                  fontSize: "22px",
                },
                customHtml: (container, view, datum, data) => {
                  const { width } = container.getBoundingClientRect();
                  const text = datum
                    ? ` ${datum.total}`
                    : ` ${data.reduce((r, d) => r + d.total, 0)}`;
                  return renderStatistic(width, text, {
                    fontSize: "10px",
                  });
                },
              },
            },
            interactions: [
              {
                type: "element-selected",
              },
              {
                type: "element-active",
              },
              {
                type: "pie-statistic-active",
              },
            ],
          }}
        />
      </div>
    </StyledDiv>
  );
};

const Modals = styled(Modal)`
  .ant-modal-content {
    box-shadow: none !important;
  }
  .ant-modal-body {
    box-shadow: none !important;
  }
`;

const StyledDiv = styled(Card)`
  max-width: 100%;
  height: 683px;

  .chart {
    margin-top: 10px;
    height: 480px;
  }

  .flex {
    display: flex;
    justify-content: space-between;
  }

  .pie-chart {
    margin-top: 5px;
    margin-right: 20px;
    font-size: 12px;
  }
`;
