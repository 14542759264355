import { Avatar, Badge, Col, Divider, PageHeader, Radio, Row } from "antd";
import React, { useState } from "react";
import styled from "styled-components";
import { PageContainer, PageContent } from "../../components/Layout";
import PasswordForm from "./components/PasswordForm";
import NoImage from "../../assets/noimage.png";
import GeneralForm from "./components/GeneralForm";
import { useSelector } from "react-redux";
import { CameraOutlined } from "@ant-design/icons";
import ImageUploadBtn from "../../components/ImageUploadBtn";
import { Form, Formik } from "formik";
import { FormItem } from "formik-antd";

const Profile = () => {
  const [formKey, setFormKey] = useState("general");
  const { user } = useSelector(state => state.auth);
  const { userRoles, s3host } = useSelector(state => state.general);

  return (
    <PageContainer>
      <PageHeader title="Миний мэдээлэл" />
      <PageContent>
        <Row>
          <Col span={24}>
            <StyledProfile>
              <div className="cover">

              </div>
              <Row>
                <div className="">
                  <Badge count={<div>
                    <Formik
                      initialValues={{
                        avatar: undefined
                      }} >
                      {({ isSubmitting }) => (
                        <Form layout="vertical">
                          <FormItem name="avatar">
                            <ImageUploadBtn name="avatar" action="/api/auth/avatar" type="upload" disable={isSubmitting} />
                          </FormItem>
                        </Form>
                      )}
                    </Formik>
                  </div>} offset={[-24, 76]}>
                    <Avatar src={user?.avatar ? `${s3host}${user?.avatar}` : NoImage} icon={<CameraOutlined/>} size={140} className="avatar-ant" shape="square" />
                  </Badge>
                </div>
                <div className="profile-name">
                  <div className="fullname">
                    {user?.lastName} {user?.firstName}
                  </div>
                  <div className="mine-role">
                    {userRoles?.find(item => item.code === user.role)?.name || "Сүпэр админ"}
                  </div>
                </div>
              </Row>
            </StyledProfile>
            <Divider/>
          </Col>
          <Col xs={24} sm={24} md={24} lg={15} >
            <Radio.Group value={formKey} onChange={(e) => setFormKey(e.target.value)} size="large" style={{ width: "100%", marginBottom: "24px" }}>
              <Radio.Button style={{ width: "50%", textAlign: "center" }} value="general">Ерөнхий мэдээлэл</Radio.Button>
              <Radio.Button style={{ width: "50%", textAlign: "center" }} value="password">Нууц үг солих</Radio.Button>
            </Radio.Group>
          </Col>
          {formKey === "general" &&
          <Col xs={24} sm={24} md={24} lg={15} >
            <h2>Ерөнхий мэдээлэл</h2>
            <br />
            <GeneralForm />
          </Col>}

          {formKey === "password" &&
          <Col xs={24} sm={24} md={24} lg={15} >
            <h2>Нууц үг солих</h2>
            <br />
            <PasswordForm setVisible={{}} />
          </Col>}
        </Row>
      </PageContent>
    </PageContainer>
  );
};

const StyledProfile = styled.div` 

  .profile-name {
    margin-left: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .fullname {
      font-weight: 500;
      font-size: 18px;
      margin-bottom: 5px;
    }
    .mine-role {
      display: flex;
      font-size: 16px;
      font-weight: 400;
      color: rgb(132, 132, 132);
      height: 32px;
      -webkit-box-align: center;
      align-items: center;
    }
  }
  .avatar-ant {
    border-radius: 5px;
    border: 1px solid #dddddd; 
    margin-top: -50px;
    margin-left: 20px;
    background: #333;
  }
 
  .cover {
    background: #333;
    height: 240px;
  }

  .image {
    border : 4px solid #fafafa; 
    width: min-content;
    border-radius: 5px;
  }
`;

export default Profile;