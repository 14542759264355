import React from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { store } from "../..";

const CkEditor = ({ initialValue, value, onChange, ...rest }) => {
  const [editorState, setEditorState] = React.useState(initialValue);
  return (
    <CKEditor
      editor={ ClassicEditor }
      config={{
        extraPlugins: [MyCustomUploadAdapterPlugin]
      }}
      data={editorState}
      onReady={ editor => {}}
      onChange={ (event, editor) => {
        const data = editor.getData();
        setEditorState(data);
        onChange(data);
      } }
      onBlur={ (event, editor) => {
        const data = editor.getData();
        setEditorState(data);
      } }
      onFocus={ (event, editor) => {
        const data = editor.getData();
        setEditorState(data);
      } }
      {...rest}
    />
  );
};

function MyCustomUploadAdapterPlugin(editor) {
  editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
    return new MyUploadAdapter(loader);
  };
}
class MyUploadAdapter {
  constructor(props) {
    // CKEditor 5's FileLoader instance.
    this.loader = props;
    // URL where to send files.
    this.url = "/api/general/upload/image";
  }

  // Starts the upload process.
  upload() {
    return new Promise((resolve, reject) => {
      this._initRequest();
      this._initListeners(resolve, reject);
      this._sendRequest();
    });
  }

  // Aborts the upload process.
  abort() {
    if (this.xhr) {
      this.xhr.abort();
    }
  }

  // Example implementation using XMLHttpRequest.
  _initRequest() {
    // eslint-disable-next-line no-multi-assign
    const xhr = this.xhr = new XMLHttpRequest();
    const { token } = store.getState().auth;
    xhr.open("POST", this.url, true);
    xhr.responseType = "json";
    xhr.setRequestHeader("Access-Control-Allow-Origin", "*");
    xhr.setRequestHeader("Authorization", "Bearer " + token);
  }

  // Initializes XMLHttpRequest listeners.
  _initListeners(resolve, reject) {
    const { s3host } = store.getState().general;
    const xhr = this.xhr;
    const loader = this.loader;
    // eslint-disable-next-line no-useless-concat
    const genericErrorText = "Couldn't upload file:" + ` ${ loader.file.name }.`;

    xhr.addEventListener("error", () => reject(genericErrorText));
    xhr.addEventListener("abort", () => reject());
    xhr.addEventListener("load", () => {
      const response = xhr.response;
      if (!response || response.error) {
        return reject(response && response.error ? response.error.message : genericErrorText);
      }
      // If the upload is successful, resolve the upload promise with an object containing
      // at least the "default" URL, pointing to the image on the server.
      resolve({
        default: `${s3host}${response.url}`
      });
    });

    if (xhr.upload) {
      xhr.upload.addEventListener("progress", evt => {
        if (evt.lengthComputable) {
          loader.uploadTotal = evt.total;
          loader.uploaded = evt.loaded;
        }
      });
    }
  }

  // Prepares the data and sends the request.
  _sendRequest() {
    const data = new FormData();

    this.loader.file.then(result => {
      data.append("file", result);
      this.xhr.send(data);
    });
  }

}

export default CkEditor;
