/* eslint-disable indent */
/* eslint-disable key-spacing */
import { Badge, Col, message as notify, Row, Space, Steps, Tabs } from "antd";
import { Formik } from "formik";
import { Form } from "formik-antd";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import * as Yup from "yup";
import { post as postApi } from "../../../apis";
import { ReactComponent as FailedSvg } from "../../../assets/status-svg/failed.svg";
import { ReactComponent as NewSvg } from "../../../assets/status-svg/new.svg";
import { ReactComponent as PendingSvg } from "../../../assets/status-svg/pending.svg";
import { ReactComponent as SuccessSvg } from "../../../assets/status-svg/success.svg";
import { datetimeFormat } from "../../../utils";
import ReportDetails from "../../ReportDetails";
import CardFailed from "./CardFailed";
import CardNew from "./CardNew";
import CardPending from "./CardPending";
import CardRejected from "./CardRejected";
import CardReview from "./CardReview";
import CardSector from "./CardSector";
import CardSolved from "./CardSolved";
import CommentReject from "./CommentReject";
import MapsGet from "./MapsGet";
import NoticeListDetails from "./NoticeListDetails";

const { TabPane } = Tabs;

const { Step } = Steps;

const SectorForm = ({ action: editable, reloadData }) => {
  const FormSchema = () =>
    Yup.object().shape({
      sectorId: Yup.string()
        .required("Заавал бөглөнө!")
        .typeError("Заавал бөглөнө!")
        .nullable(),
      sectorUserId: Yup.string().when((data, schema, test) => {
        if (test.parent.selectSectorUser) {
          return Yup.string()
            .required("Заавал бөглөнө!")
            .typeError("Заавал бөглөнө!")
            .nullable();
        }
      }),
    });

  const params = useParams();
  const history = useHistory();
  const { isMobile, postStatus } = useSelector((state) => state.general);
  const [data] = React.useState({
    text: undefined,
    image: undefined,
    sector: {
      avatar: undefined,
      fullName: undefined,
      phone: undefined,
      email: undefined,
      address: undefined,
    },
    likeCount: undefined,
    postStatus: undefined,
    postStatusDate: undefined,
    reply: undefined,
    repliedDate: undefined,

    user: {
      avatar: undefined,
      firstName: undefined,
      lastName: undefined,
      phone: undefined,
      email: undefined,
      address: undefined,
    },
    sectorUserData: undefined,
    ...(editable && editable[0] === "update" ? editable[1]?.post : {}),
    selectSectorUser:
      editable[1]?.post?.sectorUser?.sector === editable[1]?.post?.sector?._id,
    sectorData:
      (editable && editable[0] === "update" && editable[1]?.post?.sector) || "",

    sectorUserId:
      editable && editable[0] === "update" && editable[1]?.post?.sectorUser?._id
        ? editable[1]?.post?.sectorUser?._id
        : "",
    sectorId:
      (editable &&
        editable[0] === "update" &&
        editable[1]?.post?.sector?._id) ||
      editable[1]?.post?.sector?._id ||
      editable[1]?.post?.user?.sector ||
      "",

    location:
      (editable && editable[0] === "update" && editable[1]?.location) || {},
    isLocated:
      (editable && editable[0] === "update" && editable[1]?.post.isLocated) ||
      false,
  });

  const [current, setCurrent] = useState(0);

  const onSubmit = async (values) => {
    if (editable && editable[0] === "update") {
      if (values.sectorId) {
        try {
          await postApi.update(params.id, {
            ...values,
            sector: values?.sectorId,
            sectorUser: values.selectSectorUser
              ? values.sectorUserId
              : undefined,
          });
          reloadData();
          notify.success("Таны хүсэлт амжилттай!");
        } catch (error) {
          if (error.message) notify.error(error.message);
        }
      } else {
        notify.error("Салбар сонгоно уу!");
      }
    } else {
      try {
        await postApi.create({
          ...values,
          sector: values?.sectorId,
          sectorUser: values.sectorUserId,
        });
        history.push("/post");
        notify.success("Таны хүсэлт амжилттай!");
      } catch (error) {
        if (error.message) notify.error(error.message);
      }
    }
  };

  const statusRender = (value) => {
    setCurrent(postStatus.findIndex((item) => item.code === value));
    return postStatus.findIndex((item) => item.code === value);
  };

  const statusStep1 = (values) => {
    let hello = values.postStatus;
    switch (hello) {
      case "SOLVED":
        return (
          <Step
            key={0}
            title={postStatus[2].name}
            icon={<NewSvg color={current === 2 ? "#ea4335" : "#bababa"} />}
            description={
              values.repliedDate ? datetimeFormat(values.repliedDate) : null
            }
          />
        );
      default:
        return (
          <Step
            key={0}
            title={postStatus[1].name}
            icon={<NewSvg color={current === 1 ? "#ea4335" : "#bababa"} />}
            description={
              values.postStatusDate ? datetimeFormat(values.createdAt) : null
            }
          />
        );
    }
  };

  const statusStep2 = (values) => {
    let hello = values.postStatus;
    switch (hello) {
      case "SOLVED":
        return (
          <Step
            key={5}
            title={postStatus[4].name}
            icon={<PendingSvg color={current === 2 ? "#ea4335" : "#bababa"} />}
            description={
              values.reviewDate ? datetimeFormat(values.reviewDate) : null
            }
          />
        );
      case "NEW":
        return (
          <Step
            key={5}
            title={postStatus[2].name}
            icon={<PendingSvg color={current === 2 ? "#ea4335" : "#bababa"} />}
          />
        );
      default:
        return (
          <Step
            key={4}
            title={postStatus[2].name}
            icon={<PendingSvg color={current === 2 ? "#fbbc05" : "#bababa"} />}
            description={
              values.repliedDate ? datetimeFormat(values.repliedDate) : null
            }
          />
        );
    }
  };

  const statusStep = (values) => {
    let hello = values.postStatus;
    switch (hello) {
      case "REVIEW":
        return (
          <Step
            key={5}
            title={postStatus[4].name}
            icon={<SuccessSvg color={current === 4 ? "#34a853" : "#bababa"} />}
            description={
              values.reviewDate ? datetimeFormat(values.reviewDate) : null
            }
          />
        );
      case "FAILED":
        return (
          <Step
            key={5}
            title={postStatus[5].name}
            icon={<SuccessSvg color={current === 4 ? "#34a853" : "#bababa"} />}
            description={
              values.resultDate ? datetimeFormat(values.resultDate) : null
            }
          />
        );
      case "PENDING":
        return (
          <Step
            key={5}
            title={postStatus[4].name}
            icon={<FailedSvg color={current === 3 ? "#ea4335" : "#bababa"} />}
          />
        );
      case "NEW":
        return (
          <Step
            key={4}
            title={postStatus[4].name}
            s
            icon={<SuccessSvg color={current === 3 ? "#34a853" : "#bababa"} />}
          />
        );
      default:
        return (
          <Step
            key={3}
            title={postStatus[3].name}
            icon={<SuccessSvg color={current === 3 ? "#34a853" : "#bababa"} />}
            description={
              values.resultDate ? datetimeFormat(values.resultDate) : null
            }
          />
        );
    }
  };

  return (
    <Container>
      <Formik
        enableReinitialize
        initialValues={data}
        validationSchema={FormSchema}
        onSubmit={onSubmit}
      >
        {({ isSubmitting, values, setFieldValue, errors }) => {
          return (
            <Form layout="vertical">
              <Tabs defaultActiveKey="1">
                <TabPane tab="Мэдээлэл" key="1">
                  <div>
                    <Row gutter={[16, 16]}>
                      <Col span={24}>
                        <StepsWrapper>
                          <Steps
                            direction={isMobile ? "vertical" : "horizontal"}
                            current={statusRender(values.postStatus)}
                          >
                            {statusStep1(values)}
                            {statusStep2(values)}
                            {statusStep(values)}
                          </Steps>
                        </StepsWrapper>
                      </Col>
                      <Col xs={24} lg={12}>
                        <CardNew data={data} />
                      </Col>
                      <Col xs={24} lg={12}>
                        {!values.isRefused && values.postStatus === "NEW" && (
                          <CardSector
                            reloadData={reloadData}
                            data={values}
                            setFieldValue={setFieldValue}
                            isSubmitting={isSubmitting}
                            errors={errors}
                          />
                        )}
                        {values.postStatus === "PENDING" && (
                          <CardPending
                            reloadData={reloadData}
                            data={values}
                            setFieldValue={setFieldValue}
                            isSubmitting={isSubmitting}
                          />
                        )}
                        {values.postStatus === "SOLVED" && (
                          <CardSolved reloadData={reloadData} data={values} />
                        )}
                        {values.postStatus === "REVIEW" && (
                          <CardReview
                            reloadData={reloadData}
                            data={values}
                            setFieldValue={setFieldValue}
                            isSubmitting={isSubmitting}
                            errors={errors}
                          />
                        )}
                        {values.postStatus === "FAILED" && (
                          <CardFailed reloadData={reloadData} data={values} />
                        )}
                        {values.isRefused && (
                          <CardRejected
                            reloadData={reloadData}
                            data={values}
                            setFieldValue={setFieldValue}
                            isSubmitting={isSubmitting}
                          />
                        )}
                      </Col>
                      <Col span={24}>
                        <CommentReject values={values} />
                      </Col>
                      <Col span={24}>
                        <NoticeListDetails />
                      </Col>
                    </Row>
                  </div>
                </TabPane>
                <TabPane
                  tab={values?.isLocated ? "Байршил" : "Байршил сонгоонгүй"}
                  key="2"
                  disabled={!values?.isLocated}
                >
                  <MapsGet
                    location={values?.location}
                    postStatus={values.postStatus}
                  />
                </TabPane>
                <TabPane
                  tab={
                    <Space>
                      Мэдээллэсэн хэрэглэгчид{" "}
                      <Badge count={values.reportCount} />
                    </Space>
                  }
                  key="4"
                  disabled={values.reportCount === 0}
                >
                  <ReportDetails />
                </TabPane>
              </Tabs>
            </Form>
          );
        }}
      </Formik>
    </Container>
  );
};

const StepsWrapper = styled.div`
  margin-bottom: 18px;
  padding: 0 24px;

  .ant-steps-vertical {
    height: 420px;
  }
`;

const Container = styled.div`
  .ant-tabs-nav-list > .ant-tabs-nav > .ant-tabs-tab-btn {
    font-size: 44px !important;
  }
`;

export default SectorForm;
