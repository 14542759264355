import { Button, Col, message, notification, Row, Space, Spin } from "antd";
import { Formik } from "formik";
import { Form, FormItem, Input } from "formik-antd";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";
import { auth as authApi } from "../../../apis";
import { mongolText, phoneNumber } from "../../../utils";

const GeneralForm =() => {
  const FormSchema = () => Yup.object().shape({
    firstName: Yup.string()
      .trim()
      .matches(mongolText, "Та кириллээр бичнэ үү.")
      .required("Заавал бөглөнө!")
      .max(45, "Хэт урт байна!")
      .when((data) => {
        let reWhiteSpace = /\s+/;
        let text = data?.trim();
        if (reWhiteSpace.test(text)) {
          return Yup.string()
            .matches(mongolText, "Зай авж болохгүй!")
            .required("Заавал бөглөнө!");
        }
      }),
    lastName: Yup.string()
      .trim()
      .matches(mongolText, "Та кириллээр бичнэ үү.")
      .required("Заавал бөглөнө!")
      .max(45, "Хэт урт байна!")
      .when((data) => {
        let reWhiteSpace = /\s+/;
        let text = data?.trim();
        if (reWhiteSpace.test(text)) {
          return Yup.string()
            .matches(mongolText, "Зай авж болохгүй!")
            .required("Заавал бөглөнө!");
        }
      }),
    email: Yup.string()
      .when((data, schema, test) => {
        if (test.parent?.role !== "SUPER")
          return Yup.string().max(45, "Хэт урт байна!").email("И-мэйл хаяг буруу байна").required("Заавал бөглөнө!");
        return Yup.string().optional();
      }),
    phone: Yup.string()
      .when((data, schema, test) => {
        if (test.parent?.role !== "SUPER")
          return Yup.string().typeError("Утасны дугаар оруулна уу.").matches(phoneNumber, "Утасны дугаар оруулна уу.").required("Заавал бөглөнө!");
        return Yup.string().optional();
      }),
  });
  const history = useHistory();

  const { user } = useSelector(state => state.auth);

  const [data] = useState({
    firstName: undefined,
    lastName : undefined,
    email    : undefined,
    role     : undefined,
    avatar   : undefined,
    phone    : undefined,
    ...user
  });

  const dispatch = useDispatch();

  const meInit = async () => {
    try {
      await authApi.me()(dispatch);
    } catch (error) {
      if (error.message)
        notification.error({
          message: error.message
        });
    }
  };

  const onSubmit = async (value) => {
    try {
      await authApi.profile({ ...value, email: value?.email?.toLowerCase(), phone: value?.phone?.toString() });
      message.success("Амжилттай хадгаллаа.");
      meInit();
    } catch (error) {
      if (error.message)
        notification.error({
          message: error.message
        });
    }
  };


  return (
    <Formik
      initialValues={data}
      validationSchema={() => FormSchema()}
      onSubmit={onSubmit}>
      {({ isSubmitting, values }) => {
        return (
          <Form layout="vertical" >
            <Spin spinning={isSubmitting}>
              <Row gutter={[16, 16]}>
                <Col xs={24} sm={24} md={12} lg={12} >
                  <FormItem name="lastName" label="Овог" required>
                    <Input prefix={<span/>} name="lastName" placeholder="Овог" />
                  </FormItem>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} >
                  <FormItem name="firstName" label="Нэр" required>
                    <Input prefix={<span/>} name="firstName" placeholder="Нэр" />
                  </FormItem>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} >
                  <FormItem name="email" label="И-мэйл" required>
                    <Input prefix={<span/>} name="email" placeholder="И-мэйл" disabled={values?.role === "SUPER" } />
                  </FormItem>
                </Col>
                {values?.role !== "SUPER" && <Col xs={24} sm={24} md={12} lg={12} >
                  <div>
                    <FormItem name="phone" label="Утас" required >
                      <Input type="number" name="phone" prefix={<span/>} placeholder="Утас" />
                    </FormItem>
                  </div>
                </Col>}
                <Col span={24}>
                  <FormItem name="address" label="Хаяг">
                    <Input.TextArea rows={6} showCount maxLength={255} name="address" placeholder="Хаяг" />
                  </FormItem>
                </Col>
              </Row>
            </Spin>
            <Row justify="start">
              <Space>
                <Button htmlType="submit" type="primary" loading={isSubmitting} >
                    Хадгалах
                </Button>
                <Button onClick={() => {history.goBack();}}>
                    Болих
                </Button>
              </Space>
            </Row>
          </Form>
        );
      }}
    </Formik>
  );
};

export default GeneralForm;