/* eslint-disable react-hooks/exhaustive-deps */
import { CaretDownOutlined, CaretUpOutlined, ExclamationCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Input, message, Modal, Space } from "antd";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { faq as faqApi } from "../../apis";
import { MyTable, RowAction } from "../../components";
import { Filter } from "../../components/Design";
import { PageContainer, PageContent, PageHeaders } from "../../components/Layout";
import NoImage from "../../assets/noimage.png";
import { datetimeFormat } from "../../utils";
import styled from "styled-components";

const FaqList = () => {
  const history = useHistory();
  const { s3host, isMobile, userRoles } = useSelector(state => state.general);
  const [ query, setQuery] = React.useState(undefined);
  const [islist, setIsList] = useState(true);
  const [filters, setFilters] = React.useState({
    filter: {
      query: null,
    },
    offset: {
      page : 1,
      limit: 10
    }
  });
  const [passwordChange, setPasswordChange] = useState([]);
  const myTableRef = React.useRef(null);
  const columns = useHeader({
    s3host,
    userRoles,
    NoImage,
    onClick: (key, item) => {
      switch (key) {
        case "edit": {
          history.push(`/faq/${item._id}`);
          break;
        }
        case "password": {
          setPasswordChange(["change", item]);
          break;
        }
        case "remove": {
          Modal.confirm({
            title  : "Баталгаажуулах",
            icon   : <ExclamationCircleOutlined />,
            content: "Та үүнийг устгахдаа итгэлтэй байна уу!",
            onOk   : async () => {
              try {
                await faqApi.remove(item._id);
                await myTableRef.current.reload();
              } catch (error) {
                if (error.message) {
                  message.error(error.message);
                }
              }
            }
          });
          break;
        }
        default:
      }
    }
  });

  const onClear =()=> {
    setQuery(undefined);
    setFilters({
      filter: {
        query: "",
      },
      offset: {
        page : 1,
        limit: 10
      }
    });
  };

  React.useEffect(() => {
    let timer = setTimeout(() => {
      if (filters.filter.query !== query)
        setFilters({ ...filters, filter: { ...filters.filter, query: query?.trim() || "" } });
    }, 300);
    return () => clearTimeout(timer);
  }, [query]);

  return (
    <PageContainer>
      <PageHeaders title="Нийтлэг асуулт хариулт" extra={<Button type="primary" onClick={() => history.push("/faq/new")} icon={isMobile && <PlusOutlined />} >{!isMobile && "Асуулт нэмэх"}</Button>} />
      <PageContent>
        <Filter>
          <Input
            onChange={(e) => setQuery(e.target.value)}
            value={query}
            placeholder="Хайх..."
            allowClear
          />
          <Button onClick={onClear}>Цэвэрлэх</Button>
        </Filter>
        <MyTable
          scroll={{ x: "max-content" }}
          style={{ marginTop: "20px" }}
          ref={myTableRef}
          filters={filters}
          rowKey={record => record._id + record.phone}
          columns={columns}
          loadData={faqApi.list}
        />
      </PageContent>
    </PageContainer>
  );
};

const Arrows = styled.div`

  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;

  :hover {
    transition-delay: 7ms;
    background: #1890ff;
    color: #fff;
  }
`;

const useHeader = ({ onClick }) => ([{
  title : "Дараалал",
  render: (row) => {
    return <div style={{ textAlign: "center" }}>
      {row.sort}
    </div>
    ;
  }
}, {
  title : "Түгээмэл асуулт",
  render: (row) => {
    return row.title || "-";
  }
}, {
  title : "Хариулт",
  render: (row) => {
    return row.body || "-";
  }
}, {
  title : "Үүссэн огноо",
  render: (row) => {
    return row.createdAt ? datetimeFormat(row.createdAt) : "-";
  }
}, {
  title : "Үйлдэл",
  key   : "action",
  width : 100,
  fixed : "right",
  align : "center",
  render: (row) => {
    return <RowAction
      actions={{
        edit  : "Засварлах",
        remove: "Устгах"
      }} onClick={(key) => onClick(key, row)} />;
  }
}
]);

export default FaqList;