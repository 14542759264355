import { LockOutlined, UserOutlined } from "@ant-design/icons";
import { Button, Card, notification, Row, Space } from "antd";
import { Formik } from "formik";
import { Form, FormItem, Input } from "formik-antd";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import * as Yup from "yup";
import { forgot, forgotPassword, verifyOpt, getOtp } from "../../apis/auth";
import {
  otpPinRegex,
} from "../../utils";
import PinInput from "react-otp-pin";
import Logo from "../../assets/logo.png";

const FormSchema = Yup.object().shape({
  username: Yup.string()
    .email("И-мэйл хаяг оруулна уу!")
    .required("Заавал бөглөнө!")
    .typeError("И-мэйл хаяг буруу байна!")
    .nullable(),
});

const FormSchemaPin = Yup.object().shape({
  otpCode: Yup.string()
    .matches(otpPinRegex, "Заавал бөглөнө!")
    .required("Заавал бөглөнө!"),
});

const FormSchemaPass = Yup.object().shape({
  password: Yup.string()
    .min(6, "Нууц үг 6-с их орон байх хэрэгтэй!")
    .max(20, "Нууц үг 20-с бага орон байх хэрэгтэй!")
    .required("Заавал бөглөнө!"),
  confirmPassword: Yup.string()
    .required("Заавал бөглөнө!")
    .oneOf([Yup.ref("password"), null], "Нууц үг таарахгүй байна!"),
});

const Forgot = () => {
  const [verify, setVerify] = useState(false);
  const [createPassword, setCreatePassword] = useState(false);
  const history = useHistory();
  const [isDisable, setIsDisable] = useState(true);
  const [counter, setCounter] = useState(120);

  useEffect(() => {
    if (counter > 0) setTimeout(() => setCounter(counter - 1), 1000);
    else setIsDisable(false);
  }, [counter]);

  const onSubmitVerify = async (data, actions) => {
    try {
      let res;
      res = await verifyOpt({ otpCode: data.otpCode });
      setCreatePassword(res?.sessionScope === "CREATE_PASSWORD");
    } catch (err) {
      if (err.message)
        notification.error({
          message: err.message,
        });
    }
  };

  const otpReload = async () => {
    try {
      let res;
      res = await getOtp();
      notification.success({
        message: res.message,
      });
    } catch (err) {
      notification.error({
        message: err.message || err.code,
      });
    }
  };

  const onSubmit = async (data, actions) => {
    try {
      let res;
      res = await forgot({ username: data.username.toString() });
      setVerify(["verify", res]);
      notification.success({
        message: res.message,
      });
      actions.setSubmitting(false);
      actions.resetForm();
    } catch (err) {
      notification.error({
        message: err.message,
      });
      actions.setSubmitting(false);
      actions.resetForm();
    }
  };

  const onSubmitSetPassword = async (data, actions) => {
    try {
      let pass = await forgotPassword({
        password: data.password.toString().trim(),
      });
      notification.success({
        message: "Нууц үг амжилттай солигдлоо.",
      });
      actions.resetForm();
      history.push("/login");
    } catch (err) {
      if (err.message)
        notification.error({
          message: err.message,
        });
      actions.setSubmitting(false);
    }
  };

  return (
    <LoginWrapper>
      <Card className="login">
        <Space className="login-head">
          <div className="logo-image">
            <img src={Logo} alt="" width={"40px"} height={"40px"} />
          </div>
          <div>
            <div className="subname">Дархан -Уул аймаг</div>
            <div className="little-text">Эрсдэлийн мэдэгдэл</div>
          </div>
        </Space>
        {createPassword ? (
          <Formik
            initialValues={{
              password       : undefined,
              confirmPassword: undefined,
            }}
            validationSchema={FormSchemaPass}
            onSubmit={onSubmitSetPassword}
          >
            {({ isSubmitting }) => (
              <Form layout={"vertical"}>
                <FormItem name="password" label="Шинэ нууц үг" required>
                  <Input.Password
                    prefix={<LockOutlined />}
                    name="password"
                    placeholder="Шинэ нууц үг"
                  />
                </FormItem>
                <FormItem
                  name="confirmPassword"
                  label="Нууц үг давтах"
                  required
                >
                  <Input.Password
                    prefix={<LockOutlined />}
                    name="confirmPassword"
                    placeholder="Нууц үг давтах"
                  />
                </FormItem>
                <br />
                <Button
                  htmlType="submit"
                  type="primary"
                  loading={isSubmitting}
                  block
                >
                  Нууц үг
                </Button>
              </Form>
            )}
          </Formik>
        ) : (
          <>
            {verify[0] !== "verify" ? (
              <Formik
                initialValues={{
                  username: undefined,
                }}
                validationSchema={FormSchema}
                onSubmit={onSubmit}
              >
                {({ isSubmitting }) => (
                  <Form layout={"vertical"}>
                    <FormItem name="username" label="И-мэйл хаяг" required>
                      <Input
                        style={{ width: "100%" }}
                        prefix={<UserOutlined />}
                        name="username"
                        placeholder="И-мэйл хаяг"
                      />
                    </FormItem>
                    <br />
                    <Row justify="space-between">
                      <Button
                        onClick={() => history.push("/login")}
                        type="link"
                        loading={isSubmitting}
                      >
                        Буцах
                      </Button>
                      <Button
                        htmlType="submit"
                        type="primary"
                        loading={isSubmitting}
                      >
                        Нууц үг сэргээх
                      </Button>
                    </Row>
                  </Form>
                )}
              </Formik>
            ) : (
              <div>
                <Formik
                  initialValues={{
                    otpCode: undefined,
                    create : false,
                  }}
                  validationSchema={FormSchemaPin}
                  onSubmit={onSubmitVerify}
                >
                  {({ isSubmitting, setFieldValue, errors, values }) => {
                    return (
                      <Form layout={"vertical"}>
                        <FormItem name="otpCode">
                          <Row justify="center">
                            <PinInput
                              length={4}
                              name="otpCode"
                              initialValue={values.otpCode}
                              value={values.otpCode}
                              onChange={(value, index) => {
                                setFieldValue("otpCode", value);
                              }}
                              type="numeric"
                              inputMode="number"
                              style={{ padding: "10px 0" }}
                              inputStyle={{
                                borderColor: errors.otpCode && "red",
                              }}
                              inputFocusStyle={{ borderColor: "blue" }}
                              onComplete={(value, index) => {}}
                              autoSelect={true}
                              regexCriteria={otpPinRegex}
                            />
                          </Row>
                        </FormItem>
                        <br />
                        <Row justify="center" gutter={[16, 16]}>
                          <Button
                            disabled={isDisable}
                            style={{ width: "100%" }}
                            type="primary"
                            ghost
                            onClick={() => {
                              otpReload();
                              setIsDisable(false);
                              setIsDisable(true);
                              setCounter(120);
                            }}
                          >
                            <div>Дахин код авах {counter || ""} </div>
                          </Button>
                          {
                            <Button
                              style={{ width: "100%" }}
                              htmlType="submit"
                              type="primary"
                              loading={isSubmitting}
                              block
                            >
                              Баталгаажуулах
                            </Button>
                          }
                        </Row>
                      </Form>
                    );
                  }}
                </Formik>
              </div>
            )}
          </>
        )}
      </Card>
    </LoginWrapper>
  );
};

const LoginWrapper = styled.div`
  background: #f8f8f8;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  justify-content: center;
  display: flex;
  flex-direction: center;
  align-items: center;

  .timer {
    color: #8d8d8d;
    padding: 20px 0;
    width: 100%;
    text-align: center;
  }
  .pincode-input-container {
    width: 100%;
    display: flex;
    justify-content: space-evenly;

    input {
      padding: 0 !important;
      margin: 0 2px;
      text-align: center;
      border: 3px solid;
      border-top: none !important;
      border-left: none !important;
      border-right: none !important;
      background: transparent;
      width: 50px;
      height: 50px;
    }
    .pincode-input-text:focus {
      /* color: #dada;
      outline:none;
      box-shadow:none; */
    }
  }

  .login-head {
    margin-bottom: 10px;
  }
  .login {
    background: #fff;
    border: 1px solid #e8e8e8;
    width: 350px;
    margin: auto;
    padding-bottom: 10px;
    .subname {
      margin-left: 10px;
      font-weight: 600;
      text-transform: uppercase;
      color: #333;
    }
    .little-text {
      margin-left: 10px;
      text-transform: capitalize;
      font-weight: 400;
      font-size: 0.75rem;
      line-height: 1rem;
    }
    .forgot {
      text-align: right;
      margin-top: 20px;
    }
    .remember {
      margin-bottom: 10px;
    }
  }
`;

export default Forgot;
