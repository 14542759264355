import { Button, Col, message, Row, Space, Spin } from "antd";
import { Formik } from "formik";
import { Form } from "formik-antd";
import React, { useEffect, useRef, useState } from "react";
import * as Yup from "yup";
import { aboutUs as aboutApi } from "../../../apis";
import styled from "styled-components";
import { EyeOutlined } from "@ant-design/icons";
import DraftField from "../../../components/DraftCke";

const AboutForm =() => {
  const FormSchema = () => Yup.object().shape({

  });
  const [load, setLoad] = useState(false);
  const [data, setData] = useState({
    fullName : undefined,
    email    : undefined,
    role     : undefined,
    address  : undefined,
    avatar   : undefined,
    phone    : undefined,
    policy   : "",
    about    : "",
    cover    : null,
    uploadImg: undefined
  });
  const onSubmit = async (value) => {
    try {
      await aboutApi.create(value);
      message.success("Амжилттай хадгаллаа.");
    } catch (error) {
      if (error.message)
        message.error(error.message || "Алдаа!");
    }
  };

  const getData = async ({ setFieldValue }) => {
    try {
      let res = await aboutApi.get();
      setFieldValue("about", res?.about || "");
    } catch (error) {
      message.error(error.message);
    }
  };

  const reload = async () => {
    try {
      setLoad(true);
      let res = await aboutApi.get();
      setData({ ...res, about: res?.about || "" });
      setLoad(false);
    } catch (error) {
      message.error(error.message || "Алдаа!");
    }
  };

  useEffect(() => {
    reload();
  }, []);


  const RenderHTML=(value)=>{
    return (
      <div id="about" dangerouslySetInnerHTML={ { __html: value } } />
    );
  };

  if (load) return <><Spin /></>;
  return (
    <Col span={24} >
      <Container>
        <Formik
          initialValues={data}
          validationSchema={() => FormSchema()}
          onSubmit={onSubmit}>
          {({ isSubmitting, setFieldValue, values }) => {
            return (
              <Form layout="vertical" >
                <Spin spinning={isSubmitting}>
                  <Row gutter={[16, 16]}>
                    <Col xs={24} sm={24} lg={12}>
                      <DraftField
                        name="about"
                        initialValue={values.about || ""}
                        autoSize={{ minRows: 4, maxRows: 8 }}
                        maxLength={4000}
                      />
                    </Col>
                    <Col xs={24} sm={24} lg={12} >
                      <div className="header">
                        <div className="icon">
                          <div className="active">
                            <EyeOutlined />
                          </div>
                        </div>
                      </div>
                      <FrameStyle>
                        <div className="body">
                          {RenderHTML(values.about)}
                        </div>
                      </FrameStyle>
                    </Col>
                  </Row>
                </Spin>
                <Space style={{ marginTop: "40px" }}>
                  <Button htmlType="submit" type="primary" loading={isSubmitting} >
                    Хадгалах
                  </Button>
                  <Button onClick={() => {getData({ setFieldValue });}}>
                    Цуцлах
                  </Button>
                </Space>
              </Form>
            );
          }}
        </Formik>
      </Container>
    </Col>
  );
};

const FrameStyle = styled.div` 
  border: 1px solid #dadada;
  border-top: none;
  min-height: calc(100% - 48px);
  max-height: 450px;
  overflow: auto;
  
  .body {
    padding: 16px;
  }
`;

const Container = styled.div`

  .icon { 
    border: none; 
    height: 38px;
    width: 38px;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;

    .active {
      background: #E1E1E1; 
      height: 30px;
      width: 30px;
      border-radius: 2px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .header {
    background: #fafafa;
    height: 40px;
    border: none;
    border: 1px solid #dadada;
    padding: 4px;
    display: flex;
    align-items: center; 
  }

  .draft-wrapper { 
    > div {
    > div {
        min-height: 250px;
      }
    }
}
`;
export default AboutForm;