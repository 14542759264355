/* eslint-disable key-spacing */
import { Button, message, Modal, notification, Row, Space, Spin } from "antd";
import React, { useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import { post as postApi } from "../../apis";
import {
  PageContainer,
  PageContent,
  PageHeaders,
} from "../../components/Layout";
import SectorForm from "./components/SectorForm";
import * as Yup from "yup";
import { Formik } from "formik";
import { Form, FormItem, Input } from "formik-antd";
import { ExclamationCircleOutlined, LockFilled } from "@ant-design/icons";
import { useSelector } from "react-redux";

const FormSchema = () =>
  Yup.object().shape({
    result: Yup.string()
      .max(1000, "Хэт урт байна.")
      .required("Заавал бөглөнө!")
      .typeError("Заавал бөглөнө!")
      .nullable(),
  });

const PostEdit = () => {
  const params = useParams();
  const [action, setAction] = React.useState([]);
  const { isMobile } = useSelector((state) => state.general);
  const history = useHistory();
  const [load, setLoad] = useState(true);
  const [visible, setIsVisible] = useState(false);
  const [data, setData] = useState({
    result: undefined,
  });

  const reload = React.useCallback(
    async (signal) => {
      setLoad(false);
      let res = await postApi.get(params.id, null, { signal });
      setAction(["update", res]);
      setLoad(true);
    },
    [params.id]
  );

  React.useEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;

    reload(signal);

    return () => abortController.abort();
  }, [reload]);

  const PostStatusCancel = async (formAction, action) => {
    try {
      await postApi.cancel(params.id, {
        ...formAction,
        title: "title",
        body: "body",
      });
      notification.success({
        message: "Амжилттай цуцлагдлаа.",
      });

      action.resetForm();

      reload();
    } catch (error) {
      if (error.message) {
        notification.error({
          message: error.message,
        });
      }
    }
    setIsVisible(false);
  };

  const isActivePost = async (value) => {
    Modal.confirm({
      title: "Баталгаажуулах",
      icon: <ExclamationCircleOutlined />,
      okText: "Тийм",
      content: `Та үүнийг ${!value ? "ИДЭВХГҮЙ" : "ИДЭВХТЭЙ"} болгох уу!`,
      onOk: async () => {
        try {
          await postApi.hide(params.id, {
            isActive: !value,
          });
          message.success("Төлөв амжилттай солигдлоо.");
        } catch (error) {
          if (error.message) {
            message.error(error.message);
          }
        }
        reload();
      },
    });
  };
  return (
    <PageContainer>
      <PageHeaders
        onBack={() => history.push("/post")}
        title="Дэлгэрэнгүй"
        extra={
          <Space>
            {action[1]?.post?.isActive && (
              <Button
                onClick={() =>
                  isActivePost(action && action[1]?.post?.isActive)
                }
                type="danger"
                icon={<LockFilled />}
              >
                {!isMobile && "Идэвхгүй болгох"}
              </Button>
            )}
            {action[1]?.post?.postStatus === "NEW" && (
              <Button type="primary" onClick={() => setIsVisible(true)}>
                Цуцлах
              </Button>
            )}
          </Space>
        }
      />
      <PageContent
        style={{ maxWidth: "1500px", margin: "auto", paddingBottom: "100px" }}
      >
        {
          <Spin spinning={!load}>
            {action[1] && load && (
              <SectorForm action={action} reloadData={reload} />
            )}
          </Spin>
        }
      </PageContent>

      <Modal open={visible} footer={false} onCancel={() => setIsVisible(false)}>
        <Formik
          enableReinitialize
          initialValues={data}
          validationSchema={FormSchema}
          onSubmit={PostStatusCancel}
        >
          {({ isSubmitting }) => {
            return (
              <Form layout="vertical">
                <FormItem name="result" label="Тайлбар" required>
                  <Input.TextArea
                    placeholder="Энд бичнэ үү..."
                    name="result"
                    rows={10}
                    maxLength={1000}
                    showCount
                  />
                </FormItem>
                <Row justify="end" style={{ paddingTop: "30px" }}>
                  <Button
                    className="button"
                    style={{ marginRight: 10 }}
                    htmlType="submit"
                    type="primary"
                    loading={isSubmitting}
                  >
                    Цуцлах
                  </Button>
                  <Button
                    className="button"
                    type="default"
                    onClick={() => setIsVisible(false)}
                  >
                    Болих
                  </Button>
                </Row>
              </Form>
            );
          }}
        </Formik>
      </Modal>
    </PageContainer>
  );
};
export default PostEdit;
