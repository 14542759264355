/* eslint-disable key-spacing */
import React from "react";
import { Card } from "antd";
import { dashboard as dashboardApi } from "../../../apis";
import { Column } from "@ant-design/plots";
import styled from "styled-components";
import moment from "moment";

const COLUMN_CONFIG = {
  xField: "field",
  yField: "total",
  isGroup: true,
  iStack: true,
  groupField: "field",
  seriesField: "field",
  legend: {
    position: "top-left",
  },
};

export const UserChart = () => {
  const [chart, setChartData] = React.useState([]);
  const [filters, setFilters] = React.useState({});

  const reload = React.useCallback(async () => {
    let res = await dashboardApi.userChart({
      ...filters,
      start_date: filters.start_date
        ? moment(filters.start_date).startOf("days").format("YYYY-MM-DDTHH:mm")
        : moment().subtract(1, "months").format("YYYY-MM-DD"),
      end_date: filters.end_date
        ? moment(filters.end_date).endOf("days").format("YYYY-MM-DDTHH:mm")
        : moment().format("YYYY-MM-DD"),
    });

    let chartData = res?.map((item) => {
      if (filters.time_type === "year") {
        return {
          ...item,
          date: moment(item.date).format("YYYY"),
        };
      }
      if (filters.time_type === "month") {
        return {
          ...item,
          date: moment(item.date).format("YYYY-MM"),
        };
      }
      return {
        ...item,
        date: moment(item.date).format("YYYY-MM-DD"),
      };
    });
    setChartData(chartData);
  }, [filters]);

  React.useEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;
    reload(signal);

    return () => abortController.abort();
  }, [reload]);

  return (
    <StyledDiv>
      <h3>Хэрэглэгчид</h3>
      <Column
        style={{ width: "100%" }}
        {...COLUMN_CONFIG}
        data={chart}
        height={400}
        forceFit={true}
      />
    </StyledDiv>
  );
};

const StyledDiv = styled(Card)``;
