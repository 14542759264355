import { CameraOutlined } from "@ant-design/icons";
import { Avatar, Button, Input, Select, Tag } from "antd";
import React, { useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { post as postApi } from "../../../apis";
import { MyTable } from "../../../components";
import { Filter } from "../../../components/Design";

const ReportList = () => {
  const [filters, setFilters] = useState({
    filter: {
      startDate   : null,
      endDate     : null,
      query       : "",
      reportedType: null
    },
    offset: {
      page : 1,
      limit: 10
    },
  });
  const [ query, setQuery] = React.useState(undefined);
  const history = useHistory();
  const { s3host, postStatus, reportTypes } = useSelector(state => state.general);
  const myTableRef = useRef();

  const columns = useHeader({
    s3host,
    reportTypes,
    postStatus,
    onAction: async (key, row) => {
      switch (key) {
        case "edit" : {
          history.push(`/post/${row._id}`);
          break;
        }
        case "details" : {
          history.push(`/report/${row._id}`);
          break;
        }
        case "notice" : {
          history.push(`/report/${row._id}`);
          break;
        }
        default:
      }
    },
  });

  const onClear =()=> {
    setQuery(undefined);
    setFilters({
      filter: {
        startDate   : null,
        endDate     : null,
        query       : "",
        reportedType: null
      },
      offset: {
        page : 1,
        limit: 10
      },
    });
  };

  React.useEffect(() => {
    let timer = setTimeout(() => {
      if (filters.filter.query !== query)
        setFilters({ ...filters, filter: { ...filters.filter, query: query?.trim() } });
    }, 300);

    return () => clearTimeout(timer);
  }, [query]);

  return (
    <div>
      <Filter>
        <Input
          onChange={(e) => setQuery(e.target.value)}
          value={query}
          placeholder="Хайх..."
          allowClear
        />
        <Button onClick={onClear}>Цэвэрлэх</Button>
      </Filter>
      <MyTable
        scroll={{ x: "max-content" }}
        style={{ marginTop: "20px" }}
        columns={columns}
        ref={myTableRef}
        filters={filters}
        loadData={postApi.reports}
        rowKey={(record) => record._id}
      />
    </div>
  );
};


const useHeader = ({ onAction, s3host, postStatus }) => {
  return [{
    title    : "№",
    dataIndex: "i",
    width    : "20px"
  }, {
    title : "Код",
    render: (record) => {
      return <b>{record?.code || "-"}</b>;
    }
  }, {
    title : "Зураг",
    render: (record) => {
      return <>{record?.image ? <Avatar icon={<CameraOutlined />} src={`${s3host}/${record?.image}`} size={40} shape="square" /> : "-"}</>;
    }
  }, {
    title : "Эрсдэл мэдээлэл",
    render: (record) => {
      if (record?.text?.length > 25) {
        return <>{record.text.substring(0, 24)} ...</>;
      }
      return record.text || "-";
    }
  }, {
    title : "Мэдэгдсэн тоо",
    render: (record) => {
      return <b>{record?.reportCount || 0}</b>;
    }
  }, {
    title : "Таалагдсан тоо",
    render: (record) => {
      return <b>{record?.likeCount || 0}</b>;
    }
  }, {
    title : "Төлөв",
    render: (record) => {
      if (!record?.isRefused)
        return <Tag color={postStatus?.find(item => item.code === record.postStatus)?.color}>{postStatus?.find(item => item.code === record.postStatus)?.name || "-"}</Tag>;
      return <Tag color={"blue"}>Буцаагдсан</Tag>;
    }
  }, {
    title : "Идэвхтэй эсэх",
    render: (record) => {
      if (record.isActive)
        return <Tag color="green">Идэвхтэй</Tag>;
      return <Tag>Идэвхгүй</Tag>;
    }
  }, {
    title : "Үйлдэл",
    width : 100,
    fixed : "right",
    align : "center",
    render: (record) => {
      return (
        <Button type="link" onClick={() => onAction("edit", record)} >Дэлгэрэнгүй</Button>
      );
    }
  }];
};


export default ReportList;