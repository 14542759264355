/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable key-spacing */
import {
  ExclamationCircleOutlined,
  PlusOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Avatar, Button, Input, message, Modal, Tag } from "antd";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { staff as staffApi } from "../../apis";
import NoImage from "../../assets/noimage.png";
import { MyTable, RowAction } from "../../components";
import { Filter } from "../../components/Design";
import {
  PageContainer,
  PageContent,
  PageHeaders,
} from "../../components/Layout";
import FormPassword from "./components/FormPassword";

const StaffList = () => {
  const history = useHistory();
  const { s3host, isMobile, userRoles } = useSelector((state) => state.general);
  const [query, setQuery] = React.useState(undefined);
  const [filters, setFilters] = React.useState({
    filter: {
      query: "",
      age: undefined,
    },
    offset: {
      page: 1,
      limit: 10,
    },
  });
  const [passwordChange, setPasswordChange] = useState([]);
  const myTableRef = React.useRef(null);
  const columns = useHeader({
    s3host,
    userRoles,
    onClick: (key, item) => {
      switch (key) {
        case "edit": {
          history.push(`/staff/${item._id}`);
          break;
        }
        case "password": {
          setPasswordChange(["change", item]);
          break;
        }
        case "remove": {
          Modal.confirm({
            title: "Баталгаажуулах",
            icon: <ExclamationCircleOutlined />,
            content: "Та үүнийг устгахдаа итгэлтэй байна уу!",
            onOk: async () => {
              try {
                await staffApi.remove(item._id);
                await myTableRef.current.reload();
              } catch (error) {
                if (error.message) {
                  message.error(error.message);
                }
              }
            },
          });
          break;
        }
        default:
      }
    },
  });

  const onClear = () => {
    setQuery(undefined);
    setFilters({
      filter: {
        query: "",
      },
      offset: {
        page: 1,
        limit: 10,
      },
    });
  };

  React.useEffect(() => {
    let timer = setTimeout(() => {
      if (filters.filter.query !== query)
        setFilters({
          ...filters,
          filter: { ...filters.filter, query: query?.trim() || "" },
        });
    }, 300);

    return () => clearTimeout(timer);
  }, []);

  return (
    <PageContainer>
      <PageHeaders
        title="Админ хэрэглэгч жагсаалт"
        extra={
          <Button
            type="primary"
            onClick={() => history.push("/staff/new")}
            icon={isMobile && <PlusOutlined />}
          >
            {!isMobile && "Хэрэглэгч нэмэх"}
          </Button>
        }
      />
      <PageContent>
        <Filter>
          <Input
            onChange={(e) => setQuery(e.target.value)}
            value={query}
            placeholder="Хайх..."
            allowClear
          />
          <Button onClick={onClear}>Цэвэрлэх</Button>
        </Filter>
        <MyTable
          scroll={{ x: "max-content" }}
          style={{ marginTop: "20px" }}
          ref={myTableRef}
          filters={filters}
          rowKey={(record) => record.id}
          columns={columns}
          loadData={staffApi.list}
        />
        <Modal
          okText={"Хадгалах"}
          title="Нууц үг солих"
          open={passwordChange[0] === "change"}
          onCancel={() => setPasswordChange([])}
          footer={false}
        >
          <FormPassword
            passwordChange={passwordChange}
            setPasswordChange={setPasswordChange}
          />
        </Modal>
      </PageContent>
    </PageContainer>
  );
};

const useHeader = ({ s3host, onClick, userRoles }) => [
  {
    title: "№",
    dataIndex: "i",
    width: "20px",
  },
  {
    title: "Зураг",
    render: (row) => {
      return (
        <Avatar
          icon={<UserOutlined />}
          src={row.avatar ? `${s3host}${row.avatar}` : NoImage}
          size={40}
          shape="square"
        />
      );
    },
  },
  {
    title: "Овог",
    render: (row) => {
      return row.lastName || "-";
    },
  },
  {
    title: "Нэр",
    render: (row) => {
      return row.firstName || "-";
    },
  },
  {
    title: "И-мэйл хаяг",
    render: (row) => {
      return row.email || "-";
    },
  },
  {
    title: "Хандах эрх",
    render: (row) => {
      if (row.role === "SUPER") return <Tag color="pink">Сүпэр админ</Tag>;
      return (
        <Tag>
          {userRoles?.find((item) => item.code === row?.role)?.name || "-"}
        </Tag>
      );
    },
  },
  {
    title: "Утас",
    render: (row) => {
      return row.phone || "-";
    },
  },
  {
    title: "Төлөв",
    render: (row) => {
      return row.isActive ? (
        <span style={{ color: "green" }}>Идэвхтэй</span>
      ) : (
        "Идэвхгүй"
      );
    },
  },
  {
    title: "Үйлдэл",
    key: "action",
    width: 100,
    fixed: "right",
    align: "center",
    render: (row) => {
      return (
        <RowAction
          actions={{
            edit: "Засварлах",
            password: "Нууц үг солих",
            remove: "Устгах",
          }}
          onClick={(key) => onClick(key, row)}
        />
      );
    },
  },
];

export default StaffList;
