/* eslint-disable key-spacing */
import {
  DownOutlined,
  ExclamationCircleOutlined,
  LogoutOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Dropdown, Layout, Menu, Modal, Space } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import { auth as authApi } from "../../apis";
import AdminImg from "../../assets/admin.png";
import NoImage from "../../assets/noimage.png";
import Logo from "../../assets/logo.png";

const { Header } = Layout;

const PageHeader = () => {
  const dispatch = useDispatch();
  const { isMobile, drawer, s3host } = useSelector((state) => state.general);
  const { user } = useSelector((state) => state.auth);
  const logout = () => {
    authApi.logout();
    dispatch({
      type: "auth.logout",
    });
  };

  const menu = (
    <Menu>
      <Menu.Item key={"profile"}>
        <UserOutlined style={{ marginRight: 5 }} />
        Миний мэдээлэл <NavLink to="/profile" />
      </Menu.Item>
      <Menu.Item onClick={() => confirm()} key={"logout"}>
        <LogoutOutlined style={{ marginRight: 5 }} />
        Гарах
      </Menu.Item>
    </Menu>
  );
  const confirm = () => {
    Modal.confirm({
      title: "Санамж",
      icon: <ExclamationCircleOutlined />,
      content: "Та системээс гарах уу?",
      onOk: logout,
      okText: "Тийм",
      cancelText: "Үгүй",
    });
  };

  return (
    <Container>
      <div className="navbar">
        <Space className="logo">
          {isMobile && (
            <div
              className="toggle"
              onClick={() => {
                dispatch({
                  type: "general.drawer",
                  payload: !drawer,
                });
              }}
            >
              {drawer ? <MenuFoldOutlined /> : <MenuUnfoldOutlined />}
            </div>
          )}
          <div className="logo-image">
            <img width={"35px"} height={"35px"} src={Logo} alt="" />
          </div>
          <div>
            <div className="subname">Дархан-Уул аймаг</div>
            <div className="little-text">Эрсдэл мэдээллэх систем</div>
          </div>
        </Space>
      </div>
      {isMobile ? (
        <div className="user-wrap">
          <Dropdown menu={menu} trigger={["click"]}>
            <div className="user-action">
              <UserOutlined />
            </div>
          </Dropdown>
        </div>
      ) : (
        <div className="user-info">
          <Dropdown overlay={menu} trigger={["click"]}>
            <div className="user-action">
              <div className="avatar">
                {user.role === "SUPER" ? (
                  <img
                    alt="Avatar"
                    src={user?.avatar ? `${s3host}${user?.avatar}` : AdminImg}
                  />
                ) : (
                  <img
                    alt="Avatar"
                    src={user?.avatar ? `${s3host}${user?.avatar}` : NoImage}
                  />
                )}
              </div>
              <div className="username">
                {user?.lastName} {user?.firstName || "user"} <DownOutlined />
              </div>
            </div>
          </Dropdown>
        </div>
      )}
    </Container>
  );
};

const Container = styled(Header)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
  height: 60px;
  background: #fff;
  line-height: normal;
  user-select: none;
  border-bottom: 1px solid #ededed;
  box-shadow: 0 2px 8px #f0f1f2;

  .navbar {
    display: flex;
    flex-direction: row;
    flex: 1 1;
    align-items: center;
  }
  .logo {
    margin-left: 14px;
    font-weight: 600;
    text-transform: uppercase;
    color: #333;

    .toggle {
      margin-right: 10px;
      cursor: pointer;

      :hover {
        color: "blue" !important;
      }
    }
    .little-text {
      text-transform: capitalize;
      font-weight: 400;
      font-size: 0.75rem;
      line-height: 1rem;
    }
  }
  .user-wrap {
    max-width: 60px;
    min-width: 60px;
    height: 60px;
    z-index: 1;
    position: relative;
    overflow: hidden;
    display: flex;
    background: #fff;
    flex-direction: column;
    border-bottom: 1px solid #ededed;
    transition: 0.3s;
    justify-content: center;
    align-items: center;
    .user-action {
      display: flex;
      flex-direction: row;
      position: relative;
      cursor: pointer;
      width: 30px;
      height: 30px;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      border: 1px solid #ededed;
    }
  }
  .user-info {
    max-width: 400px;
    min-width: 250px;
    height: 59px;
    z-index: 1;
    position: relative;
    overflow: hidden;
    display: flex;
    background: #fff;
    flex-direction: column;
    border-left: 1px solid #ededed;
    transition: 0.3s;

    .user-action {
      display: flex;
      flex-direction: row;
      position: relative;
      cursor: pointer;

      .avatar {
        img {
          position: relative;
          background-size: cover;
          object-fit: cover;
          overflow: hidden;
          margin-top: 5px;
          margin-left: 10px;
          height: 50px;
          width: 50px;
          border-radius: 60px;
          border: 1px solid #ededed;
          display: flex;
        }
      }
      .username {
        display: flex;
        align-items: center;
        font-weight: 700;
        padding-left: 10px;
        padding-right: 35px;

        .anticon-down {
          position: absolute;
          right: 10px;
          top: 0;
          bottom: 0;
          display: flex !important;
          align-items: center;
        }
      }
    }
  }
`;

export default PageHeader;
