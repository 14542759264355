import React from "react";
import { PageContainer, PageContent, PageHeaders } from "../../components/Layout";
import Form from "./Form";

const StaffNew = () => {
  const action = ["create"];

  return (
    <PageContainer>
      <PageHeaders title="Админ хэрэглэгч бүртгэл" />
      <PageContent>
        <Form action={action} />
      </PageContent>
    </PageContainer>
  );
};

export default StaffNew;