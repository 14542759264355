import React from "react";
import { Field } from "formik";
import styled from "styled-components";
import Editor from "./ckEditor";

const DraftField = ({ initialValue, name, ...rest }) => {
  return (
    <Field name={name}>
      {({ field, form }) => {
        return (
          <StyledDraftField>
            <Editor
              initialValue={initialValue}
              value={field.value}
              onChange={(val) => form.setFieldValue(name, val)}
              {...rest}
            />
          </StyledDraftField>
        );
      }}
    </Field>
  );
};

const StyledDraftField = styled.div`
  // border: 1px solid #d9d9d9;
  // min-height: 6rem;
  // curson: text;
`;

export default DraftField;
