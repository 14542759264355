/* eslint-disable react/jsx-no-undef */
/* eslint-disable import/named */
/* eslint-disable key-spacing */
import React, { useState } from "react";
import {
  BarChartOutlined,
  PieChartOutlined,
  PrinterOutlined,
} from "@ant-design/icons";
import { Button, Col, Modal, PageHeader, Row, Tabs } from "antd";
import styled from "styled-components";
import { PageContent } from "../../components/Layout";
import { PostChart } from "./component/PostChart";
import { UserChart } from "./component/UserChart";
import { Charted } from "./component/Charted";
import { TestChart } from "./component/TestChart";
import { DemoLine } from "./component/Diagram";
import { useSelector } from "react-redux";
import { NoPrint, Print } from "react-easy-print";

const { TabPane } = Tabs;

const Dashboard = () => {
  const { isMobile } = useSelector((state) => state.general);
  const [isPrint, setIsPrint] = useState(false);
  return (
    <div>
      <PageHeader title="Хянах самбар" />
      <PageContent>
        <Tabs defaultActiveKey="1">
          <TabPane
            tab={
              <StyledIcon>
                <PieChartOutlined />
              </StyledIcon>
            }
            key="1"
          >
            <Row gutter={[16, 16]}>
              <Col xs={24} sm={24} md={24} lg={24}>
                <Row justify="end">
                  <Button
                    style={{}}
                    onClick={() => {
                      setIsPrint(["print", "TestChart"]);
                    }}
                    type="primary"
                    icon={isMobile && <PrinterOutlined />}
                  >
                    {!isMobile && "Хэвлэх"}
                  </Button>
                </Row>
                <TestChart />
              </Col>
              <Col xs={24} sm={24} md={24} lg={10}>
                <Row justify="end">
                  <Button
                    style={{ marginBottom: "10px" }}
                    onClick={() => {
                      setIsPrint(["print", "Charted"]);
                    }}
                    type="primary"
                    icon={isMobile && <PrinterOutlined />}
                  >
                    {!isMobile && "Хэвлэх"}
                  </Button>
                </Row>
                <Charted />
              </Col>
              <Col xs={24} sm={24} md={24} lg={14}>
                <Row justify="end">
                  {" "}
                  <Button
                    style={{ marginBottom: "10px" }}
                    onClick={() => {
                      setIsPrint(["print", "DemoLine"]);
                    }}
                    type="primary"
                    icon={isMobile && <PrinterOutlined />}
                  >
                    {!isMobile && "Хэвлэх"}
                  </Button>
                </Row>

                <div>
                  <DemoLine />
                </div>
              </Col>
            </Row>
          </TabPane>
          <TabPane
            tab={
              <StyledIcon>
                <BarChartOutlined />
              </StyledIcon>
            }
            key="2"
          >
            <Row gutter={[16, 16]}>
              <Col span={24}>
                <PostChart />
              </Col>
              <Col span={24}>
                <UserChart />
              </Col>
            </Row>
          </TabPane>
        </Tabs>
        <Modals
          centered
          closeIcon={<div></div>}
          maskClosable
          width={"max-content"}
          open={isPrint[0] === "print"}
          title={false}
          footer={false}
          style={{ boxShadow: "none" }}
          maskStyle={{
            boxShadow: "none",
            border: "none",
          }}
        >
          <Print>
            <div style={{ width: "650px" }}>
              {isPrint[1] === "Charted" && <Charted />}
              {isPrint[1] === "DemoLine" && <DemoLine />}
              {isPrint[1] === "TestChart" && <TestChart />}
            </div>
          </Print>

          <NoPrint>
            <Row justify="end" style={{ paddingTop: 20 }}>
              <Button
                onClick={() => setIsPrint([])}
                style={{ marginRight: 14 }}
              >
                Цуцлах
              </Button>
              <Button onClick={() => window.print()} type="primary">
                Хэвлэх
              </Button>
            </Row>
          </NoPrint>
        </Modals>
      </PageContent>
    </div>
  );
};

const Modals = styled(Modal)`
  .ant-modal-content {
    box-shadow: none !important;
  }
  .ant-modal-body {
    box-shadow: none !important;
  }
`;

const StyledIcon = styled.div`
  font-size: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  padding-bottom: 0;
  padding-right: 0;
`;

export default Dashboard;
