import request from "../utils/request";

export const get = (id) => request.get(`/api/notice/${id}`);

export const list = (data, options) =>
  request.get("/api/notice", data, options);

export const create = (data) => request.post("/api/notice", data);

export const update = (id, data) => request.put(`/api/notice/${id}`, data);

export const remove = (id) => request.del(`/api/notice/${id}`);
