/* eslint-disable key-spacing */
import { UserAddOutlined } from "@ant-design/icons";
import { Marker, MarkerClusterer } from "@react-google-maps/api";
import React, { useState } from "react";
import useSWR from "swr";
import { default as PendingLocate } from "../../../assets/status-svg/newlocate.svg";
import { default as NewLocate } from "../../../assets/status-svg/pendinglocate.svg";
import GoogleMapComponent from "../../../components/googleMap";
import request from "../../../utils/request";

const GoogleMap = ({ location, postStatus }) => {
  const { data } = useSWR("/api/map", request.get);
  const [map, setMap] = useState();

  console.log("postStatus: ", postStatus);

  return (
    <div>
      {data ? (
        <GoogleMapComponent
          zoom={15}
          center={{ lat: location.lat, lng: location.lng }}
        >
          <MarkerClusterer
            options={{
              imagePath:
                "https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m", // so you must have m1.png, m2.png, m3.png, m4.png, m5.png and m6.png in that folder
            }}
          >
            {(clusterer) =>
              [0].map((issue, j) => (
                <Marker
                  key={j}
                  clusterer={clusterer}
                  icon={{
                    url: postStatus !== "PENDING" ? PendingLocate : NewLocate,
                    scaledSize: {
                      width: 40,
                      height: 40,
                    },
                    icon: <UserAddOutlined />,
                  }}
                  position={{ lat: location.lat, lng: location.lng }}
                  options={{}}
                  onLoad={(map) => setMap(map)}
                />
              ))
            }
          </MarkerClusterer>
        </GoogleMapComponent>
      ) : (
        <div>Loading...</div>
      )}
    </div>
  );
};

export default GoogleMap;
