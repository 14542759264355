import { CameraOutlined, LoadingOutlined } from "@ant-design/icons";
import { Button, message, notification, Upload } from "antd";
import { Field } from "formik";
import React, { useState } from "react";
import { useDispatch, connect } from "react-redux";
import { auth as authApi } from "../../apis";

function beforeUpload(file) {
  const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png" || file.type === "image/webp" || file.type === "image/svg+xml";
  if (!isJpgOrPng) {
    message.error("Та зөвхөн JPG/PNG файлыг байршуулах боломжтой!");
  }
  const isLt2M = file.size / 1024 < 1024 * 10;
  if (!isLt2M) {
    message.error("Зургийн хэмжээ 10 МБ-аас бага байх ёстой!");
  }
  return isJpgOrPng && isLt2M;
}

const UploadComponent = ({ name, auth, general, action, extraFunc = ()=> {}, ...restProps }) => {
  const [loading, setLoading] = useState(false);
  const { token } = auth;
  const dispatch = useDispatch();
  const meInit = async () => {
    try {
      await authApi.me()(dispatch);
    } catch (error) {
      if (error.message)
        notification.error({
          message: error.message
        });
    }
  };

  const handleChange = (info, setFieldValue, setFieldTouched, setFieldError) => {
    setFieldTouched(name, true);

    if (info.file.status === "uploading") {
      setLoading(true);
    }

    if (info.file.status === "done") {
      setTimeout(() => {
        setFieldValue(name, info.file.response.url);
        meInit();
        extraFunc();
        setLoading(false);
      }, "5000");
    }

  };

  const uploadButton = (
    <Button disabled={loading} shape="circle" icon={loading === false ? <CameraOutlined /> : <LoadingOutlined />} />
  );

  return (
    <Field name={name}>
      {fieldProps => {
        const {
          field: { value },
          form: { setFieldValue, setFieldTouched, setFieldError }
        } = fieldProps;

        return (
          <>
            <Upload
              name='file'
              showUploadList={false}
              action={action}
              headers={{
                Authorization: `Bearer ${token}`,
              }}
              beforeUpload={beforeUpload}
              onChange={info => handleChange(info, setFieldValue, setFieldTouched, setFieldError)}
              {...restProps}
            >
              {uploadButton}
            </Upload>
          </>
        );
      }}
    </Field>
  );
};

const mapStateToProps = ({ auth, general }) => ({
  auth,
  general
});

export default connect(mapStateToProps)(UploadComponent);