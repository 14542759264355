/* eslint-disable indent */
import {
  CameraOutlined,
  ExclamationCircleOutlined,
  LockFilled,
  SendOutlined,
  UnlockOutlined,
  UserOutlined,
} from "@ant-design/icons";
import {
  Avatar,
  Button,
  Card,
  Divider,
  message,
  Modal,
  Result,
  Row,
  Skeleton,
  Tabs,
} from "antd";
import { Checkbox, FormItem, Select } from "formik-antd";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import {
  post as postApi,
  sector as sectorApi,
  user as userApi,
} from "../../../apis";
import FormSolve from "./FormSolve";

const { Meta } = Card;

const CardPending = ({ data, isSubmitting, setFieldValue, reloadData }) => {
  const { s3host, isMobile } = useSelector((state) => state.general);
  const params = useParams();
  const [results, setResult] = useState([]);
  const [users, setUsers] = useState([]);
  const [sectorUserData, setSectorUserData] = useState({});
  const [sectorData, setUserData] = useState();
  const [openSolve, setOpenSolve] = useState([]);

  const isActivePost = async (value) => {
    Modal.confirm({
      // eslint-disable-next-line key-spacing
      title: "Баталгаажуулах",
      // eslint-disable-next-line key-spacing
      icon: <ExclamationCircleOutlined />,
      // eslint-disable-next-line key-spacing
      okText: "Тийм",
      content: `Та үүнийг ${!value ? "ИДЭВХГҮЙ" : "ИДЭВХТЭЙ"} болгох уу!sss`,
      // eslint-disable-next-line key-spacing
      onOk: async () => {
        try {
          await postApi.hide(params.id, {
            isActive: value,
          });
          message.success("Төлөв амжилттай солигдлоо.");
        } catch (error) {
          if (error.message) {
            message.error(error.message);
          }
        }
        reloadData();
      },
    });
  };

  console.log("data", data);

  const getSector = async (e) => {
    let res = await sectorApi.get(e);
    setUserData(res.sector);
  };

  const initRes = async () => {
    let res = await sectorApi.select({
      query: "",
    });
    setResult(res);
  };

  const initUsers = async (query = "", sectorId = null) => {
    let timeout;
    if (timeout) clearTimeout(timeout);

    timeout = setTimeout(async () => {
      let res = await userApi.list({
        offset: {
          // eslint-disable-next-line key-spacing
          page: 1,
          limit: 10,
        },
        filter: {
          // eslint-disable-next-line key-spacing
          query: query.trim() || "",
          isActive: true,
          // eslint-disable-next-line key-spacing
          role: "SECTOR",
          // eslint-disable-next-line key-spacing
          sector: sectorId || null,
        },
      });
      setUsers(res);
    }, 300);
  };

  const getUser = async (e) => {
    if (e) {
      let res = await userApi.get(e);
      setSectorUserData(res);
    } else setSectorUserData();
  };

  useEffect(() => {
    initRes();
    initUsers("", data.sectorId);
    getUser(data.sectorUserId);
    getSector(data.sectorId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    initRes();
    initUsers("", data.sectorId);
    getUser(data.sectorUserId || "");
    getSector(data.sectorId);
  }, [data.sectorId]);

  const solved = () => {
    console.log("called...");
  };

  return (
    <CardWrapper>
      {!data.isActive ? (
        <>
          <Result
            icon={
              <LockFilled
                height={"100px"}
                width={"100px"}
                style={{ color: "red" }}
              />
            }
            title={"Эрсдэлийг идэвхжүүлэх үү ?"}
            extra={
              <Button
                onClick={() => isActivePost(true)}
                type="primary"
                icon={<UnlockOutlined />}
              >
                {!isMobile && "Тийм"}
              </Button>
            }
          />
        </>
      ) : (
        <>
          <Tabs defaultActiveKey="1">
            <Tabs.TabPane tab="Дахин хуваарилах" key="1">
              <div style={{ padding: "12px" }}>
                <FormItem
                  name="sectorId"
                  label="Хуваарилах салбар сонгоно уу"
                  required
                >
                  <Select
                    className="sector-select-input"
                    name="sectorId"
                    placeholder="Салбар сонгох"
                    showSearch
                    optionFilterProp="children"
                    onChange={(e) => {
                      getSector(e);
                      setFieldValue("sectorUserId", "");
                    }}
                  >
                    {results &&
                      results?.rows?.map((item, index) => (
                        <Select.Option value={`${item?._id}`} key={index}>
                          {item.fullName}
                        </Select.Option>
                      ))}
                  </Select>
                </FormItem>
                <div className="meta-header">
                  {data.sectorId && sectorData && (
                    <Meta
                      avatar={
                        data.sectorId &&
                        sectorData?.avatar && (
                          <Avatar
                            size={55}
                            src={`${s3host}/${sectorData?.avatar}`}
                            icon={<CameraOutlined />}
                          />
                        )
                      }
                      title={
                        data.sectorId && sectorData?.fullName ? (
                          sectorData?.fullName
                        ) : (
                          <Skeleton.Input size="small" block />
                        )
                      }
                      description={
                        data.sectorId && sectorData?.email ? (
                          <>{`${sectorData?.email} / ${sectorData?.phone}`}</>
                        ) : (
                          <>
                            {" "}
                            <Skeleton.Input
                              width={100}
                              height={10}
                              size="small"
                            />
                          </>
                        )
                      }
                    />
                  )}
                </div>
                <Divider />
                <FormItem name="selectSectorUser">
                  <Checkbox
                    name="selectSectorUser"
                    onChange={() => {
                      setFieldValue("sectorUserId", "");
                      setSectorUserData();
                    }}
                  >
                    Салбарын ажилтан сонгох эсэх ?
                  </Checkbox>
                </FormItem>
                <Divider />
                {/* usersectorid */}
                {data.selectSectorUser && (
                  <>
                    <FormItem
                      name="sectorUserId"
                      label="Салбар нэгжийн ажилтан"
                      required
                    >
                      <Select
                        disabled={!data.sectorId}
                        className="sector-select-input"
                        name="sectorUserId"
                        placeholder="Салбар нэгжийн ажилтан"
                        showSearch
                        optionFilterProp="children"
                        onChange={(e) => {
                          getUser(e, data.sectorId);
                        }}
                        onSearch={(e) => {
                          initUsers(e, data.sectorId);
                        }}
                      >
                        {users &&
                          users?.rows?.map((item, index) => (
                            <Select.Option value={`${item?._id}`} key={index}>
                              {item?.lastName} {item?.firstName || "-"}
                            </Select.Option>
                          ))}
                      </Select>
                    </FormItem>
                    <div className="meta-header">
                      {sectorUserData && (
                        <Meta
                          avatar={
                            <Avatar
                              size={55}
                              src={`${s3host}${sectorUserData?.avatar}`}
                              icon={<UserOutlined />}
                            />
                          }
                          title={
                            sectorUserData.firstName || (
                              <Skeleton.Input size="small" block />
                            )
                          }
                          description={
                            sectorUserData && sectorUserData.phone ? (
                              <>{`${sectorUserData?.phone}`}</>
                            ) : (
                              <>
                                {" "}
                                <Skeleton.Input
                                  width={100}
                                  height={10}
                                  size="small"
                                />
                              </>
                            )
                          }
                        />
                      )}
                    </div>
                    <Divider />
                  </>
                )}
              </div>
              <Row justify="end">
                <Button htmlType="submit" type="primary" loading={isSubmitting}>
                  {isMobile ? (
                    <>
                      <SendOutlined />
                    </>
                  ) : (
                    <>Илгээх</>
                  )}
                </Button>
              </Row>
            </Tabs.TabPane>
            <Tabs.TabPane tab="Эрсдэлийг шийдвэрлэх" key="2">
              <div style={{ padding: "12px" }}>
                <FormSolve
                  reload={reloadData}
                  onCancel={() => setOpenSolve([])}
                />
              </div>
            </Tabs.TabPane>
          </Tabs>
        </>
      )}
    </CardWrapper>
  );
};

const CardWrapper = styled.div`
  padding: 16px;
  border-radius: 10px;
  box-shadow: 0px 1px 5px 0px #00000014;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 15px;

  .meta-header {
    height: 70px;
  }
  .date {
    font-size: 14px;
    color: #00000073;
    font-weight: 400;
  }
`;
export default CardPending;
