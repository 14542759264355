import React from "react";
import { useHistory } from "react-router-dom";
import {
  PageContainer,
  PageContent,
  PageHeaders,
} from "../../components/Layout";
import Form from "./Form";

const NoticeNew = () => {
  const history = useHistory();

  return (
    <PageContainer>
      <PageHeaders
        onBack={() => history.push("/notice")}
        title="Нийтлэл нэмэх"
      />
      <PageContent>
        <Form action={["new"]} />
      </PageContent>
    </PageContainer>
  );
};

export default NoticeNew;
