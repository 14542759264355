/* eslint-disable key-spacing */
import { Button, notification, Row, Space } from "antd";
import { Formik } from "formik";
import { Form, FormItem, Input } from "formik-antd";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import * as Yup from "yup";
import { post as postApi } from "../../../apis";
import { ImageUpload } from "../../../components";

const FormSchema = Yup.object().shape({
  result: Yup.string().required("Заавал бөглөнө!"),
  resultImage: Yup.string().required("Заавал бөглөнө!"),
});

const FormSolve = ({ onCancel, reload = () => {} }) => {
  const params = useParams();
  const [data, setData] = useState({
    result: undefined,
    resultImage: undefined,
  });

  const onSubmit = async (value) => {
    console.log("called onSubmit", value);
    try {
      await postApi.solve(params.id, value);
      reload();
      notification.success({
        description: "Эрсдэл шийдвэрлэгдлээ",
      });
    } catch (error) {
      notification.error({
        description: error.message || "Хүсэлт амжилтгүй.",
      });
    }
  };

  return (
    <Formik
      initialValues={data}
      validationSchema={FormSchema}
      onSubmit={onSubmit}
    >
      {({ isSubmitting }) => (
        <Form layout={"vertical"}>
          <FormItem name="resultImage" label="Зураг" required>
            <ImageUpload
              name="resultImage"
              action="/api/general/upload/image"
            />
          </FormItem>
          <FormItem
            name="resulte"
            label="Тайлбар"
            style={{ marginBottom: 20 }}
            required
          >
            <Input.TextArea
              rows={8}
              maxLength={1500}
              showCount
              name="result"
              placeholder="Тайлбар"
            />
          </FormItem>
          <Row justify="end" style={{ marginTop: "40px" }}>
            <Space>
              <Button onClick={onCancel} block>
                Болих
              </Button>
              <Button
                htmlType="submit"
                type="primary"
                loading={isSubmitting}
                block
              >
                Шийдвэрлэх
              </Button>
            </Space>
          </Row>
        </Form>
      )}
    </Formik>
  );
};

export default FormSolve;
