import { Button, Col, message, notification, Row, Space, Spin } from "antd";
import { Formik } from "formik";
import { Form, FormItem, Input } from "formik-antd";
import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { aboutUs as aboutApi } from "../../../apis";
import { mongolText, phoneNumber } from "../../../utils";

const initData = {
  fullName: undefined,
  email   : undefined,
  role    : undefined,
  address : undefined,
  phone   : undefined,
  policy  : null,
  about   : null,
  cover   : null,
};

const GeneralForm =() => {
  const FormSchema = () => Yup.object().shape({
    email: Yup.string().email("И-мэйл хаяг буруу байна").typeError("И-мэйл хаяг буруу байна").required("Заавал бөглөнө!"),
    phone: Yup.string().matches(phoneNumber, "Утасны дугаар оруулна уу.").typeError("Утасны дугаар оруулна уу.").required("Заавал бөглөнө!"),
  });

  const [load, setLoad] = useState(false);

  const [data, setData] = useState(initData);

  const onSubmit = async (value) => {
    try {
      await aboutApi.create({ ...value, email: value.email.toLowerCase(), phone: value.phone.toString() });
      message.success("Амжилттай хадгаллаа.");
    } catch (error) {
      if (error.message)
        notification.error({
          message: error.message
        });
    }
  };

  const getData = async ({ setFieldValue }) => {
    try {
      let res = await aboutApi.get();

      setData({
        avatar : res?.avatar,
        address: res?.address,
        phone  : res?.phone,
        email  : res?.email && res?.email?.toLowerCase(),
      });
    } catch (error) {
      message.error(error.message);
    }
  };

  const reload = async () => {
    try {
      setLoad(true);
      let res = await aboutApi.get();
      setData(res || initData);
      setLoad(false);
    } catch (error) {
      message.error(error.message || "Алдаа!");
    }
  };

  useEffect(() => {
    reload();
  }, []);

  if (load) return <><Spin /></>;
  return (
    <Formik
      enableReinitialize
      initialValues={data}
      validationSchema={() => FormSchema()}
      onSubmit={onSubmit}>
      {({ isSubmitting, setFieldValue, values }) => {
        return (
          <Form layout="vertical" >
            <Spin spinning={isSubmitting}>
              <Row gutter={[16, 16]}>
                <Col xs={24} sm={24} md={12} lg={12} >
                  <FormItem name="email" label="И-мэйл" required>
                    <Input prefix={<span/>} name="email" placeholder="И-мэйл" />
                  </FormItem>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} >
                  <div>
                    <FormItem name="phone" label="Утас" required >
                      <Input type="number" name="phone" prefix={<span/>} placeholder="Утас" />
                    </FormItem>
                  </div>
                </Col>
                <Col span={24}>
                  <FormItem name="address" label="Хаяг">
                    <Input.TextArea rows={6} showCount maxLength={255} name="address" placeholder="Хаяг" />
                  </FormItem>
                </Col>
              </Row>
            </Spin>
            <Space>
              <Button htmlType="submit" type="primary" loading={isSubmitting} >
                    Хадгалах
              </Button>
              <Button onClick={() => {getData({ setFieldValue });}}>
                    Цуцлах
              </Button>
            </Space>
          </Form>
        );
      }}
    </Formik>
  );
};

export default GeneralForm;