import request from "../utils/request";

export const login = (data) => request.post("/api/auth/login", data);

export const forgot = (data) =>
  request.post("/api/auth/forgot", data);

export const forgotPassword = (data) =>
  request.post("/api/auth/forgot/password", data);

export const verifyOpt = (data) =>
  request.post("/api/auth/verify", data);

export const profile = (data) =>
  request.put("/api/auth/profile", data);

export const me = () => async (dispatch) => {
  let res = await request.get("/api/auth/me");
  dispatch({
    type   : "auth.user",
    payload: res
  });

  return res;
};

export const changePassword = (data) =>
  request.post("/api/user/changePassword", data);

export const password = (data) =>
  request.post("/api/auth/password", data);

export const logout = () =>
  request.get("/api/auth/logout");

export const getOtp = () =>
  request.get("/api/otp");