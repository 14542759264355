const initialState = {
  user    : {},
  token   : null,
  remember: {
    username: "",
    remember: false
  },
};

const auth = (state = initialState, action = {}) => {
  switch (action.type) {
    case "auth.user":
      return {
        ...state,
        user: action.payload
      };
    case "auth.change":
      const { accessToken } = action.payload;
      return {
        ...state,
        token: accessToken
      };
    case "auth.remember":
      return {
        ...state,
        remember: action.payload,
      };
    case "auth.logout":
      return {
        ...state,
        user : {},
        token: null,
      };
    default:
      return state;
  }
};

export default auth;
