/* eslint-disable key-spacing */
import { DownloadOutlined } from "@ant-design/icons";
import { Button, notification, Space } from "antd";
import { useState } from "react";
import { useSelector } from "react-redux";
import { post as postApi } from "../../apis";
import {
  PageContainer,
  PageContent,
  PageHeaders,
} from "../../components/Layout";

const Test = () => {
  const { s3host, isMobile, postStatusType } = useSelector(
    (state) => state.general
  );

  const [filters] = useState({
    filter: {
      startDate: undefined,
      endDate: undefined,
      query: "",
      postStatus: postStatusType || "",
      userStatus: "",
      userType: "",
      sector: "",
      sectorUser: undefined,
      download: false,
    },
    offset: {
      page: 1,
      limit: 10,
    },
  });

  const download = async () => {
    try {
      const res = await postApi.list({
        ...filters,
        filter: {
          ...filters.filter,
          download: true,
        },
      });
      if (res?.url) {
        let link = document.createElement("a");
        link.href = `${s3host}/files${res?.url}`;
        document.body.appendChild(link);
        console.log("download url", res.url);
        console.log("download s3 host", `${s3host}/files${res?.url}`);
        link.click();
      }
    } catch (error) {
      notification.error({
        title: error.message || "Хүсэлт амжилтгүй боллоо!",
      });
    }
  };

  const download1 = async () => {
    try {
      const res = await postApi.list({
        ...filters,
        filter: {
          ...filters.filter,
          download: true,
        },
      });
      if (res?.url) {
        window.open(`${s3host}/files${res?.url}`);
      }
    } catch (error) {
      notification.error({
        title: error.message || "Хүсэлт амжилтгүй боллоо!",
      });
    }
  };

  const download2 = async () => {
    try {
      const res = await postApi.list({
        ...filters,
        filter: {
          ...filters.filter,
          download: true,
        },
      });
      if (res?.url) {
        window.open(s3host + "/files" + res.url, "_blank");

        // let link = document.createElement("a");
        // link.href = `${s3host}/files${res?.url}`;
        // link.traget = "_blank";
        // document.body.appendChild(link);
        // console.log("download url", res.url);
        // console.log("download s3 host", `${s3host}/files${res?.url}`);
        // link.click();
      }
    } catch (error) {
      notification.error({
        title: error.message || "Хүсэлт амжилтгүй боллоо!",
      });
    }
  };

  const download3 = async () => {
    try {
      const res = await postApi.list({
        ...filters,
        filter: {
          ...filters.filter,
          download: true,
        },
      });
      if (res?.url) {
        window.open(`${s3host}/files${res?.url}`, "_blank");
      }
    } catch (error) {
      notification.error({
        title: error.message || "Хүсэлт амжилтгүй боллоо!",
      });
    }
  };

  const download4 = async () => {
    try {
      const res = await postApi.list({
        ...filters,
        filter: {
          ...filters.filter,
          download: true,
        },
      });

      if (res?.url) {
        const newWindow = window.open();
        newWindow.opener = null;
        newWindow.location.href = `${s3host}/files${res?.url}`;
        newWindow.target = "_blank";
      }
    } catch (error) {
      notification.error({
        title: error.message || "Хүсэлт амжилтгүй боллоо!",
      });
    }
  };

  return (
    <PageContainer>
      <PageHeaders
        title="Test"
        extra={
          <Space>
            <Button
              key={0}
              type="primary"
              ghost
              icon={isMobile && <DownloadOutlined />}
              onClick={() => {
                download();
              }}
            >
              {!isMobile && "Файл татах"}
            </Button>
          </Space>
        }
      />
      <PageContent>
        <Button onClick={() => download()}>download</Button>
        <Button onClick={() => download1()}>download1</Button>
        <Button onClick={() => download2()}>download2</Button>
        <Button onClick={() => download3()}>download3</Button>
        <Button onClick={() => download4()}>download4</Button>
      </PageContent>
    </PageContainer>
  );
};

export default Test;
