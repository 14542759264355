/* eslint-disable key-spacing */
import { GoogleMap, LoadScript } from "@react-google-maps/api";
import React from "react";

const defaultCenter = { lat: 47.06269218852993, lng: 103.8481636050001 };

const GoogleMapComponent = React.forwardRef(
  (
    {
      onClick,
      polygons,
      children,
      center,
      zoom,
      onRightClick,
      height = "80vh",
      mapTypeId,
    },
    ref
  ) => {
    const [map, setMap] = React.useState(null);
    const polygonsMemo = React.useMemo(() => polygons, [polygons]);
    React.useEffect(() => {
      if (map && polygonsMemo) {
        map.data.addGeoJson(polygons);
        map.data.setStyle({
          strokeWeight: 1,
          clickable: true,
        });
        map.data.setStyle(() => {
          return /** @type {!google.maps.Data.StyleOptions} */ {
            fillOpacity: 0,
            strokeColor: "#7b7b7b",
            strokeWeight: 1,
          };
        });
      }
    }, [map, polygonsMemo]);

    const googleMap = () => {
      return map;
    };

    React.useImperativeHandle(ref, () => ({
      googleMap,
    }));

    const onLoad = React.useCallback(function callback(map) {
      setMap(map);
    }, []);

    const onUnmount = React.useCallback(function callback() {
      setMap(null);
    }, []);

    return (
      <LoadScript googleMapsApiKey={"AIzaSyBOjXv-WWTgAGmV_zIIGpKlBg2G8oLv4ko"}>
        <GoogleMap
          mapContainerStyle={{
            width: "100%",
            height: height,
          }}
          mapTypeId={mapTypeId || "satellite"}
          onRightClick={onRightClick}
          center={center || defaultCenter}
          zoom={zoom || 5}
          onLoad={onLoad}
          onUnmount={onUnmount}
          onClick={onClick}
        >
          {children}
        </GoogleMap>
      </LoadScript>
    );
  }
);

export default GoogleMapComponent;
